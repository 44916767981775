import { Motif, MotifResponse, PhotoResponse } from '../types/motifs'
import { Tag, tags } from '../constants/tags'
import { Region, regions } from '../constants/geographicFilters'

export function mapMotifResponse(res: MotifResponse[]): Motif[] {
  return res.map(r => ({
    id: r.id,
    title: r.Title,
    EN_title: r.EN_Title,
    subtitle: r.SubTitle,
    EN_subtitle: r.EN_SubTitle,
    description: r.Description,
    EN_description: r.EN_Description,
    photoUrl: getPhotoUrl(r?.OriginalObjectPhoto),
    vectorizedUrl: getPhotoUrl(r?.VectorizedObjectPhoto),
    vectorizedUrlXs: `${process.env.REACT_APP_STRAPI_URL}${r?.VectorizedObjectPhoto?.formats.thumbnail?.url}`,
    year: r.ExactDate || r.TimeRange || r.Century || null,
    EN_year: r.EN_ExactDate || r.EN_TimeRange || r.EN_Century || null,
    tagIds: r?.TagList?.map(tag => Number(tag.id)) ?? [],
    country: r.Country ?? null,
    EN_country: r.EN_Country ?? null,
    region: r.Region ?? null,
    EN_region: r.EN_Region ?? null,
    settlement: r.Settlement ?? null,
    EN_settlement: r.EN_Settlement ?? null,
    inventoryNumber: r.InventoryNumber,
  }))
}

function getPhotoUrl(res: PhotoResponse | undefined): string {
  const path =
    res?.formats?.small?.url ??
    res?.formats?.medium?.url ??
    res?.url ??
    res?.formats?.large?.url ??
    ''
  if (!path) return ''
  return process.env.REACT_APP_STRAPI_URL + path
}

export function filterByTag(motifs: Motif[], tag: Tag): Motif[] {
  const filterTagIds = tags[tag]
  return motifs.filter(motif => motif.tagIds.some(tagId => filterTagIds.includes(tagId)))
}

export function filterByRegion(motifs: Motif[], region: Region): Motif[] {
  const filterRegionIds = regions[region]
  return motifs.filter(motif => motif.tagIds.some(tagId => filterRegionIds.includes(tagId)))
}

import styled from 'styled-components'
import { GeneratorControlWrapper } from './common'
import QRCode from 'react-qr-code'
import { useContextUI } from '../../contexts/UIContext'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { UIElement } from '../../types/UIElements'
import { useContextMotifGenerator } from '../../contexts/motifGeneratorContext'

const QRGeneratorWrapper = styled(GeneratorControlWrapper)`
  position: fixed;
  top: calc(50% - 230px);
  right: 0;
  transform: translateX(100%);
  width: 280px;
  height: 335px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  transition: 0.2s ease-out;

  &.open {
    transform: translateX(0px);
  }

  > * {
    width: 180px;
  }

  div {
    padding-bottom: 20px;
  }

  p {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.92px;
    color: ${({ theme }) => theme.colors.black49};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.lg}) {
    top: calc(50% - 230px);
    width: 330px;
    height: 380px;

    > * {
      width: 250px;
    }

    p {
      font-size: 20px;
      line-height: 42px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xl}) {
    top: calc(50% - 220px);
    width: 415px;
    height: 450px;

    > * {
      width: 300px;
    }
    div {
      padding-bottom: 0;
    }

    p {
      font-size: 24px;
      line-height: 42px;
    }
  }
`

const QRGenerator = () => {
  const { elementsOpen, setElementsOpen, qrCodeLink } = useContextUI()
  const { setExportInvoked, setLoading, loading } = useContextMotifGenerator()
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    if (elementsOpen.includes(UIElement.QRMODAL)) {
      setLoading(true)
      setExportInvoked(true)
    } else if (isOpen) {
      setIsOpen(false)
    }
  }, [elementsOpen])

  useEffect(() => {
    if (!loading && elementsOpen.includes(UIElement.QRMODAL)) {
      setIsOpen(true)
    }
  }, [loading])

  return (
    <QRGeneratorWrapper
      className={isOpen ? 'open' : ''}
      onClick={() => setElementsOpen([UIElement.CONTROLS])}
    >
      <p>{t('scanQR')}</p>
      <div>
        <QRCode size={190} value={qrCodeLink} />
      </div>
    </QRGeneratorWrapper>
  )
}

export default QRGenerator

import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { useTranslation } from 'react-i18next'
import { useContextUI } from '../../contexts/UIContext'
import { useContextMotifDetails } from '../../contexts/motifDetailsContext'
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import routePaths from '../../constants/routePaths'
import { useLocation } from 'react-router-dom'
import { UIElement } from '../../types/UIElements'
import SideMenuButton from './SideMenuButton'
import MotifListControl from '../GeneratorControls/MotifList'
import { useContextSelectedMotifs } from '../../contexts/selectedMotifContext'
import { Backdrop, Modal, Slide } from '@mui/material'
import links from '../../constants/links'
import { useEnv } from '../../utils/useEnv'

const SideMenuWrapper = styled.div`
  font-size: 8px;
  background: ${({ theme }) => theme.colors.black};
  height: 100%;
  width: 60px;
  position: fixed;
  top: 0;
  left: 0;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 1;
  transition: all 0.3s ease-out;
  transform: translateX(-100%);
  z-index: 20;

  &.slide-in {
    transform: translateX(0);
    box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.2);
  }

  &.is-iPhone {
    width: 90px;
  }

  &.hidden {
    opacity: 0;
  }

  &.menu-hidden {
    left: -110px;
  }

  .brand-wrapper {
    display: none;
    position: absolute;
    margin-left: 0;
    padding-left: 0;

    .brand-logo {
      fill: ${({ theme }) => theme.colors.white};
      width: 100%;
      height: 100%;
      margin-left: 0;
      padding-left: 0;
    }
  }

  .svg-wrapper {
    position: absolute;
    transform: translateX(calc(-100% - 15px));
    width: 38px;
    height: 38px;

    svg {
      opacity: 0.8;
      path {
        fill: ${({ theme }) => theme.colors.white80};
        stroke: none;
      }
    }
  }

  &.open {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .reserved {
    color: ${({ theme }) => theme.colors.white70};
    font-size: 16px;
    padding-bottom: 30px;
    display: none;

    .legal-notice {
      white-space: pre-wrap;
      text-transform: uppercase;
      cursor: pointer;
    }
  }

  span {
    color: ${({ theme }) => theme.colors.white80};
    font-size: 17px;
    letter-spacing: 1.34px;
    font-family: ${({ theme }) => theme.font.light};
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  button {
    position: absolute;
    right: 4%;
    top: 2vh;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    font-size: inherit;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.black95};
    color: ${({ theme }) => theme.colors.white};
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    transition: 0.2s;

    svg {
      width: 45%;
    }

    &.active {
      background-color: ${({ theme }) => theme.colors.white};

      svg {
        width: 100%;
        height: 100%;
        color: ${({ theme }) => theme.colors.black};
        fill: ${({ theme }) => theme.colors.black};
      }
    }
  }

  .btn-row {
    display: flex;
    padding-left: 30px;
    width: 90%;
    flex: 1;
    gap: 12px;

    .language-switcher {
      text-transform: uppercase;
      font-size: 16px;
      display: block;
      position: absolute;
      top: 5.5vh;
      right: 4%;
      transform: translateX(-100%);
    }

    &.copyright-row {
      flex: 0;
    }

    &:nth-child(2) {
      align-items: center;
      flex-direction: column;
      padding-bottom: 15px;
    }

    .btn-action {
      display: flex;
      cursor: pointer;
      height: 40px;
      width: 20%;

      &.link-home {
        position: absolute;
        top: 3vh;
      }
    }
  }

  .side-icon {
    width: 45%;
    max-height: 45%;
    margin: 0;
    padding: 0;
  }

  @media (min-height: ${({ theme }) => theme.breakpoints.deviceH.xxs}) {
    button {
      width: 50px;
      height: 50px;
    }

    .btn-row {
      gap: 16px;
    }

    .language-switcher {
      font-size: 17px;
    }
  }

  @media (min-height: ${({ theme }) => theme.breakpoints.deviceH.xs}) {
    height: 340px;
    top: 9vh;

    button {
      top: 1vh;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xs}) and (min-height: ${({
      theme,
    }) => theme.breakpoints.deviceH.sm}) {
    top: 17vh;
    border-radius: 0 17px 17px 0;

    &.open {
      width: 100%;
    }

    .btn-row {
      &.header-row {
        display: block;
      }

      &.copyright-wrapper {
        display: block;
      }

      .link-home {
        display: none;
      }

      .language-switcher {
        display: none;
      }

      &:nth-child(2) {
        flex-direction: row;
        padding-bottom: 0;

        .btn-action {
          height: auto;
          flex-direction: column;
        }
      }

      &.copyright-row {
        flex: 1;

        .reserved {
          display: flex;
          align-items: end;
        }
      }
    }

    .btn-action {
      span {
        max-width: 130px;
      }

      &:last-child {
        span {
          max-width: 250px;
        }
      }
    }

    span {
      display: block;
      align-items: center;
      width: auto !important;
      max-width: 96%;
      white-space: initial;
    }

    .brand-wrapper {
      display: inline-block;
      top: 1vh;
      margin-top: 10px;
      height: 32px;

      .brand-logo {
        width: auto;
      }
    }

    .svg-wrapper {
      transform: none;
      position: relative;
      width: 50px;
      height: auto;
      margin-bottom: 20px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    height: 450px;
    top: 8vh;

    button {
      width: 70px;
      height: 70px;
      top: 2.5vh;

      svg {
        width: 40%;
      }
    }

    .brand-wrapper {
      height: 38px;
      top: 2.5vh;
      margin-top: 17px;
    }

    &.open {
      width: 98%;
    }

    .btn-row {
      padding-left: 50px;
    }

    span {
      font-size: 19px;
      letter-spacing: 1.44px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.md}) {
    font-size: 9px;
    height: 493px;

    button {
      top: 2.5vh;
      width: 80px;
      height: 80px;
    }

    &.menu-hidden {
      left: -120px;
    }

    span {
      font-size: 2.2em;
      letter-spacing: 1.44px;
    }

    .brand-wrapper {
      height: 42px;
      margin-top: 20px;
    }

    .svg-wrapper {
      width: 60px;
      margin-bottom: 20px;
    }

    .btn-row {
      padding-left: 80px;
      &:last-child {
        align-items: initial;
      }
    }

    &.open {
      width: 98%;
      max-width: 1190px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.lg}) {
    span {
      font-size: 2.4em;
      letter-spacing: 1.54px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xl}) {
    height: 600px;
    top: 8vh;
    font-size: 12px;
    &.menu-hidden {
      left: -134px;
    }

    &.open {
      max-width: 1700px;

      button {
        &:first-child {
          margin-left: 30px;
        }

        svg {
          max-height: 35px;
          min-width: 40px;
        }
      }
    }

    .space-around {
      justify-content: space-around;
    }

    .svg-wrapper {
      width: 80px;
      margin-bottom: 30px;
    }

    .brand-wrapper {
      height: 50px;
    }

    .btn-action {
      span {
        max-width: 190px;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xxl}) {
    top: 13vh;

    .btn-row {
      padding-left: 5.5vw;
    }

    .brand-wrapper {
      height: 65px;
      top: 2.5vh;
    }
  }
`

const SideMenuModalWrapper = (props: {
  children: React.ReactElement
  isOpen: boolean
  onClose: () => void
}) => (
  <Modal
    open={props.isOpen}
    onClose={props.onClose}
    keepMounted
    closeAfterTransition
    slots={{ backdrop: Backdrop }}
    slotProps={{ backdrop: { style: { backgroundColor: 'rgb(255, 255, 255, 0.75)' } } }}
  >
    <Slide direction="right" in={props.isOpen}>
      {props.children}
    </Slide>
  </Modal>
)

const SideMenu = () => {
  const { elementsOpen, setElementsOpen, filterOutUiElement } = useContextUI()
  const { areDetailsOpen, setDetailsOpen, tagFilter, geoFilter, applyGeoFilter, applyTagFilter } =
    useContextMotifDetails()
  const { selectedMotifs } = useContextSelectedMotifs()
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const widestTextWidth = useRef<number>()
  const { isKiosk } = useEnv()

  useEffect(() => {
    widestTextWidth.current = Math.max(
      ...Array.from(document.querySelectorAll('.btn-action span')).map(text => text.clientWidth),
    )
  }, [])

  useEffect(() => {
    if (elementsOpen.includes(UIElement.SIDEMENU)) {
      setIsOpen(true)
    } else if (isOpen) {
      setIsOpen(false)
    }
  }, [elementsOpen])

  const toggleTagFilter = () => {
    if (location.pathname !== routePaths.HOME) navigate(routePaths.HOME)
    if (areDetailsOpen) setDetailsOpen(false)

    if (elementsOpen.includes(UIElement.GALLERY)) {
      filterOutUiElement(UIElement.GALLERY)
    }
    if (elementsOpen.includes(UIElement.ABOUT)) {
      filterOutUiElement(UIElement.ABOUT)
    }
    if (tagFilter) {
      applyTagFilter(undefined)
      setElementsOpen([UIElement.CONTROLS])
    } else {
      setElementsOpen(
        elementsOpen.includes(UIElement.TAGFILTER) ? [UIElement.CONTROLS] : [UIElement.TAGFILTER],
      )
    }
  }

  const toggleGeoFilter = () => {
    if (location.pathname !== routePaths.HOME) navigate(routePaths.HOME)
    if (areDetailsOpen) setDetailsOpen(false)
    if (elementsOpen.includes(UIElement.GALLERY)) filterOutUiElement(UIElement.GALLERY)
    if (elementsOpen.includes(UIElement.ABOUT)) filterOutUiElement(UIElement.ABOUT)
    if (geoFilter) {
      applyGeoFilter(undefined)
      setElementsOpen([UIElement.CONTROLS])
    } else {
      setElementsOpen(
        elementsOpen.includes(UIElement.GEOFILTER) ? [UIElement.CONTROLS] : [UIElement.GEOFILTER],
      )
    }
  }

  const toggleHowWorks = () => {
    setElementsOpen(elementsOpen.includes(UIElement.EXPLANATION) ? [] : [UIElement.EXPLANATION])
  }

  const toggleSideMenu = () => {
    setElementsOpen(isOpen ? [] : [UIElement.SIDEMENU])
  }

  const handleOpenAboutProject = () => {
    setDetailsOpen(false)
    setElementsOpen([
      ...elementsOpen.filter(el => el !== UIElement.GALLERY && el !== UIElement.SIDEMENU),
      UIElement.ABOUT,
    ])
  }

  const handleOpenGallery = () => {
    setDetailsOpen(false)
    setElementsOpen([
      ...elementsOpen.filter(el => el !== UIElement.ABOUT && el !== UIElement.SIDEMENU),
      UIElement.GALLERY,
    ])
  }

  const handleOpenLegalNotice = () => {
    setElementsOpen([
      ...elementsOpen.filter(el => el !== UIElement.SIDEMENU),
      UIElement.LEGALNOTICE,
    ])
  }

  const handleNavigateHome = () => {
    if (location.pathname !== routePaths.HOME) {
      setElementsOpen([UIElement.CONTROLS])
      navigate(routePaths.HOME)
    }
  }

  const changeLanguage = () => {
    i18n.changeLanguage(i18n.language === 'hu' ? 'en' : 'hu')
  }

  return (
    <>
      <SideMenuModalWrapper isOpen={isOpen} onClose={toggleSideMenu}>
        <SideMenuWrapper className={`open ${isOpen ? 'slide-in' : ''}`}>
          <div className="btn-row header-row">
            <div className="brand-wrapper">
              <SVG className="brand-logo" src="/images/brandlogo.svg" />
            </div>

            <span className="language-switcher" onClick={changeLanguage}>
              {t('changeLanguage')}
            </span>
            <button className="x-button">
              <SVG className="x-svg" src="/icons/x.svg" onClick={toggleSideMenu} />
            </button>
          </div>
          <div className="btn-row space-around">
            {location.pathname !== routePaths.HOME ? (
              <div className="btn-action link-home" onClick={handleNavigateHome}>
                <span style={{ width: `${widestTextWidth.current}px` }}>{t('homePage')}</span>
              </div>
            ) : null}
            <div id="search-tematic" className="btn-action" onClick={() => toggleTagFilter()}>
              <div className="svg-wrapper">
                <SVG
                  className="search-tematic"
                  id="search-tematic"
                  src="/icons/search-tematic.svg"
                />
              </div>
              <span style={{ width: `${widestTextWidth.current}px` }}>{t('thematicSearch')}</span>
            </div>
            <div id="search-global" className="btn-action" onClick={() => toggleGeoFilter()}>
              <div className="svg-wrapper">
                <SVG className="search-global" id="search-global" src="/icons/search-global.svg" />
              </div>
              <span style={{ width: `${widestTextWidth.current}px` }}>{t('geographicSearch')}</span>
            </div>
            <div className="btn-action" onClick={() => toggleHowWorks()}>
              <div className="svg-wrapper">
                <SVG src="/icons/how-works.svg" />
              </div>
              <span style={{ width: `${widestTextWidth.current}px` }}>{t('howWorks')}</span>
            </div>
            <div className="btn-action" onClick={() => handleOpenGallery()}>
              <div className="svg-wrapper">
                <SVG src="/icons/gallery-sidebar.svg" />
              </div>
              <span style={{ width: `${widestTextWidth.current}px` }}>{t('seeGallery')}</span>
            </div>
            <div className="btn-action" onClick={() => handleOpenAboutProject()}>
              <div className="svg-wrapper">
                <SVG src="/icons/read-book.svg" />
              </div>
              <span style={{ width: `${widestTextWidth.current}px` }}>{t('readFromProject')}</span>
            </div>
          </div>
          <div className="btn-row copyright-row">
            <div className="reserved">
              {t('aboutProject.allRightsReserved')}
              {isKiosk ? (
                <div className="legal-notice" onClick={() => handleOpenLegalNotice()}>
                  {t('museumLegalNotice')}
                </div>
              ) : (
                <a
                  className="legal-notice"
                  href={
                    i18n.language === 'hu' ? links.HU_MUSEUMLEGALNOTICE : links.EN_MUSEUMLEGALNOTICE
                  }
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t('museumLegalNotice')}
                </a>
              )}
            </div>
          </div>
        </SideMenuWrapper>
      </SideMenuModalWrapper>
      <SideMenuButton />
      {selectedMotifs.length ? <MotifListControl /> : null}
    </>
  )
}

export default SideMenu

import styled from 'styled-components'
import { useContextSelectedMotifs } from '../../contexts/selectedMotifContext'
import { useNavigate, useLocation } from 'react-router-dom'
import routePaths from '../../constants/routePaths'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useContextUI } from '../../contexts/UIContext'
import { FeatureDiscoveryElement } from '../../types/FeatureDiscoveryElement'

const Container = styled.div`
  position: fixed;
  left: 0;
  bottom: 2vh;
  display: flex;
  align-items: center;
  height: 60px;

  .feature-discovery {
    position: absolute;
    left: 0;
    height: 100%;
    min-width: 220px;

    display: flex;
    align-items: center;
    position: absolute;

    background-color: ${({ theme }) => theme.colors.black};
    border-radius: 0 60px 60px 0;
    padding-left: calc(40px + 8px + 30px);
    padding-right: 30px;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.white};
    white-space: pre-wrap;
    user-select: none;

    transform: translateX(-50px);
    transition: all 0.4s;
    transition-delay: 0s;
    opacity: 0;

    &.show {
      transition-delay: 0.6s;
      transform: translateX(0);
      opacity: 1;
    }
  }

  @media (min-height: ${({ theme }) => theme.breakpoints.deviceH.xs}) {
    height: 70px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xs}) {
    .feature-discovery {
      min-width: 230px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xs}) and (min-height: ${({
      theme,
    }) => theme.breakpoints.deviceH.sm}) {
    bottom: 18vh;

    .feature-discovery {
      padding-right: 40px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    bottom: 9vh;
    height: 90px;

    .feature-discovery {
      font-size: 1.125rem;
      min-width: 300px;
      padding-left: calc(70px + 10px + 30px);
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.md}) {
    height: 100px;

    .feature-discovery {
      left: -15px;
      padding-left: calc(80px + 10px + 30px);
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.lg}) {
    height: 120px;

    .feature-discovery {
      font-size: 1.5rem;
      min-width: 370px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xl}) {
    .feature-discovery {
      padding-left: calc(90px + 10px + 30px);
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xxl}) {
    bottom: 15vh;
    height: 136px;

    .feature-discovery {
      min-width: calc(444px + 3vw);
      padding-right: 44px;
      border-radius: 0 80px 80px 0;
      padding-left: calc(122px + 3vw + 50px);
    }
  }
`

const CountWrapper = styled.button`
  background-color: ${({ theme }) => theme.colors.black95};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: relative;
  left: 8px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: ${({ theme }) => theme.colors.black95};
  font-size: 9px;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.2);
  transition: color 0.2s;

  &.shake {
    animation: shake-horizontally-faster 5s 2.5s ease infinite;
  }

  &.pulse {
    animation: pulse 0.2s;
  }

  &.red {
    background-color: ${({ theme }) => theme.colors.red};

    img {
      &.arrow {
        display: block;
      }

      &.house {
        display: none;
      }
    }
  }

  img {
    max-width: 30%;
    max-height: 30%;

    &.home {
      max-height: 60%;
      max-width: 60%;
    }

    &.arrow {
      display: none;
    }
  }

  @media (min-height: ${({ theme }) => theme.breakpoints.deviceH.xxs}) {
    height: 50px;
    width: 50px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    font-size: 9px;
    width: 70px;
    height: 70px;
    left: 10px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.md}) {
    font-size: 11px;
    width: 80px;
    height: 80px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xl}) {
    font-size: 16px;
    width: 90px;
    height: 90px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xxl}) {
    left: calc(3vw + 6px);
    width: 112px;
    height: 112px;
  }
`

const MotifCounter = () => {
  const { selectedMotifs } = useContextSelectedMotifs()
  const { featureDiscoveryElementsOpen } = useContextUI()
  const [pulsing, setPulsing] = useState<boolean>(false)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { t } = useTranslation()

  useEffect(() => {
    setPulsing(true)
  }, [selectedMotifs.length])

  const handleClick = () => {
    navigate(pathname === routePaths.GENERATE ? routePaths.HOME : routePaths.GENERATE)
  }

  return (
    <>
      {selectedMotifs.length === 0 ? null : (
        <Container>
          <div
            className={`feature-discovery
            ${featureDiscoveryElementsOpen.includes(FeatureDiscoveryElement.HOME) ? 'show' : ''}
          `}
          >
            {t('featureDiscovery.navigateBackHome')}
          </div>
          <CountWrapper
            className={`${pathname !== routePaths.GENERATE ? 'red' : ''} ${
              pulsing ? 'pulse' : ''
            } ${pathname !== routePaths.GENERATE ? 'shake' : ''}`}
            onClick={handleClick}
            onAnimationEnd={() => setPulsing(false)}
          >
            <img className="home house" src={'/icons/home_wt_heart_icon.svg'} alt="home-svg" />
            <img className="home arrow" src={'/icons/arrow_play_big.svg'} alt="arrow-svg" />
          </CountWrapper>
        </Container>
      )}
    </>
  )
}

export default MotifCounter

import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { useContextUI } from '../../contexts/UIContext'
import React, { useState, useEffect } from 'react'
import { UIElement } from '../../types/UIElements'
import { useContextScreensaver } from '../../contexts/screensaverContext'

const Button = styled.button`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  left: 10px;
  top: 2vh;
  background-color: ${({ theme }) => theme.colors.black95};
  color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => theme.colors.black};
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: 0.3s linear;
  z-index: 20;

  .side-button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    opacity: 0;
    animation: simple-fade-in 0.2s 0.25s forwards;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 45%;
    height: 45%;
  }

  &.fade-in {
    opacity: 1;
  }
  &.shake {
    animation: shake-horizontally 10s 2.5s ease infinite;
    transform-origin: center;
  }
  &:focus-visible {
    outline: none;
  }

  @media (min-height: ${({ theme }) => theme.breakpoints.deviceH.xxs}) {
    width: 50px;
    height: 50px;
  }

  @media (min-height: ${({ theme }) => theme.breakpoints.deviceH.xs}) {
    top: 10vh;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xs}) and (min-height: ${({
      theme,
    }) => theme.breakpoints.deviceH.sm}) {
    top: 18vh;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    width: 70px;
    height: 70px;
    top: 10.5vh;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.md}) {
    width: 80px;
    height: 80px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xl}) {
    width: 90px;
    height: 90px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xxl}) {
    left: calc(3vw + 6px);
    width: 112px;
    height: 112px;
    top: 15vh;
  }
`
const SideMenuButton = () => {
  const { elementsOpen, setElementsOpen } = useContextUI()
  const { setIsScreensaverOn } = useContextScreensaver()
  const [isVisible, setIsVisible] = useState<boolean>(false)

  useEffect(() => {
    if (!elementsOpen.includes(UIElement.WELCOME)) {
      setIsVisible(true)
    }
  }, [elementsOpen])

  const handleClick = (e: React.MouseEvent) => {
    setIsScreensaverOn(false)
    setElementsOpen(elementsOpen.includes(UIElement.SIDEMENU) ? [] : [UIElement.SIDEMENU])
    e.currentTarget?.classList.remove('shake')
  }

  return (
    <Button onClick={e => handleClick(e)} className={`shake ${isVisible ? 'fade-in' : ''}`}>
      <SVG src="/icons/hamburger.svg" />
    </Button>
  )
}
export default SideMenuButton

import styled from 'styled-components'
import { theme } from '../../styles/styled-components'

const Loader = styled.div<{ $color: string }>`
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  transform: scale(0.9) translateX(3%);

  div {
    position: absolute;
    top: 41.25%;
    width: 16.25%;
    height: 16.25%;
    border-radius: 50%;
    background: ${({ $color }) => $color};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  div:nth-child(1) {
    left: 10%;
    animation: lds-ellipsis1 0.6s infinite;
  }
  div:nth-child(2) {
    left: 10%;
    animation: lds-ellipsis2 0.6s infinite;
  }
  div:nth-child(3) {
    left: 40%;
    animation: lds-ellipsis2 0.6s infinite;
  }
  div:nth-child(4) {
    left: 70%;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(175%, 0);
    }
  }
`

const DotDotLoader = ({ color }: { color?: string }) => {
  return (
    <Loader $color={color ?? theme.colors.white}>
      <div />
      <div />
      <div />
      <div />
    </Loader>
  )
}

export default DotDotLoader

import { makeUseAxios } from 'axios-hooks'
import axios from 'axios'

export const useStrapi = makeUseAxios({
  axios: axios.create({
    baseURL: process.env.REACT_APP_STRAPI_URL,
  }),
  defaultOptions: {
    useCache: false,
  },
})

export const useAxiosStrapi = () => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_STRAPI_URL,
  })

  return {
    get: axiosInstance.get,
    post: axiosInstance.post,
  }
}

export const useApi = makeUseAxios({
  axios: axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  }),
  defaultOptions: {
    useCache: false,
  },
})

export const useAxiosApi = () => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  })

  return {
    get: axiosInstance.get,
    post: axiosInstance.post,
  }
}

import styled from 'styled-components'
import { useContextUI } from '../../contexts/UIContext'
import { useContextMotifDetails } from '../../contexts/motifDetailsContext'
import { Tag } from '../../constants/tags'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import FloatingXButton from '../FloatingXButton'
import { UIElement } from '../../types/UIElements'

const TagFilterWrapper = styled.div`
  opacity: 0;
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  animation: simple-fade-in 0.5s forwards;

  .anchor {
    position: fixed;
    top: 50%;
  }

  &.fade-out {
    animation: simple-fade-out 0.5s forwards;
  }

  button {
    position: absolute;
    background: ${({ theme }) => theme.colors.black95};
    border-radius: 50%;
    overflow: hidden;
    border: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: clamp(8px, calc(100vw / 125), 16px);

    img {
      width: 100%;
      height: 100%;
      font-size: 14px;
      color: white;
      line-height: 8em;

      @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.lg}) {
        font-size: 18px;
      }
    }
  }

  .human {
    top: -30vh;
    left: 13vw;
    width: 17.0625em;
    height: 17.0625em;
  }

  .geometric {
    top: -26vh;
    left: 30vw;
    width: 21.813em;
    height: 21.813em;
  }

  .text {
    top: -35vh;
    left: 63vw;
    width: 14.875em;
    height: 14.875em;
  }

  .crest {
    top: -23vh;
    left: 82vw;
    width: 15.813em;
    height: 15.813em;
  }

  .floral {
    top: -1vh;
    left: 13vw;
    width: 25.438em;
    height: 25.438em;
  }

  .bird {
    top: 17vh;
    left: 36vw;
    width: 15.813em;
    height: 15.813em;
  }

  .animal {
    top: -8vh;
    left: 50vw;
    width: 21.5em;
    height: 21.5em;
  }

  .shape {
    top: 6vh;
    left: 71vw;
    width: 18.375em;
    height: 18.375em;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    .human {
      top: -48vh;
      left: 13vw;
      width: 14.0625em;
      height: 14.0625em;
    }

    .geometric {
      top: -47vh;
      left: 33vw;
      width: 19.813em;
      height: 19.813em;
    }

    .text {
      top: -48vh;
      left: 64vw;
      width: 12.875em;
      height: 12.875em;
    }

    .crest {
      top: -28vh;
      left: 79vw;
      width: 13.813em;
      height: 13.813em;
    }

    .floral {
      top: -8vh;
      left: 13.5vw;
      width: 22.438em;
      height: 22.438em;
    }

    .bird {
      top: 17vh;
      left: 42vw;
      width: 13.813em;
      height: 13.813em;
    }

    .animal {
      top: -17vh;
      left: 55vw;
      width: 19.5em;
      height: 19.5em;
    }

    .shape {
      top: 10vh;
      left: 78vw;
      width: 16.375em;
      height: 16.375em;
    }
  }
`

const TagFilter = () => {
  const { elementsOpen, setElementsOpen, setOverlayVisible } = useContextUI()
  const { applyTagFilter, applyGeoFilter } = useContextMotifDetails()
  const { i18n } = useTranslation()
  const [fadeOut, setFadeOut] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (elementsOpen.includes(UIElement.TAGFILTER)) {
      setIsOpen(true)
      applyGeoFilter(undefined)
    } else if (isOpen) {
      close()
    }
  }, [elementsOpen])

  const close = () => {
    applyTagFilter(undefined)
    setFadeOut(true)
    setOverlayVisible(false)
  }

  const handleClick = (tag: Tag | undefined) => {
    applyTagFilter(tag)
    setFadeOut(true)
    setOverlayVisible(false)
  }

  const handleCloseTagFilter = () => {
    setElementsOpen([UIElement.CONTROLS])
    close()
  }

  const handleAnimationEnd = () => {
    if (fadeOut) {
      setIsOpen(false)
      setFadeOut(false)
    }
  }

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const el = e.target as HTMLElement
    if (el.classList.contains('outside')) {
      handleCloseTagFilter()
    }
  }

  const filterButtons = [
    {
      name: 'human',
      action: () => handleClick(Tag.HUMAN),
    },
    {
      name: 'geometric',
      action: () => handleClick(Tag.GEOMETRIC),
    },
    {
      name: 'text',
      action: () => handleClick(Tag.TEXT),
    },
    {
      name: 'crest',
      action: () => handleClick(Tag.CREST),
    },
    {
      name: 'floral',
      action: () => handleClick(Tag.FLORAL),
    },
    {
      name: 'bird',
      action: () => handleClick(Tag.BIRD),
    },
    {
      name: 'animal',
      action: () => handleClick(Tag.ANIMAL),
    },
    {
      name: 'shape',
      action: () => handleClick(Tag.SHAPE),
    },
  ]

  return isOpen ? (
    <>
      <TagFilterWrapper
        className={`outside ${fadeOut ? 'fade-out' : ''}`}
        onClick={e => handleOutsideClick(e)}
        onAnimationEnd={handleAnimationEnd}
      >
        <div className="anchor">
          {filterButtons.map(button => (
            <button
              key={button.name}
              className={button.name}
              onClick={button.action}
              style={{ animation: `shake ${Math.random() * (4.5 - 1.5) + 1.5 + 's'} infinite` }}
            >
              <img
                src={`/icons/filter-buttons/${button.name}-filter-${i18n.language}.png`}
                alt={`${button.name} filter button`}
              />
            </button>
          ))}
        </div>
      </TagFilterWrapper>
      <FloatingXButton onClick={handleCloseTagFilter} />
    </>
  ) : null
}

export default TagFilter

import styled from 'styled-components'
import { useContextUI } from '../../contexts/UIContext'
import { useTranslation } from 'react-i18next'

const SnackbarWrapper = styled.div`
  display: flex;
  justify-content: center;

  &.unset {
    display: none;
  }

  .message-container {
    position: fixed;
    bottom: 0;
    min-height: 38px;
    max-width: 58vw;
    border: none;
    border-radius: 20px 20px 0 0;
    box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.2);
    background-color: ${({ theme }) => theme.colors.black};
    display: flex;
    align-items: center;
  }

  .play {
    animation: slide-up 0.3s ease-in-out;
  }

  .pause {
    animation: slide-out 0.3s ease-in-out;
  }

  span {
    padding: 8px 20px;
    font-size: 14px;
    font-family: ${({ theme }) => theme.font.medium};
    line-height: initial;
    color: ${({ theme }) => theme.colors.white};
    text-align: center;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xs}) {
    .message-container {
      max-width: 48vw;
    }
    span {
      padding: 12px 24px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    span {
      font-size: 18px;
      padding: 18px 40px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.md}) {
    .message-container {
      border-radius: 24px 24px 0 0;
      min-height: 45px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.lg}) {
    span {
      font-size: 24px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xl}) {
    .message-container {
      max-width: 45vw;
      border-radius: 39px 39px 0 0;
      min-height: 82px;
    }

    span {
      padding: 25px 80px;
      font-size: 24px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xxl}) {
    .message-container {
      border-radius: 69px 69px;
      bottom: 6vh;
    }

    span {
      font-size: 26px;
    }
  }
`

const Snackbar = () => {
  const { snackbarText, snackbarVisibilityState } = useContextUI()
  const { t } = useTranslation()

  return (
    <SnackbarWrapper className={snackbarVisibilityState}>
      <div className={`message-container ${snackbarVisibilityState}`}>
        <span>{t(snackbarText)}</span>
      </div>
    </SnackbarWrapper>
  )
}

export default Snackbar

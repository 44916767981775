import React from 'react'
import { PositionXY } from '../types/PositionXY'

export const getPagePositionXY = (event: React.MouseEvent | React.TouchEvent): PositionXY => {
  let pageX = 0
  let pageY = 0

  if (event.nativeEvent instanceof MouseEvent) {
    pageX = event.nativeEvent.pageX
    pageY = event.nativeEvent.pageY
  } else if (event.nativeEvent instanceof TouchEvent) {
    pageX = event.nativeEvent.touches[0].clientX
    pageY = event.nativeEvent.touches[0].clientY
  }

  return {
    x: pageX,
    y: pageY,
  }
}

import styled from 'styled-components'

export const AboutProjectWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 23px;
  opacity: 0;
  transform: translateX(50px);

  &.fade-in {
    animation: fade-in-x50 0.5s ease forwards;
  }

  &.fade-out {
    animation: fade-out-x50 0.5s ease forwards;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) and (max-height: ${({
      theme,
    }) => theme.breakpoints.deviceH.sm}) {
    width: calc(100vw - 82px);
    padding-left: 8px;
    padding-right: 12px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    flex-direction: row;
    left: 0;
    width: 100vw;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    padding-left: 172px;
    overflow-x: auto;
    overflow-y: hidden;
    gap: 46px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xxl}) {
    padding-left: 10vw;
  }
`

export const Container = styled.div`
  height: 100%;
  font-size: 12px;
  width: 100%;
  margin: 0 0 100px 0;

  .wrapper {
    overflow: hidden;
    height: 100%;
    width: 100%;
    grid-template-columns: auto;
    grid-template-rows: repeat(6, auto);
    opacity: 0;
    transform: translateY(30px);
    gap: 30px;
    align-items: center;
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 24px;
    padding: 18px 32px;
    display: flex;
    flex-direction: column;

    &.fade-in {
      animation: fade-in-y30 0.3s 0.3s ease forwards;
    }

    &.fade-out {
      animation: fade-out-y30 0.3s ease forwards;
    }

    .disable-click {
      pointer-events: none;
    }

    .about-project {
      width: 100%;

      header {
        margin-bottom: 21px;

        svg {
          display: block;
          height: 40px;
          width: 235.6px;
        }
      }

      .title {
        font-size: 22px;
        color: rgba(0, 0, 0, 0.87);
        font-size: 22px;
        font-family: ${({ theme }) => theme.font.semiBold};
        letter-spacing: 0.35px;
        line-height: 28px;
        margin-bottom: 15px;
      }

      .subtitle {
        color: rgba(0, 0, 0, 0.49);
        font-size: 18px;
        font-family: ${({ theme }) => theme.font.medium};
        letter-spacing: 0.29px;
        line-height: 24px;
        white-space: pre-wrap;
      }

      .creators-description {
        white-space: pre-wrap;
        font-size: 15px;
        font-family: ${({ theme }) => theme.font.regular};
        color: ${({ theme }) => theme.colors.black49};
        letter-spacing: 0;
        line-height: 25px;
        margin: 0;
        margin-top: 6px;

        &.subtitle {
          font-size: 21px;
          font-family: ${({ theme }) => theme.font.medium};
          margin-bottom: 0;
        }
      }
    }

    .generated-pattern-container {
      display: flex;
      flex-direction: inherit;
      gap: 35px;

      .generated-pattern {
        width: 100%;
      }
    }

    .poster-container {
      img {
        max-width: 100%;
      }
    }

    .text-container {
      color: rgba(0, 0, 0, 0.49);
      margin-top: 13px;
      font-size: 16px;
      letter-spacing: 0.38px;
      line-height: 22px;
    }

    .how-was-it-made {
      display: flex;
      gap: 100px;
      align-items: center;

      .story {
        height: 605px;
      }

      .illustration {
        height: auto;
        width: auto;
      }

      img {
        height: 755px;
      }
    }

    .description {
      color: rgba(0, 0, 0, 0.49);
      margin-top: 13px;
      font-size: 16px;
      letter-spacing: 0.38px;
      line-height: 22px;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
      flex-direction: row;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    min-height: calc(100vh - 2 * 10vh);
    font-size: 14px;
  }

  @media (min-height: ${({ theme }) => theme.breakpoints.deviceH.sm}) {
    font-size: 16px;
    width: unset;
    display: flex;
    align-items: center;
    margin: 0 50px 0 0;

    .wrapper {
      height: 700px;
      padding: 114px 92px;
      gap: 100px;
      grid-template-columns: repeat(6, auto);
      grid-template-rows: 1fr;

      .with-gap {
        margin-top: 15px;
      }

      .about-project {
        width: 695px;

        header {
          svg {
            height: 45px;
            width: 265.05px;
          }
        }

        &.awards {
          height: 605px;
        }

        &.summary {
          height: 510px;
        }

        &.intro {
          height: 625px;
        }

        .title {
          font-size: 42px;
          letter-spacing: 1.62px;
          line-height: 60px;
          margin-bottom: 0;
        }

        .subtitle {
          margin-bottom: 21px;
          font-size: 30px;
          letter-spacing: 0;
          line-height: 44px;
        }

        .creators-description {
          font-size: 18px;

          .creators-subtitle {
            font-size: 21px;
          }
        }

        .all-rights {
          font-size: 14px;
        }
      }

      .generated-pattern-container {
        gap: 100px;

        .generated-pattern {
          width: 500px;
          height: auto;
        }
      }

      .poster-container {
        img {
          height: 560px;
          max-width: unset;
        }
      }

      .text-container {
        width: 695px;
        font-size: 21px;
        letter-spacing: 0.5px;
        line-height: 32px;
      }

      .description {
        font-size: 21px;
        letter-spacing: 0.5px;
        line-height: 32px;
        margin-bottom: 21px;
        width: auto;
      }
    }
  }

  @media (min-height: ${({ theme }) => theme.breakpoints.deviceH.lg}) {
    display: flex;
    align-items: center;

    .wrapper {
      height: 850px;

      .how-was-it-made {
        img {
          height: 885px;
        }
      }
    }
  }
`

export const ButtonWrapper = styled.div`
  position: absolute;
  top: 18px;
  right: 8px;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-y: hidden;
  pointer-events: none;

  svg {
    height: 24px;
    width: 24px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    top: 0;
    right: 0;
    width: 150px;

    svg {
      height: 30px;
      width: 30px;
    }
  }
`

export const Button = styled.button`
  height: 50px;
  width: 289px;
  border: 1px solid ${({ theme }) => theme.colors.borderGrey};
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.white};
  text-align: right;
  padding: 0 10px;
  float: right;
  margin-bottom: 21px;

  span {
    white-space: pre-wrap;
    font-size: 14px;
    letter-spacing: 0.65px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.black70};
    font-family: ${({ theme }) => theme.font.regular};
  }

  @media (min-height: ${({ theme }) => theme.breakpoints.deviceH.md}) {
    width: 334px;
    height: 65px;

    span {
      font-size: 16px;
      line-height: 24px;
    }
  }
`

export const AwardsWrapper = styled.div`
  height: 100px;
  width: 100%;
  border-radius: 17px;
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 27px;
  padding: 20px 15px;

  @media (min-height: ${({ theme }) => theme.breakpoints.deviceH.md}) {
    height: 176px;
    width: 687px;
  }
`

export const AwardsText = styled.div`
  height: 125px;
  width: 479px;
  color: #ffffff;
  letter-spacing: 0.4px;
  line-height: 20px;
  display: flex;
  align-items: center;

  @media (min-height: ${({ theme }) => theme.breakpoints.deviceH.md}) {
    font-size: 16px;
    line-height: 25px;
  }
`

export const AwardsLogo = styled.div`
  margin-right: 35px;
  width: 60px;

  img {
    height: 100%;
    width: 100%;
  }

  @media (min-height: ${({ theme }) => theme.breakpoints.deviceH.md}) {
    height: 87px;
    width: 80px;
  }
`

export const HorinzontalRule = styled.div`
  margin-bottom: 21px;
  padding-bottom: 21px;
  border-bottom: 1px solid #dbdbdb;

  @media (min-height: ${({ theme }) => theme.breakpoints.deviceH.md}) {
  }
`

export const LogoWrapper = styled.div`
  display: flex;
  gap: 40px;

  img {
    height: 25px;
  }
`

import { Vector } from './vector'
import { Constants } from './constants'
import { TileKind } from './tilekind'
import { TilingPro } from './tilingpro'
import { Tile, TilePart } from './tile'
import { Tessellation } from './tessellation'

export enum PointStyle {
  DISC,
  CROSS,
}

export interface CanvasToolsStyle {
  pointColor: string
  pointSize: number
  pointStyle: PointStyle
  vectorColor: string
  vectorLineWidth: number
  vectorArrowLength: number
  tileColor: string
  tileLineWidth: number
  tileShowNeighbors: boolean
  tileShowNumber: boolean
  tileShowArc: boolean
  tileShowLetters: boolean
  arcColor: string
  arcLineWidth: number
  captionColor: string
}

export class CanvasTools {
  ctx: CanvasRenderingContext2D
  style: CanvasToolsStyle
  stack: CanvasToolsStyle[]

  constructor(ctx: CanvasRenderingContext2D) {
    this.ctx = ctx
    this.stack = []
    this.style = {
      pointColor: '#0a0',
      pointSize: 5,
      pointStyle: PointStyle.DISC,
      vectorColor: '#888',
      vectorLineWidth: 1,
      vectorArrowLength: 10,
      tileColor: '#aaa',
      tileLineWidth: 0.5,
      tileShowNeighbors: false,
      tileShowNumber: false,
      tileShowArc: false,
      tileShowLetters: false,
      arcColor: '#800',
      arcLineWidth: 1,
      captionColor: '#aaa',
    }
  }

  resetCartesian(): void {
    this.ctx.scale(1, -1)
    this.ctx.translate(this.ctx.canvas.width / 2, -this.ctx.canvas.height / 2)
  }

  save(): void {
    this.stack.push({
      pointColor: this.style.pointColor,
      pointSize: this.style.pointSize,
      pointStyle: this.style.pointStyle,
      vectorColor: this.style.vectorColor,
      vectorLineWidth: this.style.vectorLineWidth,
      vectorArrowLength: this.style.vectorArrowLength,
      tileColor: this.style.tileColor,
      tileLineWidth: this.style.tileLineWidth,
      tileShowNeighbors: this.style.tileShowNeighbors,
      tileShowNumber: this.style.tileShowNumber,
      tileShowArc: this.style.tileShowArc,
      tileShowLetters: this.style.tileShowLetters,
      arcColor: this.style.arcColor,
      arcLineWidth: this.style.arcLineWidth,
      captionColor: this.style.captionColor,
    })
  }

  restore(): void {
    if (this.stack.length > 0) {
      this.style = this.stack.pop()!
    }
  }

  /**
   * Same as {@link CanvasRenderingContext2D#moveTo} just with vector param.
   * @param {Vector} v Where to move to.
   * @returns {CanvasTools}
   */
  moveTo(v: Vector): CanvasTools {
    this.ctx.moveTo(v.x, v.y)
    return this
  }

  /**
   * Same as {@link CanvasRenderingContext2D#lineTo} just with vector param.
   * @param {Vector} v Where to draw the line to.
   * @returns {CanvasTools}
   */
  lineTo(v: Vector): CanvasTools {
    this.ctx.lineTo(v.x, v.y)
    return this
  }

  /**
   * Same as {@link CanvasRenderingContext2D#bezierCurveTo} just with vector param.
   * @param {Vector} c1
   * @param {Vector} c2
   * @param {Vector} p
   * @returns {CanvasTools}
   */
  bezierTo(c1: Vector, c2: Vector, p: Vector): CanvasTools {
    this.ctx.bezierCurveTo(c1.x, c1.y, c2.x, c2.y, p.x, p.y)
    return this
  }

  drawPoint(p: Vector): CanvasTools {
    let e: Vector
    if (this.style.pointStyle === PointStyle.CROSS) {
      e = new Vector(this.style.pointSize / 2, 0).rotate(-Constants.RAD_135)
      this.ctx.strokeStyle = this.style.pointColor
      this.ctx.beginPath()
      this.moveTo(p.add(e)).lineTo(p.add(e.inv()))
      e = e.rotate(Constants.RAD_90)
      this.moveTo(p.add(e)).lineTo(p.add(e.inv()))
      this.ctx.stroke()
    } else {
      this.ctx.fillStyle = this.style.pointColor
      this.ctx.beginPath()
      this.ctx.arc(p.x, p.y, this.style.pointSize / 2, 0, Constants.RAD_360)
      this.ctx.fill()
    }
    return this
  }

  /**
   * Draws a vector from v1 to v2.
   * @param {Vector} v1
   * @param {Vector} v2
   * @return {CanvasTools}
   */
  drawVector(v1: Vector, v2: Vector): CanvasTools {
    let av = v1.sub(v2).setLength(this.style.vectorArrowLength)
    let av1 = v2.add(av.rotate(Constants.RAD_30))
    let av2 = v2.add(av.rotate(-Constants.RAD_30))
    this.ctx.strokeStyle = this.style.vectorColor
    this.ctx.lineWidth = this.style.vectorLineWidth
    this.ctx.beginPath()
    this.moveTo(v1).lineTo(v2)
    this.ctx.stroke()
    this.ctx.fillStyle = this.style.vectorColor
    this.ctx.beginPath()
    this.moveTo(av1).lineTo(v2).lineTo(av2)
    this.ctx.fill()

    // this.lineTo(av1).moveTo(v2).lineTo(av2);
    return this
  }

  /**
   * Draws a vector from v1 to v2 with the given caption.
   * @param {Vector} v1
   * @param {Vector} v2
   * @param {string} caption
   * @return {CanvasTools}
   */
  drawCaptionedVector(v1: Vector, v2: Vector, caption: string): CanvasTools {
    let h = v2.sub(v1).mul(0.5)
    let ho = h.rotate(Constants.RAD_90).setLength(10)
    let cp = v1.add(h).add(ho)
    this.drawVector(v1, v2)
    this.ctx.fillStyle = this.style.captionColor
    this.ctx.fillText(caption, cp.x, cp.y)
    return this
  }

  drawTilingPro(tilingPro: TilingPro, scale: number) {
    tilingPro.getHalfTiles().forEach(halfTile => {
      this.drawTile(halfTile, scale)
    })
    return this
  }

  drawTessellation(tessellation: Tessellation, scale: number) {
    for (let t of tessellation) {
      this.drawTile(t, scale)
    }
  }

  private curvedSectionTo(a: Vector, b: Vector, sign: number): CanvasTools {
    let r = 0.3
    let d = Math.PI / 9
    this.bezierTo(
      a.add(
        b
          .sub(a)
          .mul(r)
          .rotate(d * sign),
      ),
      b.add(
        a
          .sub(b)
          .mul(r)
          .rotate(-d * sign),
      ),
      b,
    )
    return this
  }

  drawTile(halfTile: Tile, scale: number): CanvasTools {
    let points = halfTile.getNormalizedPoints()

    this.ctx.strokeStyle = this.style.tileColor

    this.ctx.lineWidth = this.style.tileLineWidth
    this.ctx.beginPath()
    this.moveTo(points[0].mul(scale))
    this.lineTo(points[1].mul(scale))
    this.lineTo(points[2].mul(scale))
    if (halfTile.part === TilePart.WHOLE) {
      this.lineTo(points[3].mul(scale))
    }
    this.lineTo(points[0].mul(scale))
    this.ctx.stroke()
    this.ctx.stroke()

    switch (halfTile.kind) {
      case TileKind.KITE:
        this.style.vectorColor = '#800'
        break
      case TileKind.DART:
        this.style.vectorColor = '#080'
        break
      case TileKind.THICK_RHOMB:
        this.style.vectorColor = '#008'
        break
      case TileKind.THIN_RHOMB:
        this.style.vectorColor = '#088'
        break
    }

    this.style.vectorLineWidth = 0.5
    // this.drawVector(halfTile.origin.mul(scale), halfTile.origin.add(halfTile.abscissa).mul(scale))

    return this
  }
}

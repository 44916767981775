import { JSONExportEntry } from '../types'
import { getTransformProperties } from '../util'

export const initializeAroundMotifs = (
  size: number,
  yPosition: number,
  centerAxisRotation: number,
  motifId: number,
  motif: HTMLImageElement,
  ctx: CanvasRenderingContext2D,
  canvas: HTMLCanvasElement,
  AMOUNT_OF_MOTIFS_AROUND: number,
): JSONExportEntry[] => {
  const entries: JSONExportEntry[] = []
  let rotationAngle = 360 / AMOUNT_OF_MOTIFS_AROUND
  const xPosition = canvas.offsetWidth / 2 - size / 2
  for (let i = 0; i < AMOUNT_OF_MOTIFS_AROUND; i++) {
    if (i === 0) rotationAngle = 0
    ctx.save()
    ctx.translate(canvas.offsetWidth / 2, canvas.offsetHeight / 2)
    ctx.rotate((Math.PI / 180) * rotationAngle)
    ctx.translate(-canvas.offsetWidth / 2, -canvas.offsetHeight / 2)
    ctx.translate(xPosition + size / 2, yPosition + size / 2)
    ctx.rotate(centerAxisRotation)
    ctx.drawImage(motif, -size / 2, -size / 2, size, size)

    const { origin, scale, rotation } = getTransformProperties(ctx)
    ctx.restore()

    entries.push({
      id: motifId,
      x: origin.x,
      y: origin.y,
      rotation: rotation,
      size: size * scale,
    })

    rotationAngle += 360 / AMOUNT_OF_MOTIFS_AROUND
  }

  return entries
}

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
  VelocityType,
} from 'react-zoom-pan-pinch'
import { useContextMotifDetails } from '../../contexts/motifDetailsContext'
import { Circle } from './generate'
import { gcd, fetchAsBlob } from './util'
import { useContextUI } from '../../contexts/UIContext'
import { isMobile } from 'react-device-detect'
import InteractionMotivator from '../InteractionMotivator'
import { useContextInteractionMotivator } from '../../contexts/interactionMotivatorContext'

const MapContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .image-container {
    cursor: pointer;
    opacity: 0;
    animation: zoom-in 1s ease-in-out ${Math.random()}s forwards;

    img {
      opacity: 1;
      transition: 1.5s linear;
    }

    &.filter-out {
      cursor: initial;

      img {
        opacity: 0.15;
      }
    }
  }
`

const MIN_LOADED_MOTIFS = 50

let FILTER_ZOOM_RATIO: number
if (isMobile) {
  FILTER_ZOOM_RATIO = 0.5
} else {
  FILTER_ZOOM_RATIO = 0.25
}

let MAX_ZOOM: number
if (isMobile) {
  MAX_ZOOM = 4
} else {
  MAX_ZOOM = 3
}

const DETAILED_IMAGE_LOAD_DELAY = 250

const MotifMap = () => {
  const {
    allMotifs,
    filteredMotifs,
    setTargetedMotif,
    applyTagFilter,
    applyGeoFilter,
    motifMap,
    motifMapViewport,
    motifImageCache,
  } = useContextMotifDetails()
  const { setIsInitialLoading, showErrorMessage } = useContextUI()
  const { isInteractionMotivatorShownWithHideAnimation } = useContextInteractionMotivator()

  const zoomPanPinchRef = useRef<ReactZoomPanPinchRef>(null)
  const filteredMotifsIds = useMemo(() => new Set(filteredMotifs.map(m => m.id)), [filteredMotifs])
  const [loadedMotifs, setLoadedMotifs] = useState<Record<number, Circle>>({})
  const [idsForUsingLargeImages, setIdsForUsingLargeImages] = useState(new Set<number>())

  // on unmount, cancel loading images and save viewport
  const imageLoadingCancelRef = useRef(false)
  useEffect(() => {
    return () => {
      imageLoadingCancelRef.current = true
    }
  }, [])

  const handleClick = useCallback(
    (target: HTMLDivElement, id: number) => {
      if (target.matches('.filter-out')) return

      setTargetedMotif(id)
    },
    [setTargetedMotif],
  )

  // return promise to track image load completion
  const fetchImageData = async (circle: Circle) => {
    if (imageLoadingCancelRef.current) return
    const imageCache = motifImageCache.current

    if (imageCache.get(circle.id) === null) return

    if (imageCache.get(circle.id) === undefined) {
      motifImageCache.current.set(circle.id, null)
      let blob, blobXs

      try {
        blob = await fetchAsBlob(circle.url)
      } catch {}

      try {
        blobXs = await fetchAsBlob(circle.urlXs)
      } catch {}

      // only fail if both blobs fail to fetch
      let url, urlXs
      if (blob && blobXs) {
        url = URL.createObjectURL(blob)
        urlXs = URL.createObjectURL(blobXs)
      } else if (blob) {
        blobXs = blob

        url = URL.createObjectURL(blob)
        urlXs = URL.createObjectURL(blobXs)
      } else if (blobXs) {
        blob = blobXs

        url = URL.createObjectURL(blob)
        urlXs = URL.createObjectURL(blobXs)
      } else {
        console.error(`Could not download ${circle.id} in full or thumbnail size`)
        return
      }

      motifImageCache.current.set(circle.id, { blob, url, blobXs, urlXs })
    }
  }

  const drawImages = () => {
    if (!motifMap) return

    setLoadedMotifs(() => {
      const newLoadedMotifs: Record<number, Circle> = {}
      motifMap.circles.forEach(circle => {
        const imageData = motifImageCache.current.get(circle.id)
        if (imageData) {
          newLoadedMotifs[circle.id] = { ...circle, url: imageData.url, urlXs: imageData.urlXs }
        }
      })

      return newLoadedMotifs
    })
  }

  // main effect for generating and loading motif map
  useEffect(() => {
    setIsInitialLoading(true)

    if (!motifMap || !zoomPanPinchRef.current) return

    const circles = motifMap.circles

    // select images for priority loading
    const wh_gcd = gcd(motifMap.mapSize[0], motifMap.mapSize[1])
    const w_ratio = motifMap.mapSize[0] / wh_gcd
    const h_ratio = motifMap.mapSize[1] / wh_gcd

    const w_center = motifMap.mapSize[0] / 2
    const h_center = motifMap.mapSize[1] / 2

    // sort images using a rectangular distance function where:
    // d(z, 0) = d(0, z) = d(z, z)
    const sorted = circles.sort((a, b) => {
      const ax = (a.x - w_center) / w_ratio
      const ay = (a.y - h_center) / h_ratio
      const bx = (b.x - w_center) / w_ratio
      const by = (b.y - h_center) / h_ratio

      const distA = Math.abs(ax) + Math.abs(ay) + Math.abs(Math.abs(ax) - Math.abs(ay))
      const distB = Math.abs(bx) + Math.abs(by) + Math.abs(Math.abs(bx) - Math.abs(by))
      return distA - distB
    })

    // start loading images in batches, taking priority into account
    Promise.all(sorted.slice(0, MIN_LOADED_MOTIFS).map(c => fetchImageData(c)))
      .then(async () => {
        drawImages()
        setIsInitialLoading(false)

        let remainingMotifs = sorted.slice(MIN_LOADED_MOTIFS)
        while (remainingMotifs.length > 0) {
          await Promise.all(remainingMotifs.slice(0, MIN_LOADED_MOTIFS).map(c => fetchImageData(c)))
          remainingMotifs = remainingMotifs.slice(MIN_LOADED_MOTIFS)

          drawImages()
        }
      })
      .catch(e => {
        showErrorMessage('APIErrorMessage', true)
        console.log('Could not fetch motif images', e)
      })
  }, [motifMap, zoomPanPinchRef.current])

  useEffect(() => {
    return () => {
      applyTagFilter(undefined)
      applyGeoFilter(undefined)
    }
  }, [])

  useEffect(() => {
    if (
      !motifMap ||
      !zoomPanPinchRef.current ||
      !zoomPanPinchRef.current.instance.wrapperComponent ||
      !zoomPanPinchRef.current.instance.contentComponent ||
      filteredMotifs.length === 0 ||
      filteredMotifs.length === allMotifs.length
    )
      return

    const distance = (p1: [number, number], p2: [number, number]) =>
      Math.pow(p1[0] - p2[0], 2) + Math.pow(p1[1] - p2[1], 2)

    // NOTE: code assumes viewport fills entire screen
    const contentBox = zoomPanPinchRef.current.instance.contentComponent.getBoundingClientRect()
    const scaleX = contentBox.width / motifMap.mapSize[0]
    const scaleY = contentBox.height / motifMap.mapSize[1]
    const center: [number, number] = [
      (-contentBox.left + (contentBox.width - -contentBox.left - contentBox.right) / 2) / scaleX,
      (-contentBox.top + (contentBox.height - -contentBox.top - contentBox.bottom) / 2) / scaleY,
    ]

    const [closestCircle] = motifMap.circles
      .filter(c => filteredMotifsIds.has(c.id))
      .map(c => [c, distance(center, [c.x, c.y])])
      .reduce((prev, curr) => {
        if (prev.length === 0 || curr[1] < prev[1]) {
          return curr
        } else {
          return prev
        }
      }, []) as [Circle, number]

    const motifSize = closestCircle.r * 2
    const averageWindowSize = Math.min(window.innerWidth, window.innerHeight)
    const scale = FILTER_ZOOM_RATIO / (motifSize / averageWindowSize)

    zoomPanPinchRef.current.zoomToElement(`motif-${closestCircle.id}`, scale)
  }, [filteredMotifs])

  // bounce click events when the canvas is moving
  const velocityRef = useRef<VelocityType>()
  const zoomTimerRef = useRef<number>()

  return (
    <>
      <MapContainer>
        {isInteractionMotivatorShownWithHideAnimation ? <InteractionMotivator /> : null}
        {motifMap && (
          <TransformWrapper
            ref={zoomPanPinchRef}
            minScale={0.5}
            maxScale={MAX_ZOOM}
            centerZoomedOut
            initialScale={1.2}
            doubleClick={{ disabled: true }}
            centerOnInit={!motifMapViewport.current}
            onInit={ref => {
              const viewport = motifMapViewport.current
              if (viewport) {
                ref.instance.setTransformState(
                  viewport.scale,
                  viewport.positionX,
                  viewport.positionY,
                )
              }
            }}
            onTransformed={ref => {
              velocityRef.current = ref.instance.velocity || undefined
              motifMapViewport.current = ref.state

              if (zoomTimerRef.current) {
                clearInterval(zoomTimerRef.current)
                zoomTimerRef.current = undefined
              }

              if (idsForUsingLargeImages.size > 0) setIdsForUsingLargeImages(new Set())

              // NOTE: for some reason, typescript uses NodeJS types for setTimeout
              zoomTimerRef.current = setTimeout(() => {
                const imageContainers = Array.from(document.querySelectorAll('.image-container'))
                setIdsForUsingLargeImages(
                  new Set(
                    imageContainers
                      .filter(container => {
                        const rect = container.getBoundingClientRect()
                        return (
                          // is large enough
                          (rect.width > 156 || rect.height > 156) &&
                          // intersects viewport
                          rect.left < window.innerWidth &&
                          rect.right > 0 &&
                          rect.top < window.innerHeight &&
                          rect.bottom > 0
                        )
                      })
                      .map(container => parseInt(container.getAttribute('id')!.split('-')[1])),
                  ),
                )
              }, DETAILED_IMAGE_LOAD_DELAY) as unknown as number
            }}
          >
            <TransformComponent wrapperStyle={{ maxWidth: '100%', maxHeight: '100vh' }}>
              <div
                style={{
                  position: 'relative',
                  width: `${motifMap.mapSize[0]}px`,
                  height: `${motifMap.mapSize[1]}px`,
                }}
              >
                {Object.values(loadedMotifs).map(circle => (
                  <div
                    id={`motif-${circle.id}`}
                    key={circle.id}
                    style={{
                      position: 'absolute',
                      left: `${circle.x - circle.r}px`,
                      top: `${circle.y - circle.r}px`,
                    }}
                    className={
                      'image-container ' +
                      ((filteredMotifsIds.size > 0 &&
                        !filteredMotifsIds.has(circle.id) &&
                        'filter-out') ||
                        '')
                    }
                    onClick={ev =>
                      velocityRef.current === undefined && handleClick(ev.currentTarget, circle.id)
                    }
                  >
                    <img
                      src={idsForUsingLargeImages.has(circle.id) ? circle.url : circle.urlXs}
                      width={circle.r * 2}
                      height={circle.r * 2}
                      alt={`${circle.id}`}
                    />
                  </div>
                ))}
              </div>
            </TransformComponent>
          </TransformWrapper>
        )}
      </MapContainer>
    </>
  )
}

export default MotifMap

import { useEffect } from 'react'
import './utils/i18n'
import { ThemeProvider } from 'styled-components'
import { theme } from './styles/styled-components'
import Router from './Router'
import SelectedMotifsContext from './contexts/selectedMotifContext'
import MotifDetailsContext from './contexts/motifDetailsContext'
import UIContext from './contexts/UIContext'
import { useEnv } from './utils/useEnv'
import MotifGeneratorContext from './contexts/motifGeneratorContext'
import { useTranslation } from 'react-i18next'
// import { useIdleTimer } from './utils/idleTimer'
// import routePaths from './constants/routePaths'

function App() {
  const { isLocalDev, isKiosk } = useEnv()
  const { i18n } = useTranslation()
  const userLang = navigator.language

  // TODO: Uncomment this when screensaver animation is unused
  // useIdleTimer({
  //   callback: () => window.location.replace(routePaths.HOME),
  // })

  if (!isLocalDev) {
    window.addEventListener(
      'contextmenu',
      function (event) {
        event.preventDefault()
      },
      false,
    )
  }

  useEffect(() => {
    if (isKiosk) {
      document.querySelector('body')?.classList.add('noselect')
    }

    i18n.changeLanguage(userLang.split('-')[0])
  }, [])

  return (
    // Add global contexts here
    <ThemeProvider theme={theme}>
      <UIContext>
        <MotifDetailsContext>
          <SelectedMotifsContext>
            <MotifGeneratorContext>
              <Router />
            </MotifGeneratorContext>
          </SelectedMotifsContext>
        </MotifDetailsContext>
      </UIContext>
    </ThemeProvider>
  )
}

export default App

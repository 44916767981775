import { useEffect } from 'react'
import MotifMap from '../../components/MotifMap'
import MotifDetails from '../../components/MotifDetails'
import { useContextMotifDetails } from '../../contexts/motifDetailsContext'
import FloatingXButton from '../../components/FloatingXButton'
import { UIElement } from '../../types/UIElements'
import { useContextUI } from '../../contexts/UIContext'
import FirstVisitNotification from '../../components/FirstVisitNotification'

const Home = () => {
  const { areDetailsOpen, setDetailsOpen, applyGeoFilter, applyTagFilter, tagFilter, geoFilter } =
    useContextMotifDetails()
  const { isAnimationShowing, elementsOpen, setElementsOpen } = useContextUI()

  useEffect(() => {
    if (elementsOpen.includes(UIElement.QRMODAL)) {
      setElementsOpen([UIElement.CONTROLS])
    }

    return () => {
      setDetailsOpen(false)
      setElementsOpen([UIElement.CONTROLS])
    }
  }, [])

  const clearFilters = () => {
    setElementsOpen([UIElement.CONTROLS])
    applyGeoFilter(undefined)
    applyTagFilter(undefined)
  }

  return (
    <>
      {isAnimationShowing ? (
        <FirstVisitNotification isAnimationShowing={isAnimationShowing} />
      ) : null}
      <MotifMap />
      {areDetailsOpen ? <MotifDetails /> : null}
      {(tagFilter || geoFilter) && !areDetailsOpen ? (
        <FloatingXButton onClick={clearFilters} />
      ) : null}
    </>
  )
}

export default Home

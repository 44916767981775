import { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 0 10vw;
  text-align: center;

  > * {
    transition: opacity 0.3s;
    opacity: 0;
  }

  &.loaded {
    > * {
      opacity: 1;
    }

    .image-container {
      background-repeat: no-repeat;
      animation: rotate 1s forwards;
      animation-delay: 1s;
    }
  }

  p {
    margin-bottom: 3em;
  }

  .image-container {
    background-image: url('icons/icon-rotation-arrow.png');
    background-position: center center;

    img {
      width: 100%;
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(90deg);
    }
  }
`

const RotateDeviceNotification = () => {
  const { t } = useTranslation()
  const [imageLoaded, setImageLoaded] = useState<boolean>(false)

  const onLoadImage = () => {
    setImageLoaded(true)
  }

  return (
    <Container className={`${imageLoaded ? 'loaded' : ''}`}>
      <p>{t('screenOrientationErrorMessage')}</p>
      <div className="image-container">
        <img src="images/device.png" alt="" onLoad={onLoadImage} />
      </div>
      <p>{t('screenOrientationErrorInstruction')}</p>
    </Container>
  )
}

export default RotateDeviceNotification

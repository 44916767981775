import React, { useEffect, useRef, useState } from 'react'
import { Container, Grid, Modal } from '@mui/material'
import { useAxiosApi } from '../../utils/axios'
import { MotifUsage, Stats } from './types'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  overflow-y: auto;
`

const renderUsage = (usage?: MotifUsage) => {
  if (!usage) return

  const sortedUsage = Object.keys(usage.usage)
    .map(k => {
      return { id: k, usage: usage.usage[Number(k)] }
    })
    .sort((a, b) => b.usage - a.usage)

  return (
    <>
      <h1>Total motif exports: {usage.total}</h1>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <h4>Motif ID</h4>
        </Grid>
        <Grid item xs={6}>
          <h4>Number of uses</h4>
        </Grid>

        {sortedUsage.map(u => (
          <>
            <Grid item xs={6}>
              {u.id}
            </Grid>
            <Grid item xs={6}>
              {u.usage}
            </Grid>
          </>
        ))}
      </Grid>
    </>
  )
}

export const Statistics = () => {
  const { get } = useAxiosApi()

  const [stats, setStats] = useState<Stats>()
  const isDownloading = useRef(false)

  const [date, setDate] = useState<dayjs.Dayjs | null>(null)

  const getStats = async () => {
    if (stats !== undefined || isDownloading.current) return

    const res = await get<Stats>('/statistics')
    setStats(res.data)
  }

  useEffect(() => void getStats())

  return (
    <Modal open={true}>
      <Wrapper>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {/* Global stats */}
              {stats && renderUsage(stats.global)}
            </Grid>

            <Grid item xs={6}>
              {/* Date-specific stats */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker value={date} onChange={value => setDate(value)} />

                {stats && date && renderUsage(stats.date[Number(date.startOf('day').toDate())])}
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Container>
      </Wrapper>
    </Modal>
  )
}

export default Statistics

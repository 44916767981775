import { useEffect, useState } from 'react'
import SVG from 'react-inlinesvg'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import routePaths from '../../constants/routePaths'
import lottie from 'lottie-web'
import { useContextUI } from '../../contexts/UIContext'

const NotificationWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.68);
`

const LottieContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;

  &.fade-out {
    animation: simple-fade-out 0.8s ease forwards;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    height: 300px;
    width: 525px;
  }
`

const TitleWrapper = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 10;
  color: #e60050;
  font-size: 14px;
  font-family: ${({ theme }) => theme.font.bold};
  letter-spacing: 0.6px;
  line-height: 32px;
  text-align: center;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  opacity: 0;
  animation: simple-fade-in 3.5s ease forwards 0.5s;

  @keyframes scale-in {
    0% {
      transform: translate(-50%, -50%) scale(0);
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  img {
    margin-left: 45px;
  }

  @media (max-height: ${({ theme }) => theme.breakpoints.deviceH.sm}) {
    font-size: 18px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.md}) {
    font-size: 16px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.lg}) {
    font-size: 22px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xl}) {
    font-size: 24px;
  }
`

const WhiteXButton = styled.button`
  position: fixed;
  top: 120px;
  right: 50px;
  background: none;
  border: none;
  padding: 10px;
  width: 50px;
  height: 50px;
  color: white;

  @media (max-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    top: 25px;
    right: 25px;
  }
`

type Props = {
  isAnimationShowing: boolean
}

const FirstVisitNotification = ({ isAnimationShowing }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setIsAnimationShowing } = useContextUI()
  const [fadeOut, setFadeOut] = useState<boolean>(false)

  const onClose = () => {
    setFadeOut(true)
    setTimeout(() => {
      setFadeOut(false)
      setIsAnimationShowing(false)
    }, 800)
  }

  const loadLottie = () => {
    const element = document.getElementById('lottie-tutorial')
    if (!element) throw new Error('Lottie container not found')
    lottie.loadAnimation({
      container: element,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      path: 'animation/motivum_tutorial.json',
    })
  }

  useEffect(() => {
    if (isAnimationShowing) loadLottie()
  }, [isAnimationShowing])

  return (
    <NotificationWrapper>
      <LottieContainer
        id="lottie-tutorial"
        className={`${fadeOut ? 'fade-out' : ''}`}
        onClick={() => {
          setIsAnimationShowing(false)
          navigate(routePaths.GENERATE)
        }}
      >
        <TitleWrapper>
          <div>
            <span>{t('firstVisitNotification')}</span>
            <img src="/icons/red-arrow.png" alt="red-arrow-icon" />
          </div>
        </TitleWrapper>
      </LottieContainer>
      <WhiteXButton onClick={onClose}>
        <SVG src="/icons/x.svg" />
      </WhiteXButton>
    </NotificationWrapper>
  )
}

export default FirstVisitNotification

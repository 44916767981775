import { Circle, generateMapProps } from './generate'
import { useEffect, useRef, useState } from 'react'
import { useContextSelectedMotifs } from '../../../contexts/selectedMotifContext'
import styled from 'styled-components'
import { EXPORT_SIZE, useContextMotifGenerator } from '../../../contexts/motifGeneratorContext'
import { PositionXY } from '../../../types/PositionXY'
import { useThrottleFn } from '@react-cmpt/use-throttle'
import { isAndroid, isIOS } from 'react-device-detect'
import { JSONExportPlane, Dimensions, MotifGeneratorType } from '../types'
import { getPagePositionXY } from '../../../utils/getPagePositionXY'
import { ImagePromise } from '../../MotifMap/util'

const RESOLUTION_MULTIPLIER = 2
const MIN_SCALE = 0.5
const MAX_SCALE = 1.2

const Container = styled.canvas`
  background-color: ${({ theme }) => theme.colors.white};
  min-width: ${EXPORT_SIZE}px;
  min-height: ${EXPORT_SIZE}px;
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 0;

  & + canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
`

export const MapTypeGenerator = () => {
  const { selectedMotifs } = useContextSelectedMotifs()
  const {
    exportInvoked,
    setExportInvoked,
    exportGeneratorCoordinatesToJSON,
    handleExport,
    handleExportCanvas,
    isRegenerated,
  } = useContextMotifGenerator()
  const containerRef = useRef<HTMLCanvasElement | null>(null)
  const canvasToExportRef = useRef<HTMLCanvasElement>(null)
  const [motifImages, setMotifImages] = useState<HTMLImageElement[]>([])
  const dragging = useRef<boolean>(false)
  const lastPos = useRef<PositionXY>({ x: 0, y: 0 })
  const [circles, setCircles] = useState<Circle[]>()
  const [scale, setScale] = useState(1)
  const canvasSize = useRef<Dimensions>({
    width: (window.innerWidth < 1000 ? 1000 : window.innerWidth) * RESOLUTION_MULTIPLIER,
    height: (window.innerHeight < 1000 ? 1000 : window.innerHeight) * RESOLUTION_MULTIPLIER,
  })
  const jsonCoordinates = useRef<JSONExportPlane>({
    clipPlane: undefined,
    entries: [],
  })

  const sortImages = (imagesToSort: HTMLImageElement[], sortByArray: string[]) => {
    return [...imagesToSort].sort((a, b) => sortByArray.indexOf(a.src) - sortByArray.indexOf(b.src))
  }

  const loadImages = async () => {
    // check that all required images are present
    if (
      selectedMotifs.find(
        m => motifImages.find(img => m.vectorizedUrl === img.src) === undefined,
      ) !== undefined
    ) {
      const images = await Promise.all(selectedMotifs.map(m => ImagePromise(m.vectorizedUrl!)))
      setMotifImages(images)
    } else {
      setMotifImages(
        sortImages(
          motifImages,
          selectedMotifs.map(m => m.vectorizedUrl!),
        ),
      )
    }
  }

  useEffect(() => void loadImages(), [selectedMotifs])

  useEffect(() => {
    if (!exportInvoked || !containerRef.current || !canvasToExportRef.current) return

    const exportCtx = canvasToExportRef.current!.getContext('2d')
    const sourceCtx = containerRef.current!.getContext('2d')
    if (!exportCtx || !containerRef.current || !sourceCtx) return

    exportCtx.fillStyle = '#ffffff'
    exportCtx.fillRect(0, 0, canvasToExportRef.current.width, canvasToExportRef.current.height)
    const sourceCanvasTopLeftPos = {
      x: window.innerWidth < EXPORT_SIZE ? 0 : window.innerWidth - EXPORT_SIZE,
      y: window.innerHeight < EXPORT_SIZE ? 0 : window.innerHeight - EXPORT_SIZE,
    }

    exportCtx.drawImage(
      containerRef.current,
      sourceCanvasTopLeftPos.x,
      sourceCanvasTopLeftPos.y,
      EXPORT_SIZE * RESOLUTION_MULTIPLIER,
      EXPORT_SIZE * RESOLUTION_MULTIPLIER,
      0,
      0,
      EXPORT_SIZE,
      EXPORT_SIZE,
    )

    exportGeneratorCoordinatesToJSON({
      props: {
        type: MotifGeneratorType.MAP,
        selectedMotifs,
        circles: circles ?? [],
        canvasSize: canvasSize.current,
      },
      planes: [jsonCoordinates.current],
      canvasSize: {
        width: containerRef.current.width,
        height: containerRef.current.height,
      },
    })

    if (isIOS || isAndroid) {
      handleExportCanvas(canvasToExportRef.current)
      setExportInvoked(false)
    } else {
      const exportCanvas = async () => {
        await handleExport(canvasToExportRef.current!)
        setExportInvoked(false)
      }

      exportCanvas().catch(console.error)
    }
  }, [exportInvoked])

  useEffect(() => {
    if (motifImages.length === selectedMotifs.length && containerRef?.current) {
      const circles = generateMapProps(selectedMotifs, EXPORT_SIZE)
      jsonCoordinates.current.entries = circles.map(c =>
        Object.assign(c, { size: 2 * c.r, id: selectedMotifs[c.idx].id }),
      )
      setCircles(circles)
    }
  }, [containerRef, isRegenerated, selectedMotifs, motifImages])

  useEffect(() => circles && draw(circles), [circles, scale, motifImages])

  const draw = (circles: Circle[]) => {
    if (!containerRef.current) return

    const ctx = containerRef.current.getContext('2d')
    if (!ctx || !containerRef.current) return

    ctx.fillStyle = '#ffffff'
    ctx.fillRect(0, 0, containerRef.current.width, containerRef.current.height)

    circles.forEach(c => {
      const img = motifImages[c.idx]

      ctx.save()
      ctx.translate(c.x * RESOLUTION_MULTIPLIER, c.y * RESOLUTION_MULTIPLIER)
      ctx.rotate(c.rotation)

      const imageSize = 2 * RESOLUTION_MULTIPLIER * c.r * scale
      ctx.drawImage(img, -imageSize / 2, -imageSize / 2, imageSize, imageSize)

      ctx.restore()
    })
  }

  const handleMouseDown = (event: React.MouseEvent) => {
    event.preventDefault()
    onStartEvent(event.pageX, event.pageY)
  }

  const handleTouchStart = (event: React.TouchEvent) => {
    onStartEvent(event.touches[0].pageX, event.touches[0].pageY)
  }

  const onStartEvent = (x: number, y: number) => {
    lastPos.current.x = x
    lastPos.current.y = y
    dragging.current = true
  }

  const handleMouseUp = (event: React.MouseEvent | React.TouchEvent) => {
    event.preventDefault()
    dragging.current = false
    if (!containerRef.current) return
  }

  const handleMouseMove = (event: React.MouseEvent | React.TouchEvent): void => {
    if (dragging.current && circles) {
      const pageXY = getPagePositionXY(event)
      const moveX = lastPos.current.x - pageXY.x
      const moveY = lastPos.current.y - pageXY.y

      lastPos.current = pageXY

      setCircles(
        circles.map(c =>
          Object.assign(c, {
            rotation: c.rotation - (moveX / window.innerHeight) * 2,
          }),
        ),
      )

      let newScale = scale + (moveY / window.innerHeight) * 2
      newScale = Math.max(newScale, MIN_SCALE)
      newScale = Math.min(newScale, MAX_SCALE)

      setScale(newScale)
    }
  }

  const { callback } = useThrottleFn(handleMouseMove, 40)

  return (
    <>
      <Container
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseMove={callback}
        onMouseUp={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchMove={callback}
        onTouchEnd={handleMouseUp}
        width={canvasSize.current.width < EXPORT_SIZE ? EXPORT_SIZE : canvasSize.current.width}
        height={canvasSize.current.width < EXPORT_SIZE ? EXPORT_SIZE : canvasSize.current.height}
      />
      <canvas ref={canvasToExportRef} width={EXPORT_SIZE} height={EXPORT_SIZE} />
    </>
  )
}

export default MapTypeGenerator

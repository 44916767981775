export enum Tag {
  ANIMAL = 'ANIMAL', // állatábrázolás
  HUMAN = 'HUMAN', // emberábrázolás
  GEOMETRIC = 'GEOMETRIC', // geometrikus minta
  BIRD = 'BIRD', // madár
  SHAPE = 'SHAPE', // alakzat
  CREST = 'CREST', // címer
  TEXT = 'TEXT', // felirat
  FLORAL = 'FLORAL', // növényi díszítés
}

// You can see all the registered tags if you visit the Strapi admin site or send a GET request to
// the https://diszitmenyek-db.neprajz.hu/tags?_start=0&_limit=1000 url.
export const tags: Record<Tag, number[]> = {
  [Tag.BIRD]: [36, 110],
  [Tag.ANIMAL]: [5],
  [Tag.CREST]: [3],
  [Tag.TEXT]: [75, 76],
  [Tag.HUMAN]: [611],
  [Tag.FLORAL]: [143, 561],
  [Tag.GEOMETRIC]: [87, 139],
  [Tag.SHAPE]: [839],
}

export const tagIcons: Record<Tag, string> = {
  [Tag.HUMAN]: '/icons/filter-buttons/human.svg',
  [Tag.GEOMETRIC]: '/icons/filter-buttons/geometric.svg',
  [Tag.TEXT]: '/icons/filter-buttons/text.svg',
  [Tag.CREST]: '/icons/filter-buttons/crest.svg',
  [Tag.FLORAL]: '/icons/filter-buttons/floral.svg',
  [Tag.BIRD]: '/icons/filter-buttons/bird.svg',
  [Tag.ANIMAL]: '/icons/filter-buttons/animal.svg',
  [Tag.SHAPE]: '/icons/filter-buttons/shape.svg',
}

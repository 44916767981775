import React from 'react'
import ReactDOM from 'react-dom'
import './styles/globals.css'
import './styles/animations.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as gtag from 'ga-gtag'

if (process.env.GOOGLE_TRACKING_ID) {
  gtag.install(process.env.GOOGLE_TRACKING_ID)
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import { GeneratorControlButton } from './common'
import SVG from 'react-inlinesvg'
import styled from 'styled-components'
import { MotifGeneratorType } from '../MotifPatternGenerator/types'
import { useContextMotifGenerator } from '../../contexts/motifGeneratorContext'
import { useContextScreensaver } from '../../contexts/screensaverContext'
import { useContextUI } from '../../contexts/UIContext'
import { FeatureDiscoveryElement } from '../../types/FeatureDiscoveryElement'
import { isMobile } from 'react-device-detect'

const PatternButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: space-between;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 72%;
    height: 72%;
    max-width: 40px;
    max-height: 40px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.md}) {
    gap: 18px;
  }
`

const PatternButtons = () => {
  const { setIsScreensaverOn } = useContextScreensaver()
  const {
    setFeatureDiscoveryElementsOpen,
    featureDiscoveryElementsAlreadyShown,
    setFeatureDiscoveryElementsAlreadyShown,
  } = useContextUI()
  const { selectedGeneratorType, selectGeneratorType, isRegenerated, setIsRegenerated } =
    useContextMotifGenerator()

  // Replace these with the commented out lines below if you
  // want to have freehand generator in place
  const firstRow = [
    { src: 'round_pattern.svg', type: MotifGeneratorType.CIRCLE },
    { src: 'square_pattern.svg', type: MotifGeneratorType.SQUARE },
    { src: 'penrose.svg', type: MotifGeneratorType.PENROSE },
    { src: 'random_pattern.svg', type: MotifGeneratorType.MAP },
  ]

  const secondRow = [
    { src: 'square.svg', type: MotifGeneratorType.KALEIDO_SQUARE },
    { src: 'pentagon.svg', type: MotifGeneratorType.KALEIDO_PENTA },
    { src: 'hexagon.svg', type: MotifGeneratorType.KALEIDO_HEXA },
    {
      src: isMobile ? 'octagon.svg' : 'free.svg',
      type: isMobile ? MotifGeneratorType.KALEIDO_OCTA : MotifGeneratorType.FREE,
    },
    // { src: 'octagon.svg', type: MotifGeneratorType.KALEIDO_OCTA },
  ]

  const handleClick = (type: MotifGeneratorType) => {
    setIsScreensaverOn(false)

    if (
      featureDiscoveryElementsAlreadyShown.includes(FeatureDiscoveryElement.HOME) ||
      featureDiscoveryElementsAlreadyShown.includes(FeatureDiscoveryElement.PATTERNLIST) ||
      featureDiscoveryElementsAlreadyShown.includes(FeatureDiscoveryElement.ACTIONS)
    ) {
      setFeatureDiscoveryElementsOpen([])
    } else if (selectedGeneratorType === MotifGeneratorType.FREE) {
      setFeatureDiscoveryElementsOpen([
        FeatureDiscoveryElement.HOME,
        FeatureDiscoveryElement.PATTERNLIST,
        FeatureDiscoveryElement.ACTIONS,
      ])
      setFeatureDiscoveryElementsAlreadyShown([
        FeatureDiscoveryElement.HOME,
        FeatureDiscoveryElement.PATTERNLIST,
        FeatureDiscoveryElement.ACTIONS,
      ])
    } else {
      setFeatureDiscoveryElementsOpen([])
    }

    selectGeneratorType(type)
    if (selectedGeneratorType === MotifGeneratorType.MAP) {
      setIsRegenerated(!isRegenerated)
    }
  }

  return (
    <>
      <PatternButtonWrapper>
        {firstRow.map((item, index) => (
          <GeneratorControlButton
            key={index}
            onClick={() => handleClick(item.type)}
            $active={selectedGeneratorType === item.type}
          >
            <SVG src={`/icons/patterns/${item.src}`} />
          </GeneratorControlButton>
        ))}
      </PatternButtonWrapper>
      <PatternButtonWrapper>
        {secondRow.map((item, index) => (
          <GeneratorControlButton
            key={index}
            onClick={() => handleClick(item.type)}
            $active={selectedGeneratorType === item.type}
          >
            <SVG src={`/icons/patterns/${item.src}`} />
          </GeneratorControlButton>
        ))}
      </PatternButtonWrapper>
      {
        // TODO: Use the third row here when the freehand generator is done
        // <PatternButtonWrapper>
        //   {thirdRow.map((item, index) => (
        //     <GeneratorControlButton
        //       key={index}
        //       onClick={() => handleClick(item.type)}
        //       $active={selectedGeneratorType === item.type}
        //     >
        //       <SVG src={`/icons/patterns/${item.src}`} />
        //     </GeneratorControlButton>
        //   ))}
        // </PatternButtonWrapper>
      }
    </>
  )
}

export default PatternButtons

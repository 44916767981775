import styled from 'styled-components'

export const GeneratorControlButtonDiv = styled.div`
  --button-size: 40px;
  background-color: ${props => props.theme.colors.white};
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center;
  height: var(--button-size);
  width: var(--button-size);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.borderGrey};
  border-radius: 8px;

  @media (min-height: ${({ theme }) => theme.breakpoints.deviceH.xxs}) {
    border-radius: 16px;
    --button-size: 50px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.md}) {
    --button-size: 60px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xl}) {
    --button-size: 80px;
  }
`

export const GeneratorControlButton = styled.button<{
  $useSpacing?: boolean
  $empty?: boolean
  $active?: boolean
}>`
  --button-size: 40px;
  background-color: ${props => (props.$empty ? 'transparent' : props.theme.colors.white)};
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center;
  height: var(--button-size);
  width: var(--button-size);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid
    ${({ theme, $active, $empty }) =>
      $empty ? theme.colors.borderLightGrey : $active ? theme.colors.red : theme.colors.borderGrey};
  border-radius: 8px;
  padding: 0;

  @media (min-height: ${({ theme }) => theme.breakpoints.deviceH.xxs}) {
    border-radius: 16px;
    --button-size: 50px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.md}) {
    --button-size: 60px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xl}) {
    --button-size: 80px;
  }
`

export const GeneratorControlWrapper = styled.div`
  position: relative;
  border-bottom-left-radius: 17px;
  border-top-left-radius: 17px;
  background-color: ${({ theme }) => theme.colors.bgLightGrey};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  padding: 10px 12px;
  display: flex;
  justify-content: space-between;
  height: 100%;
  z-index: 10;

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    padding: 15px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.md}) {
    padding: 18px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xl}) {
    padding: 21px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xxl}) {
    border-bottom-right-radius: 17px;
    border-top-right-radius: 17px;
    right: 3vw;
  }
`

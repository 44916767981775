import { useEffect, useRef, useState } from 'react'
import { useThrottleFn } from '@react-cmpt/use-throttle'
import { JSONExportPlane, JSONExportEntry, MotifGeneratorType } from '../types'
import { KaleidoscopeContainer } from './styled'
import { PositionXY } from '../../../types/PositionXY'
import { getPagePositionXY } from '../../../utils/getPagePositionXY'
import { useContextSelectedMotifs } from '../../../contexts/selectedMotifContext'
import { EXPORT_SIZE, useContextMotifGenerator } from '../../../contexts/motifGeneratorContext'
import { useContextUI } from '../../../contexts/UIContext'
import { useEnv } from '../../../utils/useEnv'
import { InfoSnackbarType } from '../../../constants/animationStates'
import { useContextScreensaver } from '../../../contexts/screensaverContext'
import { isAndroid, isIOS, isMobile } from 'react-device-detect'
import { getTransformProperties } from '../util'

type Props = {
  type:
    | MotifGeneratorType.KALEIDO_HEXA
    | MotifGeneratorType.KALEIDO_OCTA
    | MotifGeneratorType.KALEIDO_PENTA
    | MotifGeneratorType.KALEIDO_SQUARE
  position?: PositionXY
  width?: number
}

const TILE_NUMBER = 9
const RESOLUTION_MULTIPLIER = window.innerWidth < 1920 ? 2 : 1

const KaleidoscopeGeneratorCanvas = ({ type, position, width }: Props) => {
  const rad = Math.PI / 180
  const { isKiosk, isWeb } = useEnv()
  const { isScreensaverOn, setIsScreensaverOn } = useContextScreensaver()
  const animationPositionOffset = useRef<PositionXY>()
  const animationStartTimestamp = useRef<number>()
  const animationFrameRef = useRef<number>(0)
  const animationUnderway = useRef<boolean>(false)
  const dragging = useRef<boolean>(false)
  const canvasRefs = useRef<HTMLCanvasElement[]>([])
  const canvasToExportRef = useRef<HTMLCanvasElement>(null)
  const intervalRef = useRef<NodeJS.Timeout | null>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const lastPos = useRef<PositionXY>({ x: 0, y: 0 })
  const { selectedMotifs } = useContextSelectedMotifs()
  const [canvasDataUrl, setCanvasDataUrl] = useState<string | null>(null)
  const [motifImages, setMotifImages] = useState<HTMLImageElement[]>([])
  const [patternImageIndexes, setPatternImageIndexes] = useState<number[]>([])
  const {
    exportInvoked,
    setExportInvoked,
    exportGeneratorCoordinatesToJSON,
    handleExport,
    handleExportCanvas,
  } = useContextMotifGenerator()
  const [imageLoadingIsFinished, setImageLoadingIsFinished] = useState<boolean>(false)
  const [imagePositionOffset, setImagePositionOffset] = useState<PositionXY>(
    position || { x: 0, y: 0 },
  )
  const { showSnackbar, hideSnackbar, disabledSnackbars, setDisabledSnackbars } = useContextUI()
  const jsonCoordinates = useRef<JSONExportPlane[]>([])
  const numberOfSlices = (() => {
    switch (type) {
      case MotifGeneratorType.KALEIDO_SQUARE:
        return 4
      case MotifGeneratorType.KALEIDO_PENTA:
        return 5
      case MotifGeneratorType.KALEIDO_HEXA:
        return 6
      case MotifGeneratorType.KALEIDO_OCTA:
        return 8
      default:
        return 2
    }
  })()
  const sliceAngle = (360 * rad) / numberOfSlices

  useEffect(() => {
    if (!canvasDataUrl || !canvasToExportRef.current) return

    exportGeneratorCoordinatesToJSON({
      props: {
        type,
        selectedMotifs,
        position: imagePositionOffset,
        width: EXPORT_SIZE,
      },
      planes: jsonCoordinates.current,
      canvasSize: {
        width: canvasRefs.current[0].width,
        height: canvasRefs.current[0].height,
      },
    })

    if (isIOS || isAndroid) {
      handleExportCanvas(canvasToExportRef.current)
    } else {
      const exportCanvas = async () => {
        await handleExport(canvasToExportRef.current!)
        setExportInvoked(false)
        setCanvasDataUrl(null)
      }

      exportCanvas().catch(console.error)
    }
  }, [canvasDataUrl])

  const loadImages = (index: number) => {
    const img = new Image()
    img.crossOrigin = ''
    img.src = selectedMotifs[index].vectorizedUrl!
    img.onload = () => {
      setMotifImages([...motifImages, img])
    }
  }

  // Prevents pull-to refresh behavior on iOS devices
  useEffect(() => {
    if (!containerRef.current) return
    let prevent = false

    containerRef.current.addEventListener('touchstart', function (e) {
      if (e.touches.length !== 1) return

      const scrollY =
        window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop
      prevent = scrollY === 0
    })

    containerRef.current.addEventListener('touchmove', function (e) {
      if (prevent) {
        prevent = false
        e.preventDefault()
      }
    })
  }, [containerRef.current])

  useEffect(() => {
    hideSnackbar()
    if (!disabledSnackbars.includes(InfoSnackbarType.KALEIDOSCOPE)) {
      if (isKiosk) showSnackbar('snackbarMessages.useKaleidoscopeKiosk')
      if (isWeb) showSnackbar('snackbarMessages.useKaleidoscopeWeb')
      if (isMobile) showSnackbar('snackbarMessages.useKaleidoscopeMobile')
    }
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current)
      hideSnackbar()
      if (disabledSnackbars.includes(InfoSnackbarType.KALEIDOSCOPE)) {
        return
      } else {
        setDisabledSnackbars([...disabledSnackbars, InfoSnackbarType.KALEIDOSCOPE])
      }
    }
  }, [])

  useEffect(() => {
    if (motifImages.length === selectedMotifs.length) {
      const isOrdered = motifImages.every(
        (img, idx) => img.src === selectedMotifs[idx].vectorizedUrl,
      )
      if (!isOrdered) {
        const newMotifImages = motifImages.map((_, idx) => {
          const src = selectedMotifs[idx].vectorizedUrl
          return motifImages.find(img => img.src === src)
        })

        if (!newMotifImages.every(img => img)) {
          setMotifImages([])
          setImageLoadingIsFinished(false)
        } else {
          // SAFETY: if-else rules out undefined values
          setMotifImages(newMotifImages as HTMLImageElement[])
        }
        return
      }
      setImageLoadingIsFinished(true)

      switch (motifImages.length) {
        case 1:
          setPatternImageIndexes([0, 0, 0, 0])
          break
        case 2:
          setPatternImageIndexes([0, 1, 1, 0])
          break
        case 3:
          setPatternImageIndexes([0, 1, 2, 0])
          break
        case 4:
          setPatternImageIndexes([0, 1, 2, 3])
      }
    }
    if (motifImages.length < selectedMotifs.length && !imageLoadingIsFinished) {
      loadImages(motifImages.length)
    }
    if (motifImages.length > selectedMotifs.length) {
      setMotifImages(
        motifImages.filter(img =>
          selectedMotifs.map(motif => motif.vectorizedUrl).includes(img.src),
        ),
      )
    }
  }, [selectedMotifs, motifImages, imageLoadingIsFinished])

  useEffect(() => {
    if (exportInvoked && canvasToExportRef.current) {
      draw(
        canvasToExportRef.current,
        EXPORT_SIZE / RESOLUTION_MULTIPLIER,
        EXPORT_SIZE / RESOLUTION_MULTIPLIER,
        imagePositionOffset,
      )
      setCanvasDataUrl(canvasToExportRef.current.toDataURL())
    }

    if (canvasRefs.current.length !== TILE_NUMBER || patternImageIndexes.length !== 4) {
      return
    }

    render(imagePositionOffset)

    if (intervalRef.current || canvasRefs.current.length !== TILE_NUMBER) {
      return
    }

    if (isScreensaverOn) {
      animationUnderway.current = true
      animationPositionOffset.current = imagePositionOffset
      animationFrameRef.current = requestAnimationFrame(animation)
    }
  }, [
    patternImageIndexes,
    canvasRefs,
    imagePositionOffset,
    type,
    canvasToExportRef,
    exportInvoked,
    isScreensaverOn,
  ])

  useEffect(() => {
    if (!isScreensaverOn) {
      animationUnderway.current = false
    }
  }, [isScreensaverOn])

  const calculateTranslationFromTimeElapsed = (elapsed: number): PositionXY => {
    return {
      x: Math.sin((Math.PI / 12000) * elapsed) * 400,
      y: Math.cos((Math.PI / 24000) * elapsed) * 600,
    }
  }

  // Biennale Screensaver logic
  const animation = (timestamp: DOMHighResTimeStamp) => {
    if (animationStartTimestamp.current === undefined) {
      animationStartTimestamp.current = timestamp
    }

    animationPositionOffset.current = calculateTranslationFromTimeElapsed(
      timestamp - animationStartTimestamp.current,
    )

    if (animationUnderway.current) {
      animationFrameRef.current = requestAnimationFrame(animation)
    } else {
      setImagePositionOffset(animationPositionOffset.current)
      return
    }

    render(animationPositionOffset.current)
  }

  const render = (translation: PositionXY) => {
    if (canvasRefs.current[0]) {
      const clientRect = canvasRefs.current[0].getBoundingClientRect()
      draw(canvasRefs.current[0], clientRect.width, clientRect.height, translation)
      canvasRefs.current.slice(1).forEach(canvas => {
        canvas.width = clientRect.width * RESOLUTION_MULTIPLIER
        canvas.height = clientRect.height * RESOLUTION_MULTIPLIER
        const ctx = canvas.getContext('2d')
        if (ctx !== null) {
          ctx.drawImage(canvasRefs.current[0], 0, 0)
        }
      })
    }
  }

  const draw = (canvas: HTMLCanvasElement, width: number, height: number, position: PositionXY) => {
    if (!canvasRefs.current[0]) return
    const canvasRefBoundingRect = canvasRefs.current[0].getBoundingClientRect()
    canvas.width = width * RESOLUTION_MULTIPLIER
    canvas.height = height * RESOLUTION_MULTIPLIER
    const ctx = canvas.getContext('2d')

    if (!ctx) return

    const centerX = canvas.width / 2
    const centerY = canvas.height / 2
    const imageSize = canvas.width / 3

    ctx.fillStyle = '#fff'
    ctx.fillRect(0, 0, 1000, 1000)

    const proportional = width / canvasRefBoundingRect.width
    const offset = {
      x: position.x * proportional,
      y: position.y * proportional,
    }

    let basePosX = offset.x
    let basePosY = offset.y
    let posXWithOffset = basePosX + imageSize
    let posYWithOffset = basePosY + imageSize

    let patternOffsetMultiplierX = Math.abs(Math.floor((offset.x + imageSize) / (imageSize * 2)))
    let patternOffsetMultiplierY = Math.abs(Math.floor((offset.y + imageSize) / (imageSize * 2)))

    if (offset.x > 0) {
      patternOffsetMultiplierX = -patternOffsetMultiplierX
    }

    if (offset.y > 0) {
      patternOffsetMultiplierY = -patternOffsetMultiplierY
    }

    basePosX += imageSize * 2 * patternOffsetMultiplierX
    basePosY += imageSize * 2 * patternOffsetMultiplierY
    posXWithOffset += imageSize * 2 * patternOffsetMultiplierX
    posYWithOffset += imageSize * 2 * patternOffsetMultiplierY

    const positions = {
      x: [basePosX, posXWithOffset, basePosX, posXWithOffset],
      y: [basePosY, basePosY, posYWithOffset, posYWithOffset],
    }

    jsonCoordinates.current = []

    for (let i = 0; i < numberOfSlices; i++) {
      for (let j = 0; j <= 1; j++) {
        const clipRotationAngle = sliceAngle * i + (sliceAngle / 2) * (j + 1)

        ctx.save()
        ctx.translate(centerX, centerY)
        ctx.rotate(clipRotationAngle)
        const plane = getClipPath(ctx, sliceAngle / 2, canvas.width)
        ctx.clip()
        ctx.rotate(sliceAngle / 4)
        if (j === 1) {
          ctx.scale(-1, 1)
        }
        ctx.translate(-centerX, -centerY)
        const { scale, rotation } = getTransformProperties(ctx)

        const multipliedImageOffsetPosXY = [
          { x: 2 * imageSize, y: -2 * imageSize },
          { x: 0, y: -2 * imageSize },
          { x: -2 * imageSize, y: -2 * imageSize },
          { x: -2 * imageSize, y: 0 },
          { x: 0, y: 0 },
          { x: 2 * imageSize, y: 0 },
          { x: 2 * imageSize, y: 2 * imageSize },
          { x: 0, y: 2 * imageSize },
          { x: -2 * imageSize, y: 2 * imageSize },
        ]
        const entries: JSONExportEntry[] = []
        for (let multiplierIndex = 0; multiplierIndex < 9; multiplierIndex++) {
          const currentEntries = patternImageIndexes.map((imageIndex, index) => {
            const x = positions.x[index] + multipliedImageOffsetPosXY[multiplierIndex].x
            const y = positions.y[index] + multipliedImageOffsetPosXY[multiplierIndex].y

            ctx.drawImage(motifImages[imageIndex], x, y, imageSize, imageSize)

            const p = ctx
              .getTransform()
              .transformPoint(new DOMPoint(x + imageSize / 2, y + imageSize / 2))
            return {
              id: selectedMotifs[imageIndex].id,
              x: p.x,
              y: p.y,
              rotation,
              size: imageSize * scale,
              flipX: j === 1,
            }
          })

          entries.push(...currentEntries)
        }

        ctx.restore()

        if (plane === undefined) {
          jsonCoordinates.current.push({
            clipPlane: plane,
            entries,
          })
        } else {
          jsonCoordinates.current.push({
            clipPlane: { kind: 'polygon', points: plane },
            entries,
          })
        }
      }
    }
  }

  const getClipPath = (ctx: CanvasRenderingContext2D, angle: number, width: number) => {
    if (!canvasRefs.current[0]) return
    const cx = 0
    const cy = 0

    ctx.beginPath()
    ctx.moveTo(cx, cy)

    const triangleCoordinates = [
      { x: 0, y: 0 },
      { x: 0, y: -width },
      {
        x: -width * Math.sin(-angle),
        y: -width * Math.cos(-angle),
      },
    ]

    ctx.lineTo(triangleCoordinates[1].x, triangleCoordinates[1].y)
    ctx.lineTo(triangleCoordinates[2].x, triangleCoordinates[2].y)
    ctx.lineTo(0, 0)
    ctx.closePath()

    const t = ctx.getTransform()
    const coords: PositionXY[] = triangleCoordinates
      .map(p => t.transformPoint(new DOMPoint(p.x, p.y)))
      .map(p => Object.assign({ x: p.x, y: p.y }))
    return coords
  }

  const handleMouseDown = (event: React.MouseEvent) => {
    if (isScreensaverOn) {
      setIsScreensaverOn(false)
    } else {
      event.preventDefault()
      onStartEvent(event.pageX, event.pageY)
    }
  }

  const handleTouchStart = (event: React.TouchEvent) => {
    if (isScreensaverOn) {
      setIsScreensaverOn(false)
    } else {
      onStartEvent(event.touches[0].pageX, event.touches[0].pageY)
    }
  }

  const onStartEvent = (x: number, y: number) => {
    lastPos.current.x = x
    lastPos.current.y = y
    dragging.current = true
  }

  const handleMouseUp = (event: React.MouseEvent | React.TouchEvent) => {
    if (isScreensaverOn) {
      setIsScreensaverOn(false)
    } else {
      event.preventDefault()
      dragging.current = false
    }
  }

  const handleMouseMove = (event: React.MouseEvent | React.TouchEvent): void => {
    if (!dragging.current) return
    const pageXY = getPagePositionXY(event)
    const moveX = lastPos.current.x - pageXY.x
    const moveY = lastPos.current.y - pageXY.y

    lastPos.current = pageXY

    let offsetX = imagePositionOffset.x + moveX
    let offsetY = imagePositionOffset.y + moveY

    setImagePositionOffset({ x: offsetX, y: offsetY })
  }

  const { callback } = useThrottleFn(handleMouseMove, 50)

  return (
    <KaleidoscopeContainer
      ref={containerRef}
      style={{
        width: width ? `${width * 3}px` : '140vw',
        height: width ? `${width * 3}px` : '140vw',
      }}
      className={`kaleidoscope-${type.toLowerCase()}`}
      onMouseDown={handleMouseDown}
      onMouseMove={callback}
      onMouseUp={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchMove={callback}
      onTouchEnd={handleMouseUp}
    >
      {[...Array(TILE_NUMBER)].map((_, i) => {
        return (
          <div
            key={i}
            className={'tile' + (i < 3 || i > 5 ? ' tile-flipped' : '')}
            style={{
              width: width ? `${width}px` : 'calc(100% / 3 + 1px)',
              height: width ? `${width}px` : 'calc(100% / 3 + 1px)',
            }}
          >
            <canvas ref={el => (canvasRefs.current[i] = el!)}></canvas>
          </div>
        )
      })}
      <div
        className="tile tile-to-export"
        style={{ width: `${EXPORT_SIZE}px`, height: `${EXPORT_SIZE}px` }}
      >
        <canvas
          width={EXPORT_SIZE / RESOLUTION_MULTIPLIER}
          height={EXPORT_SIZE / RESOLUTION_MULTIPLIER}
          ref={canvasToExportRef}
        ></canvas>
      </div>
    </KaleidoscopeContainer>
  )
}

export default KaleidoscopeGeneratorCanvas

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import SVG from 'react-inlinesvg'
import { useState } from 'react'

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.black70};
  z-index: 50;

  &.fade-in {
    animation: simple-fade-in 0.5s ease forwards;
  }

  &.fade-out {
    animation: simple-fade-out 0.5s ease forwards;
  }

  svg {
    margin: 0 20px;
  }

  .right-arrow-svg {
    transform: rotate(180deg);
  }

  .x-button {
    position: fixed;
    padding: 0;
    top: 6vh;
    right: 35px;
    background: none;
    border: none;
    color: white;
  }

  .white-x-svg {
    width: 30px;
    height: 30px;
    margin: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    .x-button {
      top: 12vh;
      right: 84px;
    }
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.deviceW.lg}) {
    svg {
      margin: 0 54px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    .x-button {
      top: 30px;
      right: 30px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.deviceW.xl}) {
    .x-button {
      right: 120px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xxl}) {
    .x-button {
      right: 5vw;
    }
  }
`

const Dialog = styled.div`
  width: 400px;
  height: 196px;
  border-radius: 12px;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.white};

  h1 {
    margin: 10px 0 0 0;
    font-size: 19px;
    letter-spacing: 0.4px;
    line-height: 30px;
    text-align: center;
    font-family: ${({ theme }) => theme.font.regular};
  }

  .button-container {
    display: flex;
    margin-top: 20px;
  }

  button {
    height: 60px;
    width: 250px;
    padding: 0;
    border: none;
    background: none;
    text-align: center;
  }

  span {
    font-size: 27px;
    font-family: ${({ theme }) => theme.font.bold};
    letter-spacing: 1.62px;
    line-height: 50px;
    font-family: ${({ theme }) => theme.font.semiBold};
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.deviceW.lg}) {
    width: 450px;

    h1 {
      font-size: 20px;
    }

    span {
      line-height: 55px;
      font-size: 36px;
    }
  }

  @media only screen and (min-width: 1600px) {
    width: 500px;

    h1 {
      letter-spacing: 0.5px;
      line-height: 32px;
      font-size: 21px;
    }

    span {
      line-height: 60px;
      font-size: 42px;
    }
  }
`

interface Props {
  message: string
  onConfirm: (confirm: boolean) => void
}

const Index = ({ onConfirm, message }: Props) => {
  const { t } = useTranslation()
  const [fadeOut, setFadeOut] = useState<boolean>(false)

  const closeConfirmation = (confirm: boolean) => {
    setFadeOut(true)
    setTimeout(() => {
      onConfirm(confirm)
    }, 500)
  }

  return (
    <Wrapper className={fadeOut ? 'fade-out' : 'fade-in'} onClick={() => closeConfirmation(false)}>
      <SVG className="left-arrow-svg" src="/icons/arrow.svg" />
      <Dialog>
        <h1>{t(message)}</h1>
        <div className="button-container">
          <button onClick={() => closeConfirmation(true)}>
            <span>{t('yes')}</span>
          </button>
          <button onClick={() => closeConfirmation(false)}>
            <span>{t('no')}</span>
          </button>
        </div>
      </Dialog>
      <SVG className="right-arrow-svg" src="/icons/arrow.svg" />
      <button className="x-button" onClick={() => closeConfirmation(false)}>
        <SVG className="white-x-svg" src="/icons/x.svg" />
      </button>
    </Wrapper>
  )
}

export default Index

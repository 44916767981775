import React, { useState } from 'react'
import PatternButtonListControl from '../../components/GeneratorControls/PatternButtonList'
import ActionButtons from '../../components/GeneratorControls/ActionButtons'
import MotifPatternGenerator from '../../components/MotifPatternGenerator'
import styled from 'styled-components'
import QRGenerator from '../../components/GeneratorControls/QRGenerator'
import { useEffect } from 'react'
import { useContextUI } from '../../contexts/UIContext'
import { useEnv } from '../../utils/useEnv'
import { UIElement } from '../../types/UIElements'
import { isMobile } from 'react-device-detect'
import MobileDeviceNotification from '../../components/MobileDeviceNotification'
import InteractionMotivator from '../../components/InteractionMotivator'
import { useContextInteractionMotivator } from '../../contexts/interactionMotivatorContext'
import { useContextMotifGenerator } from '../../contexts/motifGeneratorContext'
import FeatureDiscovery from '../../components/MotifPatternGenerator/FreeLayoutGenerator/FeatureDiscovery'
import { MotifGeneratorType } from '../../components/MotifPatternGenerator/types'
import { FeatureDiscoveryElement } from '../../types/FeatureDiscoveryElement'

const GeneratorWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const MotifGenerator = () => {
  const {
    isFirstGeneratorVisit,
    setIsFirstGeneratorVisit,
    elementsOpen,
    setElementsOpen,
    setFeatureDiscoveryElementsOpen,
    featureDiscoveryElementsAlreadyShown,
    setFeatureDiscoveryElementsAlreadyShown,
    filterOutUiElement,
    hideSnackbar,
  } = useContextUI()
  const { selectedGeneratorType } = useContextMotifGenerator()
  const { isInteractionMotivatorShownWithHideAnimation } = useContextInteractionMotivator()
  const { isKiosk } = useEnv()
  const [isDialogOpen, setIsDialogOpen] = useState<boolean | undefined>()

  useEffect(() => {
    setElementsOpen([UIElement.CONTROLS])
    if (isFirstGeneratorVisit && isMobile && window.innerHeight < 500) {
      setIsDialogOpen(true)
    } else {
      setIsFirstGeneratorVisit(false)
    }

    if (
      selectedGeneratorType !== MotifGeneratorType.FREE &&
      (isKiosk ||
        !featureDiscoveryElementsAlreadyShown.includes(FeatureDiscoveryElement.HOME) ||
        !featureDiscoveryElementsAlreadyShown.includes(FeatureDiscoveryElement.PATTERNLIST) ||
        !featureDiscoveryElementsAlreadyShown.includes(FeatureDiscoveryElement.ACTIONS))
    ) {
      setFeatureDiscoveryElementsOpen([
        FeatureDiscoveryElement.HOME,
        FeatureDiscoveryElement.PATTERNLIST,
        FeatureDiscoveryElement.ACTIONS,
      ])
      setFeatureDiscoveryElementsAlreadyShown([
        FeatureDiscoveryElement.HOME,
        FeatureDiscoveryElement.PATTERNLIST,
        FeatureDiscoveryElement.ACTIONS,
      ])
    }

    return () => {
      if (elementsOpen.includes(UIElement.ABOUT)) {
        filterOutUiElement(UIElement.ABOUT)
      }

      if (elementsOpen.includes(UIElement.GALLERY)) {
        filterOutUiElement(UIElement.GALLERY)
      }

      // reset generator feature discovery state
      setFeatureDiscoveryElementsOpen([])
      if (isKiosk) {
        setFeatureDiscoveryElementsAlreadyShown([])
      }

      // Hide the snackbar and QR code whenever the generator page component is destroyed
      hideSnackbar()
    }
  }, [])

  useEffect(() => {
    if (
      selectedGeneratorType === MotifGeneratorType.FREE &&
      !featureDiscoveryElementsAlreadyShown.includes(FeatureDiscoveryElement.FREE_LAYOUT)
    ) {
      setFeatureDiscoveryElementsOpen([FeatureDiscoveryElement.FREE_LAYOUT])
      setFeatureDiscoveryElementsAlreadyShown([
        ...featureDiscoveryElementsAlreadyShown,
        FeatureDiscoveryElement.FREE_LAYOUT,
      ])
    }
  }, [selectedGeneratorType])

  useEffect(() => {
    if (isDialogOpen === false) {
      setIsFirstGeneratorVisit(false)
    }
  }, [isDialogOpen])

  return (
    <GeneratorWrapper style={{ touchAction: isKiosk ? 'none' : 'auto' }}>
      {isDialogOpen ? <MobileDeviceNotification setIsDialogOpen={setIsDialogOpen} /> : null}
      <MotifPatternGenerator />
      <FeatureDiscovery />
      {isInteractionMotivatorShownWithHideAnimation ? <InteractionMotivator /> : null}
      <PatternButtonListControl />
      <ActionButtons />
      {isKiosk && <QRGenerator />}
    </GeneratorWrapper>
  )
}

export default MotifGenerator

import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react'
import { useInteractionMotivatorTimer } from '../utils/interactionMotivatorTimer'
interface ContextType {
  isInteractionMotivatorShown: boolean
  setIsInteractionMotivatorShown: Dispatch<SetStateAction<boolean>>
  isInteractionMotivatorShownWithHideAnimation: boolean
  setIsInteractionMotivatorShownWithHideAnimation: Dispatch<SetStateAction<boolean>>
}

interface Props {
  children: ReactNode
}

const Context = createContext({} as ContextType)

const InteractionMotivatorContext = ({ children }: Props) => {
  const [isInteractionMotivatorShown, setIsInteractionMotivatorShown] = useState<boolean>(false)
  const [
    isInteractionMotivatorShownWithHideAnimation,
    setIsInteractionMotivatorShownWithHideAnimation,
  ] = useState<boolean>(false)

  const toggleInteractionMotivatorAnimation = useCallback((shouldDisplay: boolean) => {
    setIsInteractionMotivatorShown(shouldDisplay)
    if (shouldDisplay) {
      setIsInteractionMotivatorShownWithHideAnimation(true)
    }
  }, [])

  useInteractionMotivatorTimer({
    callback: toggleInteractionMotivatorAnimation,
  })
  return (
    <Context.Provider
      value={{
        isInteractionMotivatorShown,
        setIsInteractionMotivatorShown,
        isInteractionMotivatorShownWithHideAnimation,
        setIsInteractionMotivatorShownWithHideAnimation,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useContextInteractionMotivator = () => {
  return useContext(Context)
}

export default InteractionMotivatorContext

import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useContextUI } from '../../contexts/UIContext'
import { FeatureDiscoveryElement } from '../../types/FeatureDiscoveryElement'

const TooltipWrapper = styled.div`
  position: absolute;
  right: calc(100% + 10px);
  top: 50%;
  transform: translateY(-50%);

  .tooltip {
    padding: 0 30px;
    white-space: nowrap;
    font-size: 0.875rem;
    height: 60px;
    display: flex;
    align-items: center;
    background: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 60px;
    user-select: none;

    transform: translateX(50px);
    transition: all 0.4s;
    transition-delay: 0.6s;
    opacity: 0;

    &.show {
      animation: fade-in-x50 0.6s 0.6s forwards;
    }

    &.hide {
      opacity: 1;
      transform: translateX(0);
      animation: fade-out-x50 0.6s 0s forwards;
    }
  }

  img {
    width: 20px;
    margin-left: 1rem;
  }

  @media (min-height: ${({ theme }) => theme.breakpoints.deviceH.xs}) {
    .tooltip {
      height: 70px;
      padding: 0 40px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xs}) {
    right: calc(100% + 30px);

    img {
      width: 22px;
      margin-left: 2.3125rem;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xs}) and (min-height: ${({
      theme,
    }) => theme.breakpoints.deviceH.sm}) {
    .tooltip {
      padding: 0 40px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    .tooltip {
      font-size: 1.125rem;
      height: 90px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.md}) {
    .tooltip {
      height: 100px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.lg}) {
    right: calc(100% + 40px);

    .tooltip {
      font-size: 1.5rem;
      height: 120px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xxl}) {
    right: calc(100% + 3vw + 30px);
    .tooltip {
      font-size: 26px;
      padding: 0 44px;
      height: 136px;
      text-align: center;
      border-radius: 80px;
    }
  }
`

const GeneratorTooltip = () => {
  const { t } = useTranslation()
  const { featureDiscoveryElementsOpen, featureDiscoveryElementsAlreadyShown } = useContextUI()
  const tooltipRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (featureDiscoveryElementsOpen.includes(FeatureDiscoveryElement.PATTERNLIST)) {
      tooltipRef.current?.classList.add('show')
    } else if (tooltipRef.current?.classList.contains('show')) {
      tooltipRef.current?.classList.add('hide')
    }
  }, [featureDiscoveryElementsOpen, featureDiscoveryElementsAlreadyShown])

  return (
    <TooltipWrapper>
      <div ref={tooltipRef} className="tooltip">
        {t('featureDiscovery.choosePatternShort')}
        <img src={'/icons/download_icon.svg'} alt="arrow-svg" />
      </div>
    </TooltipWrapper>
  )
}

export default GeneratorTooltip

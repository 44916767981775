import React from 'react'
import { GeoFilterStage } from '../../constants/geographicFilters'
import styled from 'styled-components'
import { SelectorWrapper } from './common'
import SVG from 'react-inlinesvg'
import { useTranslation } from 'react-i18next'

const BlackBubble = styled.div`
  width: 295px;
  min-width: 295px;
  min-height: 295px;
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    border-radius: 100%;
    border: none;
    padding: 0;
    background: none;
    display: flex;
    justify-content: center;
  }

  img {
    z-index: 2;
  }

  svg {
    fill: currentColor;
    position: absolute;
    color: ${({ theme }) => theme.colors.white};
  }

  &.bubble-one {
    left: 30px;

    img {
      margin-right: 12px;
      width: 88%;
    }

    svg {
      z-index: 1;
      max-width: 90%;
      left: 11px;
      bottom: 10px;
    }
  }

  &.bubble-two {
    right: 30px;

    img {
      width: 53%;
    }

    svg {
      max-width: 67%;
      left: 74px;
      right: 17px;
      bottom: 10px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.md}) {
    min-width: 574px;
    min-height: 574px;

    &.bubble-one {
      svg {
        left: 25px;
        bottom: 15px;
      }
    }

    &.bubble-two {
      svg {
        left: 145px;
        bottom: 15px;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.lg}) {
    min-width: 780px;
    min-height: 780px;

    &.bubble-one {
      img {
        width: 85%;
      }
      svg {
        left: 30px;
        bottom: 30px;
      }
    }

    &.bubble-two {
      svg {
        left: 195px;
        bottom: 30px;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xl}) {
    min-width: 820px;
    min-height: 820px;

    &.bubble-one {
      img {
        width: 90%;
      }
      svg {
        left: 25px;
        bottom: 35px;
      }
    }

    &.bubble-two {
      svg {
        left: 190px;
        bottom: 35px;
      }
    }
  }
`

interface Props {
  className: string
  selectType: (type: GeoFilterStage) => void
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const TypeSelector = ({ selectType, className, onClick }: Props) => {
  const { i18n } = useTranslation()

  return (
    <SelectorWrapper className={`outside ${className}`} onClick={onClick}>
      <BlackBubble className="bubble-one">
        <button onClick={() => selectType(GeoFilterStage.GLOBAL)}>
          <img src="/icons/global-map-big.png" alt="global-map-big" className="global-map-big" />
        </button>
        <SVG className="global-map-text" src={`/icons/global-map-text-${i18n.language}.svg`} />
      </BlackBubble>
      <BlackBubble className="bubble-two">
        <button onClick={() => selectType(GeoFilterStage.LOCAL)}>
          <img src="/icons/local-map-big.png" alt="local-map-big" className="local-map-big" />
        </button>
        <SVG className="local-map-text" src={`/icons/local-map-text-${i18n.language}.svg`} />
      </BlackBubble>
    </SelectorWrapper>
  )
}

export default TypeSelector

import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { useContextMotifGenerator } from '../../contexts/motifGeneratorContext'
import { useContextUI } from '../../contexts/UIContext'
import { useNavigate } from 'react-router-dom'
import routePaths from '../../constants/routePaths'
import { useContextSelectedMotifs } from '../../contexts/selectedMotifContext'
import ConfirmationDialog from '../ConfirmationDialog'
import { useEffect, useRef, useState } from 'react'
import DotDotLoader from '../DotDotLoader'
import { UIElement } from '../../types/UIElements'
import { useEnv } from '../../utils/useEnv'
import { useContextScreensaver } from '../../contexts/screensaverContext'
import { useContextMotifDetails } from '../../contexts/motifDetailsContext'
import { useTranslation } from 'react-i18next'
import { FeatureDiscoveryElement } from '../../types/FeatureDiscoveryElement'

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 2vh;
  height: 60px;
  font-size: 0.875rem;
  right: 10px;
  gap: 20px;

  svg {
    max-width: 50%;
    max-height: 50%;
  }

  .feature-discovery {
    min-width: 270px;

    display: flex;
    align-items: center;
    position: absolute;
    right: -10px;
    height: 100%;
    padding-left: 30px;
    border-radius: 60px 0 0 60px;
    padding-right: calc(2 * 40px + 20px + 10px);
    background: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};
    white-space: pre-wrap;
    user-select: none;

    transform: translateX(100%);
    transition: transform 0.4s;
    opacity: 0;

    &.show {
      animation: fade-in-x50 0.6s 0.6s forwards;
    }

    &.hide {
      opacity: 1;
      transform: translateX(0);
      animation: fade-out-x50 0.6s 0s forwards;
    }
  }

  @media (min-height: ${({ theme }) => theme.breakpoints.deviceH.xs}) {
    height: 70px;

    .feature-discovery {
      min-width: 330px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xs}) {
    .feature-discovery {
      min-width: 330px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xs}) and (min-height: ${({
      theme,
    }) => theme.breakpoints.deviceH.sm}) {
    bottom: 18vh;

    .feature-discovery {
      padding-left: 40px;
      min-width: 350px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    font-size: 1.125rem;
    height: 90px;
    bottom: 9vh;

    .feature-discovery {
      min-width: 375px;
      padding-right: calc(2 * 70px + 20px + 20px + 30px);
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.md}) {
    height: 100px;
    right: 15px;

    .feature-discovery {
      right: -15px;
      min-width: 410px;
      padding-right: calc(2 * 80px + 20px + 20px);
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.lg}) {
    font-size: 1.5rem;
    height: 120px;

    .feature-discovery {
      min-width: 440px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xl}) {
    right: 25px;
    gap: 25px;
    .feature-discovery {
      min-width: 485px;
      right: -25px;
      padding-right: calc(2 * 90px + 20px + 20px + 30px);
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xxl}) {
    bottom: 15vh;
    gap: 30px;
    height: 136px;
    right: calc(3vw - 17px);

    .feature-discovery {
      right: calc(-3vw + 17px);
      min-width: calc(485px + 3vw);
      padding-left: 44px;
      border-radius: 80px 0 0 80px;
      padding-right: calc(2 * 112px + 20px + 20px + 30px);
    }
  }
`

const ActionButton = styled.button`
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  padding: 0;
  margin: auto;
  border: ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  transition: background-color 0.2s;

  &.red {
    background-color: ${({ theme }) => theme.colors.red};
    transition-delay: 0.6s;
  }

  &.pulse {
    animation: pulse 0.2s;
  }

  @media (min-height: ${({ theme }) => theme.breakpoints.deviceH.xxs}) {
    width: 50px;
    height: 50px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    width: 70px;
    height: 70px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.md}) {
    width: 80px;
    height: 80px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xl}) {
    width: 90px;
    height: 90px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xxl}) {
    width: 112px;
    height: 112px;
  }
`
const DownloadBtn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.25s ease-out;
  font-size: 10px;

  span {
    background-color: ${({ theme }) => theme.colors.white};
    transition: 0.2s ease-out;

    &:nth-child(1) {
      width: 1.3125em;
      height: 0.1875em;
    }

    &:nth-child(2) {
      width: 0.1875em;
      height: 1.75em;
    }

    &:nth-child(3) {
      height: 1em;
      width: 1em;
      background-color: transparent;
      transform: rotate(45deg);
      margin-top: -1.0625em;
      border-bottom: 0.1875em solid ${({ theme }) => theme.colors.white};
      border-right: 0.1875em solid ${({ theme }) => theme.colors.white};
    }
  }

  &.tick {
    transform: rotate(360deg);
    span:nth-child(1) {
      width: 0;
      opacity: 0;
    }
    span:nth-child(2) {
      height: 0;
      opacity: 0;
    }
    span:nth-child(3) {
      margin-top: -0.8125em;
      height: 1.75em;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.md}) {
    font-size: 13px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xl}) {
    font-size: 16px;
  }
`

const ActionButtons = () => {
  const { setIsScreensaverOn } = useContextScreensaver()
  const {
    setExportInvoked,
    setLoading,
    loading,
    selectedGeneratorType,
    setFreeLayoutGeneratorState,
  } = useContextMotifGenerator()
  const { t } = useTranslation()
  const {
    elementsOpen,
    setElementsOpen,
    isFirstGeneratorVisit,
    featureDiscoveryElementsOpen,
    setFeatureDiscoveryElementsOpen,
    featureDiscoveryElementsAlreadyShown,
  } = useContextUI()
  const navigate = useNavigate()
  const { setSelectedMotifs, setNumberOfSelectedMotifs } = useContextSelectedMotifs()
  const { motifMapViewport } = useContextMotifDetails()
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const [pulsing, setPulsing] = useState<boolean>(false)
  const { isKiosk } = useEnv()
  const featureDiscoveryRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (featureDiscoveryElementsOpen.includes(FeatureDiscoveryElement.ACTIONS)) {
      featureDiscoveryRef.current?.classList.add('show')
    } else if (featureDiscoveryRef.current?.classList.contains('show')) {
      featureDiscoveryRef.current?.classList.add('hide')
    }
  }, [featureDiscoveryElementsOpen, featureDiscoveryElementsAlreadyShown])

  useEffect(() => {
    setPulsing(true)
    setTimeout(() => {
      setPulsing(false)
    }, 200)
  }, [selectedGeneratorType])

  const handleRefresh = (confirmed: boolean) => {
    if (confirmed) {
      motifMapViewport.current = undefined

      navigate(routePaths.HOME)
      setSelectedMotifs([])
      setNumberOfSelectedMotifs(0)
    }
    setShowConfirmation(false)
  }

  const handleDownload = () => {
    setFeatureDiscoveryElementsOpen([])
    setIsScreensaverOn(false)
    if (isKiosk) {
      setElementsOpen(
        elementsOpen.includes(UIElement.CONTROLS) ? [UIElement.QRMODAL] : [UIElement.CONTROLS],
      )
    } else {
      setLoading(true)
      setExportInvoked(true)
    }
  }

  return (
    <>
      <ButtonWrapper>
        <div ref={featureDiscoveryRef} className="feature-discovery">
          {t('downloadOrRestart')}
        </div>
        <ActionButton
          className={`${pulsing ? 'download pulse' : 'download'} ${
            !featureDiscoveryElementsOpen.includes(FeatureDiscoveryElement.ACTIONS) &&
            !isFirstGeneratorVisit
              ? 'red'
              : ''
          }`}
          onClick={handleDownload}
          disabled={loading}
        >
          {loading ? (
            <DotDotLoader />
          ) : (
            <DownloadBtn
              className={`${elementsOpen.includes(UIElement.QRMODAL) ? 'tick' : ''}
              `}
            >
              <span />
              <span />
              <span />
            </DownloadBtn>
          )}
        </ActionButton>
        <ActionButton
          onClick={() => {
            setIsScreensaverOn(false)
            setShowConfirmation(true)
            setFreeLayoutGeneratorState({ images: [], isMirrored: false })
          }}
        >
          <SVG className="reload-svg" src="/icons/reload_bold.svg" />
        </ActionButton>
      </ButtonWrapper>
      {showConfirmation && (
        <ConfirmationDialog onConfirm={handleRefresh} message={'refreshConfirmation'} />
      )}
    </>
  )
}

export default ActionButtons

export enum GeoFilterStage {
  INITIAL = 'INITIAL',
  LOCAL = 'LOCAL',
  GLOBAL = 'GLOBAL',
}

export enum Region {
  EUROPE = 'EUROPE',
  ASIA = 'ASIA',
  AFRICA = 'AFRICA',
  NORTH_AMERICA = 'NORTH_AMERICA',
  CENTRAL_AMERICA = 'CENTRAL_AMERICA',
  SOUTH_AMERICA = 'SOUTH_AMERICA',
  AUSTRALIA = 'AUSTRALIA',
  OCEANIA = 'OCEANIA',
  MELANESIA = 'MELANESIA',
  CAUCASUS = 'CAUCASUS',
  ALFOLD = 'ALFOLD',
  DUNANTUL = 'DUNANTUL',
  FELVIDEK = 'FELVIDEK',
  ERDELY = 'ERDELY',
  SZEKELYFOLD = 'SZEKELYFOLD',
  DELVIDEK = 'DELVIDEK',
  HAJDUSAG = 'HAJDUSAG',
  KALOTASZEG = 'KALOTASZEG',
  SARKOZ = 'SARKOZ',
  MEZOKOVESD = 'MEZOKOVESD',
  KALOCSA = 'KALOCSA',
  ORMANSAG = 'ORMANSAG',
  BUKOVINA = 'BUKOVINA',
  ESZAKMAGYARORSZAG = 'ESZAKMAGYARORSZAG',
}

export const regions: Record<Region, number[]> = {
  [Region.EUROPE]: [836, 826, 820, 821, 827, 840, 844, 841, 842, 845, 843, 732, 285, 181, 428, 847],
  [Region.ASIA]: [828, 826],
  [Region.AFRICA]: [830],
  [Region.NORTH_AMERICA]: [834],
  [Region.CENTRAL_AMERICA]: [837],
  [Region.SOUTH_AMERICA]: [835],
  [Region.AUSTRALIA]: [808, 831],
  [Region.OCEANIA]: [833, 829, 808, 831],
  [Region.MELANESIA]: [829],
  [Region.CAUCASUS]: [826],
  [Region.ALFOLD]: [844, 827, 285],
  [Region.DUNANTUL]: [840, 732, 842],
  [Region.FELVIDEK]: [841],
  [Region.ERDELY]: [845, 820, 821],
  [Region.SZEKELYFOLD]: [820],
  [Region.DELVIDEK]: [843],
  [Region.HAJDUSAG]: [827],
  [Region.KALOTASZEG]: [821],
  [Region.SARKOZ]: [732],
  [Region.MEZOKOVESD]: [428],
  [Region.KALOCSA]: [285],
  [Region.ORMANSAG]: [842],
  [Region.BUKOVINA]: [181],
  [Region.ESZAKMAGYARORSZAG]: [847, 428],
}

export enum UIElement {
  WELCOME = 'WELCOME',
  SIDEMENU = 'SIDEMENU',
  EXPLANATION = 'EXPLANATION',
  TAGFILTER = 'TAGFILTER',
  GEOFILTER = 'GEOFILTER',
  GALLERY = 'GALLERY',
  ABOUT = 'ABOUT',
  QRMODAL = 'QRMODAL',
  CONTROLS = 'CONTROLS',
  LEGALNOTICE = 'LEGALNOTICE',
  NONE = 'NONE',
}

import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useThrottleFn } from '@react-cmpt/use-throttle'
import { Tile } from './penrose/tile'
import { TileKind } from './penrose/tilekind'
import { Constants } from './penrose/constants'
import { TilingPro } from './penrose/tilingpro'
import { CanvasTools } from './penrose/canvastools'
import { PositionXY } from '../../../types/PositionXY'
import { getPagePositionXY } from '../../../utils/getPagePositionXY'
import { EXPORT_SIZE, useContextMotifGenerator } from '../../../contexts/motifGeneratorContext'
import { useContextSelectedMotifs } from '../../../contexts/selectedMotifContext'
import { useContextUI } from '../../../contexts/UIContext'
import { useEnv } from '../../../utils/useEnv'
import { InfoSnackbarType } from '../../../constants/animationStates'
import { isAndroid, isIOS, isMobile } from 'react-device-detect'
import { Dimensions, JSONExportPlane, MotifGeneratorType } from '../types'
import { getTransformProperties } from '../util'

const DEFAULT_IMAGE_SIZE = 120
const MIN_IMAGE_SIZE = 60
const MAX_IMAGE_SIZE = 150
const DEFAULT_ZOOM_MULTIPLIER = 50
const DEFAULT_ITERATION_NUMBER = 6
const RESOLUTION_MULTIPLIER = 2

const PenroseContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-position: center center;
  canvas {
    position: absolute;
    top: 0;
    left: 0;
  }

  canvas:not(.canvas-to-export) {
    transform: scale(0.5) translate(-50%, -50%);
  }

  canvas.canvas-to-export {
    background-color: #fff;
    z-index: -1;
  }
`

type Props = {
  rotationAngleProp?: number
  imageSizeProp?: number
}

const PenroseGenerator = ({ rotationAngleProp, imageSizeProp }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const { isKiosk, isWeb } = useEnv()
  const dragging = useRef<boolean>(false)
  const defaultRotationAngle = Math.PI / 2
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const lastPos = useRef<PositionXY>({ x: 0, y: 0 })
  const { selectedMotifs } = useContextSelectedMotifs()
  const [images, setImages] = useState<HTMLImageElement[]>([])
  const [iterationNumber] = useState<number>(DEFAULT_ITERATION_NUMBER)
  const generatedImageContainerRef = useRef<HTMLDivElement | null>(null)
  const canvasToExportRef = useRef<HTMLCanvasElement>(null)
  const [imageSize, setImageSize] = useState<number>(imageSizeProp || DEFAULT_IMAGE_SIZE)
  const [rotationAngle, setRotationAngle] = useState(rotationAngleProp || defaultRotationAngle)
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)
  const {
    exportInvoked,
    setExportInvoked,
    exportGeneratorCoordinatesToJSON,
    handleExport,
    handleExportCanvas,
  } = useContextMotifGenerator()
  const [imageLoadingIsFinished, setImageLoadingIsFinished] = useState<boolean>(false)
  const [zoomMultiplier, setZoomMultiplier] = useState<number>(DEFAULT_ZOOM_MULTIPLIER)
  const [imagesThumbnailSize, setImagesThumbnailSize] = useState<HTMLImageElement[]>([])
  const { showSnackbar, hideSnackbar, disabledSnackbars, setDisabledSnackbars } = useContextUI()
  const jsonCoordinates = useRef<JSONExportPlane>({
    clipPlane: undefined,
    entries: [],
  })

  const loadImages = (index: number, isThumbail: boolean) => {
    const img = new Image()
    img.crossOrigin = ''
    img.src = isThumbail
      ? selectedMotifs[index].vectorizedUrlXs!
      : selectedMotifs[index].vectorizedUrl!
    img.onload = () => {
      isThumbail
        ? setImagesThumbnailSize([...imagesThumbnailSize, img])
        : setImages([...images, img])
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    hideSnackbar()
    if (!disabledSnackbars.includes(InfoSnackbarType.PENROSE)) {
      if (isKiosk) showSnackbar('snackbarMessages.usePenroseKiosk')
      if (isWeb) showSnackbar('snackbarMessages.usePenroseWeb')
      if (isMobile) showSnackbar('snackbarMessages.usePenroseMobile')
    }
    return () => {
      hideSnackbar()
      if (disabledSnackbars.includes(InfoSnackbarType.PENROSE)) {
        return
      } else {
        setDisabledSnackbars([...disabledSnackbars, InfoSnackbarType.PENROSE])
      }
    }
  }, [])

  const sortImages = (imagesToSort: HTMLImageElement[], sortByArray: string[]) => {
    return [...imagesToSort].sort((a, b) => sortByArray.indexOf(a.src) - sortByArray.indexOf(b.src))
  }

  useEffect(() => {
    if (
      images.length === selectedMotifs.length &&
      imagesThumbnailSize.length === selectedMotifs.length
    ) {
      setImageLoadingIsFinished(true)

      if (imageLoadingIsFinished) {
        setImages(
          sortImages(
            images,
            selectedMotifs.map(motif => motif.vectorizedUrl!),
          ),
        )
        setImagesThumbnailSize(
          sortImages(
            imagesThumbnailSize,
            selectedMotifs.map(motif => motif.vectorizedUrlXs!),
          ),
        )
      }
    }

    if (images.length < selectedMotifs.length && !imageLoadingIsFinished) {
      loadImages(images.length, false)
    }

    if (imagesThumbnailSize.length < selectedMotifs.length && !imageLoadingIsFinished) {
      loadImages(imagesThumbnailSize.length, true)
    }

    if (images.length > selectedMotifs.length) {
      setImages(
        images.filter(img => selectedMotifs.map(motif => motif.vectorizedUrl).includes(img.src)),
      )
    }

    if (imagesThumbnailSize.length > selectedMotifs.length) {
      setImagesThumbnailSize(
        imagesThumbnailSize.filter(img =>
          selectedMotifs.map(motif => motif.vectorizedUrlXs).includes(img.src),
        ),
      )
    }
  }, [selectedMotifs, images.length, imagesThumbnailSize.length, imageLoadingIsFinished])

  // Prevents pull-to refresh behavior on iOS devices
  useEffect(() => {
    if (!containerRef.current) return
    let prevent = false

    containerRef.current.addEventListener('touchstart', function (e) {
      if (e.touches.length !== 1) return

      const scrollY =
        window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop
      prevent = scrollY === 0
    })

    containerRef.current.addEventListener('touchmove', function (e) {
      if (prevent) {
        prevent = false
        e.preventDefault()
      }
    })
  }, [containerRef.current])

  useEffect(
    () =>
      void (async () => {
        if (!canvasToExportRef.current || !exportInvoked || !canvasRef.current) return

        exportGeneratorCoordinatesToJSON({
          props: {
            type: MotifGeneratorType.PENROSE,
            selectedMotifs,
            rotationAngle,
            imageSize,
            zoomMultiplier,
          },
          planes: [jsonCoordinates.current],
          canvasSize: {
            width: canvasRef.current.width,
            height: canvasRef.current.height,
          },
        })

        draw(canvasToExportRef.current, 80, {
          width: EXPORT_SIZE * RESOLUTION_MULTIPLIER,
          height: EXPORT_SIZE * RESOLUTION_MULTIPLIER,
        })

        // export large image, redraw on smaller canvas
        const imageData = await createImageBitmap(canvasToExportRef.current)
        canvasToExportRef.current.width = EXPORT_SIZE
        canvasToExportRef.current.height = EXPORT_SIZE

        const ctx = canvasToExportRef.current.getContext('2d')
        if (!ctx) return

        ctx.imageSmoothingQuality = 'high'
        ctx.imageSmoothingEnabled = true
        ctx.drawImage(imageData, 0, 0, EXPORT_SIZE, EXPORT_SIZE)

        if (isIOS || isAndroid) {
          handleExportCanvas(canvasToExportRef.current)
          setExportInvoked(false)
        } else {
          const exportCanvas = async () => {
            await handleExport(canvasToExportRef.current!)
            setExportInvoked(false)
          }

          exportCanvas().catch(console.error)
        }
      })(),
    [generatedImageContainerRef, exportInvoked],
  )

  useEffect(() => {
    if (windowWidth < 1366) {
      setZoomMultiplier(DEFAULT_ZOOM_MULTIPLIER)
    } else if (windowWidth >= 1366 && windowWidth <= 1920) {
      setZoomMultiplier(DEFAULT_ZOOM_MULTIPLIER * 1)
    } else if (windowWidth > 1920 && windowWidth <= 2560) {
      setZoomMultiplier(DEFAULT_ZOOM_MULTIPLIER * 1.5)
    } else if (windowWidth > 2560) {
      setZoomMultiplier(DEFAULT_ZOOM_MULTIPLIER * 2.5)
    }
  }, [windowWidth])

  useEffect(() => {
    if (!canvasRef.current) return

    draw(canvasRef.current, zoomMultiplier)
  }, [
    canvasRef,
    windowWidth,
    zoomMultiplier,
    images,
    imagesThumbnailSize,
    rotationAngle,
    imageSize,
  ])

  const draw = (canvas: HTMLCanvasElement, zoomMultiplier: number, size?: Dimensions) => {
    if (
      images.length !== selectedMotifs.length ||
      imagesThumbnailSize.length !== selectedMotifs.length
    )
      return

    const ctx = canvas.getContext('2d')
    if (!ctx) return

    if (!size) {
      canvas.width =
        window.innerWidth < 1000
          ? 1000 * RESOLUTION_MULTIPLIER
          : window.innerWidth * RESOLUTION_MULTIPLIER
      canvas.height =
        window.innerHeight < 1000
          ? 1000 * RESOLUTION_MULTIPLIER
          : window.innerHeight * RESOLUTION_MULTIPLIER
      ctx.scale(RESOLUTION_MULTIPLIER, RESOLUTION_MULTIPLIER)
    } else {
      canvas.width = size.width
      canvas.height = size.height
    }

    ctx.fillStyle = '#fff'
    ctx.strokeStyle = '#906090'
    ctx.lineWidth = 0.5
    ctx.imageSmoothingQuality = 'high'
    ctx.imageSmoothingEnabled = true

    ctx.beginPath()
    ctx.rect(
      0,
      0,
      canvas.width < EXPORT_SIZE ? EXPORT_SIZE : canvas.width,
      canvas.height < EXPORT_SIZE ? EXPORT_SIZE : canvas.height,
    )
    ctx.fill()

    if (size) {
      ctx.translate(-canvas.width / 2 + size.width / 2, -canvas.height / 2 + size.height / 2)
    } else {
      ctx.translate(
        -canvas.width / 2 + window.innerWidth / 2,
        -canvas.height / 2 + window.innerHeight / 2,
      )
    }

    const ct = new CanvasTools(ctx)
    ct.resetCartesian()
    const tilingPro = new TilingPro().deflate(iterationNumber)
    const tessellation = tilingPro.getHalfTiles().findRhombs().normalize()
    const rhombs = tessellation.findRhombs().normalize()

    jsonCoordinates.current.entries = []
    Array.from(rhombs).forEach((rhomb: Tile) => {
      const translateX =
        rhomb.kind.value === TileKind.THIN_RHOMB.value
          ? zoomMultiplier / 2
          : Math.cos(Math.PI / 5) * Constants.PHI * zoomMultiplier

      const finalImageSize =
        rhomb.kind.value === TileKind.THICK_RHOMB.value
          ? imageSize * 1.6 * (zoomMultiplier / 100)
          : imageSize * (zoomMultiplier / 100)

      const thumbnailUsageLimit = finalImageSize * 1.8

      let image =
        thumbnailUsageLimit < imagesThumbnailSize[0].width ? imagesThumbnailSize[0] : images[0]
      let imageIdx = 0

      if (selectedMotifs.length === 2) {
        image = rhomb.kind.value === TileKind.THICK_RHOMB.value ? images[0] : images[1]
      }

      const dist = Math.sqrt(Math.pow(rhomb.origin.x, 2) + Math.pow(rhomb.origin.y, 2))
      if (selectedMotifs.length === 3) {
        if (rhomb.kind.value === TileKind.THICK_RHOMB.value) {
          if (Math.trunc(dist / 4) % 2 === 1) {
            image =
              thumbnailUsageLimit < imagesThumbnailSize[2].width
                ? imagesThumbnailSize[2]
                : images[2]
            imageIdx = 2
          } else {
            image =
              thumbnailUsageLimit < imagesThumbnailSize[0].width
                ? imagesThumbnailSize[0]
                : images[0]
            imageIdx = 0
          }
        } else {
          image =
            thumbnailUsageLimit < imagesThumbnailSize[1].width ? imagesThumbnailSize[1] : images[1]
          imageIdx = 1
        }
      }

      if (selectedMotifs.length === 4) {
        if (Math.trunc(dist / 4 - 1) % 2 === 1) {
          if (rhomb.kind.value === TileKind.THICK_RHOMB.value) {
            image =
              thumbnailUsageLimit < imagesThumbnailSize[2].width
                ? imagesThumbnailSize[2]
                : images[2]
            imageIdx = 2
          } else {
            image =
              thumbnailUsageLimit < imagesThumbnailSize[0].width
                ? imagesThumbnailSize[0]
                : images[0]
            imageIdx = 0
          }
        } else {
          if (rhomb.kind.value === TileKind.THICK_RHOMB.value) {
            image =
              thumbnailUsageLimit < imagesThumbnailSize[3].width
                ? imagesThumbnailSize[3]
                : images[3]
            imageIdx = 3
          } else {
            image =
              thumbnailUsageLimit < imagesThumbnailSize[1].width
                ? imagesThumbnailSize[1]
                : images[1]
            imageIdx = 1
          }
        }
      }
      const angle = rhomb.abscissa.angle()
      ctx.save()

      ctx.translate(rhomb.origin.x * zoomMultiplier, rhomb.origin.y * zoomMultiplier)
      ctx.rotate(angle)

      ctx.translate(translateX, 0)
      ctx.rotate(rotationAngle)

      ctx.scale(-1, 1)

      const { origin, scale, rotation } = getTransformProperties(ctx)
      jsonCoordinates.current.entries.push({
        id: selectedMotifs[imageIdx].id,
        x: origin.x,
        y: origin.y,
        size: finalImageSize * scale,
        rotation: rotation,
      })

      ctx.drawImage(image, -finalImageSize / 2, -finalImageSize / 2, finalImageSize, finalImageSize)
      ctx.restore()
    })
  }

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

  const handleMouseDown = (event: React.MouseEvent) => {
    event.preventDefault()
    onStartEvent(event.pageX, event.pageY)
  }

  const handleTouchStart = (event: React.TouchEvent) => {
    onStartEvent(event.touches[0].pageX, event.touches[0].pageY)
  }

  const onStartEvent = (x: number, y: number) => {
    lastPos.current.x = x
    lastPos.current.y = y
    dragging.current = true
  }

  const handleMouseUp = (event: React.MouseEvent | React.TouchEvent) => {
    event.preventDefault()
    dragging.current = false
  }

  const handleMouseMove = (event: React.MouseEvent | React.TouchEvent): void => {
    if (dragging.current) {
      const pageXY = getPagePositionXY(event)
      const moveX = lastPos.current.x - pageXY.x
      const moveY = lastPos.current.y - pageXY.y

      lastPos.current = pageXY

      let limitedImageSize = moveY + imageSize
      if (limitedImageSize > MAX_IMAGE_SIZE) {
        limitedImageSize = MAX_IMAGE_SIZE
      } else if (limitedImageSize < MIN_IMAGE_SIZE) {
        limitedImageSize = MIN_IMAGE_SIZE
      }

      setImageSize(limitedImageSize)
      setRotationAngle(rotationAngle + moveX / 100)
    }
  }

  const { callback } = useThrottleFn(handleMouseMove, 40)

  return (
    <PenroseContainer
      ref={containerRef}
      onMouseDown={handleMouseDown}
      onMouseMove={callback}
      onMouseUp={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchMove={callback}
      onTouchEnd={handleMouseUp}
    >
      <canvas
        ref={canvasRef}
        width={window.innerWidth < EXPORT_SIZE ? EXPORT_SIZE : window.innerWidth}
        height={window.innerHeight < EXPORT_SIZE ? EXPORT_SIZE : window.innerHeight}
      ></canvas>
      {exportInvoked ? (
        <canvas
          className="canvas-to-export"
          ref={canvasToExportRef}
          width={EXPORT_SIZE}
          height={EXPORT_SIZE}
        ></canvas>
      ) : null}
    </PenroseContainer>
  )
}

export default PenroseGenerator

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { isDesktop, isTablet } from 'react-device-detect'
import { useState, useEffect } from 'react'
import routePaths from './constants/routePaths'
import Home from './pages/Home'
import Welcome from './components/Welcome'
import MotifCounter from './components/MotifCounter'
import ExplanationFeature from './components/ExplanationFeature'
import TagFilter from './components/TagFilter'
import SideMenu from './components/SideMenu'
import MotifGenerator from './pages/MotifGenerator'
import WhiteOverlay from './components/WhiteOverlay'
import { useContextUI } from './contexts/UIContext'
import FullScreenLoading from './components/FullScreenLoading'
import LanguageChanger from './components/LanguageChanger'
import GeographicFilter from './components/GeographicFilter'
import ErrorMessage from './components/ErrorMessage'
import Snackbar from './components/Snackbar'
import { useContextSelectedMotifs } from './contexts/selectedMotifContext'
import BrandLogo from './components/BrandLogo'
import { useEnv } from './utils/useEnv'
import RotateDeviceNotification from './components/RotateDeviceNotification'
import ScreensaverContext from './contexts/screensaverContext'
import Gallery from './components/Gallery'
import AboutProject from './components/AboutProject'
import InteractionMotivatorContext from './contexts/interactionMotivatorContext'
import { UIElement } from './types/UIElements'
import LegalNotice from './components/LegalNotice'
import { Statistics } from './components/Statistics'

const Router = () => {
  const { isInitialLoading, rotateDeviceNotificationVisible, elementsOpen } = useContextUI()
  const { isWeb, isKiosk } = useEnv()
  const { selectedMotifs } = useContextSelectedMotifs()
  const [showLoadingScreen, setShowLoadingScreen] = useState(false)

  useEffect(() => {
    if (isInitialLoading && !showLoadingScreen) setShowLoadingScreen(true)

    if (!isInitialLoading && showLoadingScreen) {
      setTimeout(() => {
        setShowLoadingScreen(false)
      }, 1000)
    }
  }, [isInitialLoading, rotateDeviceNotificationVisible])

  return (
    <BrowserRouter>
      <ScreensaverContext>
        <InteractionMotivatorContext>
          <Routes>
            <Route path={routePaths.HOME} element={<Home />} />
            <Route
              path={routePaths.GENERATE}
              element={
                selectedMotifs.length < 1 ? <Navigate to={routePaths.HOME} /> : <MotifGenerator />
              }
            />
            <Route path={routePaths.ABOUT_PROJECT} element={<AboutProject />} />
            <Route path={routePaths.GALLERY} element={<Gallery />} />
            <Route path={routePaths.STATISTICS} element={<Statistics />} />
            <Route path={'*'} element={<Navigate to={routePaths.HOME} />} />
          </Routes>

          <WhiteOverlay />
          <TagFilter />
          <GeographicFilter />
          {elementsOpen.includes(UIElement.GALLERY) ? <Gallery /> : null}
          {elementsOpen.includes(UIElement.ABOUT) ? <AboutProject /> : null}
          <ExplanationFeature />
          <SideMenu />
          {elementsOpen.includes(UIElement.LEGALNOTICE) ? <LegalNotice /> : null}
          <MotifCounter />
          <Snackbar />
          <Welcome />
          <ErrorMessage />
          {isDesktop || isKiosk || isTablet ? <LanguageChanger /> : null}
          {!isKiosk && isDesktop ? isWeb && <BrandLogo /> : null}
          {showLoadingScreen && (
            <FullScreenLoading isCompleted={!isInitialLoading && showLoadingScreen} />
          )}
          {rotateDeviceNotificationVisible ? <RotateDeviceNotification /> : null}
        </InteractionMotivatorContext>
      </ScreensaverContext>
    </BrowserRouter>
  )
}

export default Router

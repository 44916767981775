import React, { useEffect, useState } from 'react'
import { Motif } from '../../types/motifs'
import { useContextSelectedMotifs } from '../../contexts/selectedMotifContext'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { isMobile } from 'react-device-detect'

const StyledButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.black70};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  grid-row: 2 / 2;
  grid-column: 1 / 1;
  align-self: start;

  &:disabled {
    cursor: not-allowed;
    .circle {
      opacity: 0.3;
    }
  }
`

const SelectorButton = styled(StyledButton)`
  gap: 5px;

  p {
    text-align: right;
    margin: 0;
  }

  &.mobile {
    flex-direction: row-reverse;
    gap: 9px;

    p {
      font-size: 13px !important;
      text-align: left;
    }
  }

  .circle {
    min-height: 44px;
    min-width: 44px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;

    svg {
      width: 30px;
      height: 30px;
    }

    &.add {
      background-color: ${({ theme }) => theme.colors.red};

      svg {
        color: ${({ theme }) => theme.colors.white};
      }
    }

    &.remove {
      background-color: transparent;
      border: 1px solid black;

      svg {
        color: ${({ theme }) => theme.colors.red};
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    p {
      margin-right: 10px;
      font-size: 15px;
      line-height: 24px;
      text-align: right;
    }

    .circle {
      min-height: 70px;
      min-width: 70px;

      svg {
        width: 40px;
        height: 40px;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.md}) {
    .circle {
      min-height: 80px;
      min-width: 80px;

      svg {
        width: 50px;
        height: 50px;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xl}) {
    .circle {
      min-height: 90px;
      min-width: 90px;

      svg {
        width: 60px;
        height: 60px;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xxl}) {
    .circle {
      min-height: 112px;
      min-width: 112px;
    }
  }

  @media (min-height: ${({ theme }) => theme.breakpoints.deviceH.md}) {
    p {
      font-size: 15px;
    }
  }
`

interface Props {
  motif: Motif
}

const MotifSelector = ({ motif }: Props) => {
  const { t } = useTranslation()
  const { selectedMotifs, isSelected, selectMotif, deselectMotif } = useContextSelectedMotifs()
  const [numberedText, setNumberedText] = useState<string | false>(false)
  const [isMotifSelected, setIsMotifSelected] = useState<boolean>(false)

  useEffect(() => {
    setIsMotifSelected(isSelected(motif.id))
  }, [selectedMotifs.length])

  useEffect(() => {
    switch (selectedMotifs.length) {
      case 0:
        setNumberedText('maxFourMotifs')
        break
      case 1:
        setNumberedText('threeMoreMotifs')
        break
      case 2:
        setNumberedText('twoMoreMotifs')
        break
      case 3:
        setNumberedText('oneMoreMotif')
        break
      case 4:
        setNumberedText(false)
        break
    }
  }, [selectedMotifs.length])

  return (
    <SelectorButton
      className={`${isMobile ? 'mobile' : ''}`}
      onClick={event => {
        event.stopPropagation()
        if (isMotifSelected) {
          deselectMotif(motif.id)
        } else {
          selectMotif(motif)
        }
      }}
      disabled={selectedMotifs.length === 4 && !isMotifSelected}
    >
      <div>
        <p>{selectedMotifs.length === 4 ? t('maxMotifsReached') : t('selectThisMotif')}</p>
        {numberedText && <p>{t(numberedText)}</p>}
      </div>
      <div className={`circle ${isMotifSelected ? 'remove' : 'add'}`}>
        {isMotifSelected ? <RemoveIcon /> : <AddIcon />}
      </div>
    </SelectorButton>
  )
}

export default MotifSelector

import { Motif } from '../../types/motifs'
import { PositionXY } from '../../types/PositionXY'
import { Circle } from './MapTypeGenerator/generate'

export enum MotifGeneratorType {
  MAP = 'MAP',
  SQUARE = 'SQUARE',
  CIRCLE = 'CIRCLE',
  PENROSE = 'PENROSE',
  KALEIDO_SQUARE = 'KALEIDO_SQUARE',
  KALEIDO_PENTA = 'KALEIDO_PENTA',
  KALEIDO_HEXA = 'KALEIDO_HEXA',
  KALEIDO_OCTA = 'KALEIDO_OCTA',
  FREE = 'FREE',
}

export type JSONExportWithTimestamp = JSONExport & {
  timestamp: number
  timezone: number
}

export interface JSONExport {
  planes: JSONExportPlane[]
  props: GeneratorProps
  canvasSize: Dimensions
}

export type ClippingPlane =
  | undefined
  | { kind: 'polygon'; points: PositionXY[] }
  | { kind: 'circle'; position: PositionXY; radius: number }

export interface JSONExportPlane {
  clipPlane: ClippingPlane
  entries: JSONExportEntry[]
}

export interface JSONExportEntry {
  id: number
  x: number
  y: number
  size: number
  rotation: number
  flipX?: boolean
  flipY?: boolean
}

export type GeneratorProps =
  | undefined
  | CircleLayoutGeneratorProps
  | SquareLayoutGeneratorProps
  | PenroseGeneratorProps
  | KaleidoscopeGeneratorProps
  | MapGeneratorProps

export interface Dimensions {
  width: number
  height: number
}

interface CircleLayoutGeneratorProps {
  type: MotifGeneratorType.CIRCLE
  selectedMotifs: Motif[]
  rotationAngle: number
}

interface SquareLayoutGeneratorProps {
  type: MotifGeneratorType.SQUARE
  selectedMotifs: Motif[]
  rotationAngle: number
}

interface PenroseGeneratorProps {
  type: MotifGeneratorType.PENROSE
  selectedMotifs: Motif[]
  rotationAngle: number
  imageSize: number
  zoomMultiplier: number
}

interface KaleidoscopeGeneratorProps {
  type:
    | MotifGeneratorType.KALEIDO_HEXA
    | MotifGeneratorType.KALEIDO_OCTA
    | MotifGeneratorType.KALEIDO_PENTA
    | MotifGeneratorType.KALEIDO_SQUARE
  selectedMotifs: Motif[]
  position: PositionXY
  width: number
}

interface MapGeneratorProps {
  type: MotifGeneratorType.MAP
  selectedMotifs: Motif[]
  canvasSize: Dimensions
  circles: Circle[]
}

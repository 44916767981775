import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  JSONExport,
  JSONExportWithTimestamp,
  MotifGeneratorType,
} from '../components/MotifPatternGenerator/types'
import { toBlob, toJpeg } from 'html-to-image'
import { useEnv } from '../utils/useEnv'
import { useContextUI } from './UIContext'
import { useAxiosApi } from '../utils/axios'
import { Errors } from '../constants/apiErrors'
import { AxiosError } from 'axios'
import { saveAs } from 'file-saver'
import { Motif } from '../types/motifs'
import { PositionXY } from '../types/PositionXY'
import { nanoid } from 'nanoid'
import { FreeLayoutGeneratorState } from '../types/GeneratorState'

interface GeneratorContextType {
  selectedGeneratorType: MotifGeneratorType
  selectGeneratorType: (generatorType: MotifGeneratorType) => void
  exportInvoked: boolean
  setExportInvoked: Dispatch<SetStateAction<boolean>>
  isPatternGenerated: boolean
  setIsPatternGenerated: Dispatch<SetStateAction<boolean>>
  exportGeneratorCoordinatesToJSON: (json: JSONExport) => void
  handleExport: (element: HTMLElement) => Promise<boolean>
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  isRegenerated: boolean
  setIsRegenerated: Dispatch<SetStateAction<boolean>>
  handleExportCanvas: (canvasElement: HTMLCanvasElement) => void
  selectedMotifForFreeGeneration: Motif | undefined
  setSelectedMotifForFreeGeneration: Dispatch<SetStateAction<Motif | undefined>>
  freeGeneratorTouchEventPageXY: PositionXY | undefined
  setFreeGeneratorTouchEventPageXY: Dispatch<SetStateAction<PositionXY | undefined>>
  freeLayoutGeneratorFeatureDiscoveryAlreadyShown: boolean
  setFreeLayoutGeneratorFeatureDiscoveryAlreadyShown: Dispatch<SetStateAction<boolean>>
  freeLayoutGeneratorState: FreeLayoutGeneratorState | undefined
  setFreeLayoutGeneratorState: Dispatch<SetStateAction<FreeLayoutGeneratorState | undefined>>
}

interface Props {
  children: ReactNode
}

export const EXPORT_SIZE = 1000
const Context = createContext({} as GeneratorContextType)

const MotifGeneratorContext = ({ children }: Props) => {
  const { isKiosk } = useEnv()
  const { setQrCodeLink, showSnackbar } = useContextUI()
  const { post } = useAxiosApi()
  const [selectedGeneratorType, setSelectedGeneratorType] = useState<MotifGeneratorType>(
    MotifGeneratorType.CIRCLE,
  )
  const [exportInvoked, setExportInvoked] = useState<boolean>(false)
  const [isPatternGenerated, setIsPatternGenerated] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [isRegenerated, setIsRegenerated] = useState<boolean>(false)
  const [selectedMotifForFreeGeneration, setSelectedMotifForFreeGeneration] = useState<Motif>()
  const [freeGeneratorTouchEventPageXY, setFreeGeneratorTouchEventPageXY] = useState<PositionXY>()
  const [
    freeLayoutGeneratorFeatureDiscoveryAlreadyShown,
    setFreeLayoutGeneratorFeatureDiscoveryAlreadyShown,
  ] = useState(false)
  const [freeLayoutGeneratorState, setFreeLayoutGeneratorState] =
    useState<FreeLayoutGeneratorState>()

  const selectGeneratorType = (generatorType: MotifGeneratorType) => {
    setSelectedGeneratorType(generatorType)
  }

  const forceIdRef = useRef<string>(nanoid())
  useEffect(() => {
    if (exportInvoked) {
      forceIdRef.current = nanoid()
    }
  }, [exportInvoked])

  const exportGeneratorCoordinatesToJSON = async (json: JSONExport) => {
    try {
      const jsonWithTime: JSONExportWithTimestamp = {
        timestamp: Number(Date.now()),
        timezone: new Date().getTimezoneOffset(),
        ...json,
      }

      const res = await post(`/uploadJson?forceId=${forceIdRef.current}`, jsonWithTime)
      return res.data
    } catch (e) {
      console.log(e)
    }
  }

  const handleExport = async (element: HTMLElement): Promise<boolean> => {
    setLoading(true)
    if (isKiosk) {
      const blob = await toBlob(element, {
        width: EXPORT_SIZE / window.devicePixelRatio,
        height: EXPORT_SIZE / window.devicePixelRatio,
      })
      if (!blob) throw new Error('Could not create Blob from image')
      const imageUrl = await uploadImage(blob)
      setQrCodeLink(imageUrl)
    } else {
      const dataUrl = await toJpeg(element, {
        width: EXPORT_SIZE / window.devicePixelRatio,
        height: EXPORT_SIZE / window.devicePixelRatio,
        skipAutoScale: true,
        quality: 0.92,
      })
      const link = document.createElement('a')
      link.download = 'pattern.jpeg'
      link.href = dataUrl
      link.click()
      setLoading(false)
    }
    return Promise.resolve(true)
  }

  const handleExportCanvas = async (canvasElement: HTMLCanvasElement) => {
    canvasElement.toBlob(async blob => {
      try {
        if (!blob) return
        const imageUrl = await uploadImage(blob)
        saveAs(imageUrl, 'pattern.jpg')
      } catch (e) {
        let errorKey = Errors.SERVER_ERROR.toString()
        const message = (e as AxiosError)?.response?.data?.message
        if (message && Object.values(Errors).includes(message)) {
          errorKey = message
        }
        showSnackbar(`snackbarMessages.uploadApiError.${errorKey}`, 10)
        console.dir(e)
        throw e
      } finally {
        setLoading(false)
      }
    }, 'image/jpeg')
  }

  const uploadImage = async (blob: Blob): Promise<string> => {
    try {
      const formData = new FormData()
      formData.append('image', blob, 'pattern.jpeg')
      const res = await post(`/upload?forceId=${forceIdRef.current}`, formData)

      // NOTE: axios does unsolicited json parsing
      const json = res.data as { url: string }
      return json.url
    } catch (e) {
      let errorKey = Errors.SERVER_ERROR.toString()
      const message = (e as AxiosError)?.response?.data?.message
      if (message && Object.values(Errors).includes(message)) {
        errorKey = message
      }
      showSnackbar(`snackbarMessages.uploadApiError.${errorKey}`, 10)
      console.dir(e)
      throw e
    } finally {
      setLoading(false)
    }
  }

  return (
    <Context.Provider
      value={{
        selectedGeneratorType,
        selectGeneratorType,
        exportInvoked,
        setExportInvoked,
        isPatternGenerated,
        setIsPatternGenerated,
        exportGeneratorCoordinatesToJSON,
        handleExport,
        loading,
        setLoading,
        isRegenerated,
        setIsRegenerated,
        handleExportCanvas,
        selectedMotifForFreeGeneration,
        setSelectedMotifForFreeGeneration,
        freeGeneratorTouchEventPageXY,
        setFreeGeneratorTouchEventPageXY,
        freeLayoutGeneratorFeatureDiscoveryAlreadyShown,
        setFreeLayoutGeneratorFeatureDiscoveryAlreadyShown,
        freeLayoutGeneratorState,
        setFreeLayoutGeneratorState,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useContextMotifGenerator = () => {
  return useContext(Context)
}

export default MotifGeneratorContext

const MaskSquare = () => {
  return (
    <svg id="svg-mask" width="300" height="300" xmlns="http://www.w3.org/2000/svg">
      <rect x="0" y="0" rx="0" ry="0" width="300" height="300" fill="#fff" />
      Sorry, your browser does not support inline SVG.
    </svg>
  )
}

export default MaskSquare

import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Motif } from '../../../types/motifs'
import { useContextSelectedMotifs } from '../../../contexts/selectedMotifContext'
import { EXPORT_SIZE, useContextMotifGenerator } from '../../../contexts/motifGeneratorContext'
import { initializeAroundMotifs } from './initAroundMotifs'
import { isAndroid, isIOS } from 'react-device-detect'
import { PositionXY } from '../../../types/PositionXY'
import { getPagePositionXY } from '../../../utils/getPagePositionXY'
import { useThrottleFn } from '@react-cmpt/use-throttle'
import { JSONExportPlane, MotifGeneratorType } from '../types'
import { getTransformProperties } from '../util'

const TILE_NUMBER = 9
const AROUND_MOTIF_SPACING = 1
const AMOUNT_OF_MOTIFS_AROUND = 16
const RESOLUTION_MULTIPLIER = 2

const Container = styled.div`
  width: 120vw;
  height: 120vw;
  display: flex;
  flex-wrap: wrap;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 10;
  }

  .tile {
    background-color: ${({ theme }) => theme.colors.white};
    width: calc(100% / 3);
    height: calc(100% / 3);
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    z-index: 20;

    &.tile-to-export {
      width: ${EXPORT_SIZE}px;
      height: ${EXPORT_SIZE}px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }

    canvas {
      width: 100%;
      height: 100%;
    }
  }
`
type Props = {
  rotationAngleProp?: number
}

const CircleLayoutGeneratorCanvas = ({ rotationAngleProp }: Props) => {
  const { selectedMotifs } = useContextSelectedMotifs()
  const [firstTimeGenerated, setFirstTimeGenerated] = useState<Boolean>(false)
  const [centerMotif, setCenterMotif] = useState<Motif>()
  const [aroundMotifs, setAroundMotifs] = useState<Motif[]>([])
  const canvasRefs = useRef<HTMLCanvasElement[]>([])
  const canvasToExportRef = useRef<HTMLCanvasElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [images, setImages] = useState<HTMLImageElement[]>([])
  const [imageLoadingIsFinished, setImageLoadingIsFinished] = useState<boolean>(false)
  const [canvasDataUrl, setCanvasDataUrl] = useState<string | null>(null)
  const lastPos = useRef<PositionXY>({ x: 0, y: 0 })
  const dragging = useRef<boolean>(false)
  const defaultRotationAngle = 0
  const [rotationAngle, setRotationAngle] = useState(rotationAngleProp || defaultRotationAngle)
  const jsonCoordinates = useRef<JSONExportPlane>({
    clipPlane: undefined,
    entries: [],
  })

  const {
    exportInvoked,
    setExportInvoked,
    exportGeneratorCoordinatesToJSON,
    handleExport,
    handleExportCanvas,
    isPatternGenerated,
    setIsPatternGenerated,
  } = useContextMotifGenerator()

  const loadImages = (index: number) => {
    const img = new Image()
    img.crossOrigin = ''
    img.src = selectedMotifs[index].vectorizedUrl!
    img.onload = () => {
      setImages([...images, img])
    }
  }

  const sortImages = (imagesToSort: HTMLImageElement[], sortByArray: string[]) => {
    return [...imagesToSort].sort((a, b) => sortByArray.indexOf(a.src) - sortByArray.indexOf(b.src))
  }

  useEffect(() => {
    if (images.length === selectedMotifs.length) {
      setImageLoadingIsFinished(true)

      if (imageLoadingIsFinished) {
        setImages(
          sortImages(
            images,
            selectedMotifs.map(motif => motif.vectorizedUrl!),
          ),
        )
      }
    }
    if (images.length < selectedMotifs.length && !imageLoadingIsFinished) {
      loadImages(images.length)
    }
    if (images.length > selectedMotifs.length) {
      setImages(
        images.filter(img => selectedMotifs.map(motif => motif.vectorizedUrl).includes(img.src)),
      )
    }
  }, [selectedMotifs, images.length, imageLoadingIsFinished])

  // Prevents pull-to refresh behavior on iOS devices
  useEffect(() => {
    if (!containerRef.current) return
    let prevent = false

    containerRef.current.addEventListener('touchstart', function (e) {
      if (e.touches.length !== 1) return

      const scrollY =
        window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop
      prevent = scrollY === 0
    })

    containerRef.current.addEventListener('touchmove', function (e) {
      if (prevent) {
        prevent = false
        e.preventDefault()
      }
    })
  }, [containerRef.current])

  useEffect(() => {
    if (!canvasToExportRef.current || !exportInvoked) return

    setCanvasDataUrl(canvasToExportRef.current.toDataURL()!)
  }, [exportInvoked, canvasToExportRef])

  useEffect(() => {
    if (!canvasDataUrl || !canvasToExportRef.current) return

    exportGeneratorCoordinatesToJSON({
      props: {
        type: MotifGeneratorType.CIRCLE,
        selectedMotifs,
        rotationAngle,
      },
      planes: [jsonCoordinates.current],
      canvasSize: {
        width: canvasRefs.current[0].width,
        height: canvasRefs.current[0].height,
      },
    })

    if (isIOS || isAndroid) {
      handleExportCanvas(canvasToExportRef.current)
      setExportInvoked(false)
      setCanvasDataUrl(null)
    } else {
      const exportCanvas = async () => {
        await handleExport(canvasToExportRef.current!)
        setExportInvoked(false)
        setCanvasDataUrl(null)
      }

      exportCanvas().catch(console.error)
    }
  }, [canvasDataUrl, canvasToExportRef])

  useEffect(() => {
    if (!selectedMotifs) return
    if (!isPatternGenerated) setIsPatternGenerated(true)

    setCenterMotif(selectedMotifs[0])
    if (selectedMotifs.length === 1) {
      setAroundMotifs([selectedMotifs[0]])
      return
    }
    setAroundMotifs(selectedMotifs.filter((_m, i) => i !== 0))
  }, [selectedMotifs])

  useEffect(() => {
    if (exportInvoked && canvasToExportRef.current) {
      draw(canvasToExportRef.current)
    }

    if (canvasRefs.current.length !== TILE_NUMBER || selectedMotifs.length !== images.length) return

    canvasRefs.current.forEach(canvas => {
      draw(canvas)
    })

    if (!firstTimeGenerated) {
      setFirstTimeGenerated(true)
    }
  }, [canvasRefs, aroundMotifs, centerMotif, images, canvasDataUrl, rotationAngle, exportInvoked])

  const draw = (canvas: HTMLCanvasElement) => {
    if (!canvas) return

    const ctx = canvas.getContext('2d')
    canvas.width = canvas.offsetWidth * RESOLUTION_MULTIPLIER
    canvas.height = canvas.offsetHeight * RESOLUTION_MULTIPLIER
    if (!ctx) return

    jsonCoordinates.current.entries = []

    ctx.scale(RESOLUTION_MULTIPLIER, RESOLUTION_MULTIPLIER)
    ctx.imageSmoothingQuality = 'high'
    ctx.imageSmoothingEnabled = true

    ctx.beginPath()
    ctx.fillStyle = '#fff'
    ctx.fillRect(0, 0, canvas.width, canvas.height)

    const centralImageWidth =
      canvas.offsetWidth * 0.8 - selectedMotifs.length * (canvas.offsetWidth * 0.13)
    const centralImageHeight =
      canvas.offsetHeight * 0.8 - selectedMotifs.length * (canvas.offsetHeight * 0.13)

    const centralImageX = canvas.offsetWidth / 2 - centralImageWidth / 2
    const centralImageY = canvas.offsetHeight / 2 - centralImageHeight / 2

    ctx.save()
    ctx.translate(centralImageX + centralImageWidth / 2, centralImageY + centralImageHeight / 2)
    ctx.rotate(rotationAngle)

    if (images.length === 0) return
    ctx.drawImage(
      images[0],
      -centralImageWidth / 2,
      -centralImageHeight / 2,
      centralImageWidth,
      centralImageHeight,
    )
    const { origin, rotation, scale } = getTransformProperties(ctx)
    ctx.restore()

    jsonCoordinates.current.entries.push({
      id: selectedMotifs[0].id,
      x: origin.x,
      y: origin.y,
      size: centralImageWidth * scale,
      rotation: rotation,
    })

    const OUTER_MOTIFS = { size: canvas.offsetWidth * 0.15, Y: 0 }
    const MIDDLE_MOTIFS = {
      size: canvas.offsetWidth * 0.12,
      Y: OUTER_MOTIFS.size + AROUND_MOTIF_SPACING,
    }
    const INNER_MOTIFS = {
      size: canvas.offsetWidth * 0.086,
      Y: OUTER_MOTIFS.size + MIDDLE_MOTIFS.size + 2 * AROUND_MOTIF_SPACING,
    }

    switch (selectedMotifs.length) {
      case 2:
        jsonCoordinates.current.entries.push(
          ...initializeAroundMotifs(
            OUTER_MOTIFS.size,
            OUTER_MOTIFS.Y,
            rotationAngle * 2,
            selectedMotifs[1].id,
            images[1] || '',
            ctx,
            canvas,
            AMOUNT_OF_MOTIFS_AROUND,
          ),
        )
        break
      case 3:
        jsonCoordinates.current.entries.push(
          ...initializeAroundMotifs(
            OUTER_MOTIFS.size,
            OUTER_MOTIFS.Y,
            rotationAngle * 2,
            selectedMotifs[2].id,
            images[2],
            ctx,
            canvas,
            AMOUNT_OF_MOTIFS_AROUND,
          ),
        )
        jsonCoordinates.current.entries.push(
          ...initializeAroundMotifs(
            MIDDLE_MOTIFS.size,
            MIDDLE_MOTIFS.Y,
            rotationAngle * 2,
            selectedMotifs[1].id,
            images[1],
            ctx,
            canvas,
            AMOUNT_OF_MOTIFS_AROUND,
          ),
        )
        break
      case 4:
        jsonCoordinates.current.entries.push(
          ...initializeAroundMotifs(
            OUTER_MOTIFS.size,
            OUTER_MOTIFS.Y,
            rotationAngle * 2,
            selectedMotifs[3].id,
            images[3],
            ctx,
            canvas,
            AMOUNT_OF_MOTIFS_AROUND,
          ),
        )
        jsonCoordinates.current.entries.push(
          ...initializeAroundMotifs(
            MIDDLE_MOTIFS.size,
            MIDDLE_MOTIFS.Y,
            rotationAngle * 2,
            selectedMotifs[2].id,
            images[2],
            ctx,
            canvas,
            AMOUNT_OF_MOTIFS_AROUND,
          ),
        )
        jsonCoordinates.current.entries.push(
          ...initializeAroundMotifs(
            INNER_MOTIFS.size,
            INNER_MOTIFS.Y,
            rotationAngle * 2,
            selectedMotifs[1].id,
            images[1],
            ctx,
            canvas,
            AMOUNT_OF_MOTIFS_AROUND,
          ),
        )
        break
      default:
        jsonCoordinates.current.entries.push(
          ...initializeAroundMotifs(
            OUTER_MOTIFS.size,
            OUTER_MOTIFS.Y,
            rotationAngle * 2,
            selectedMotifs[0].id,
            images[0],
            ctx,
            canvas,
            AMOUNT_OF_MOTIFS_AROUND,
          ),
        )
    }

    jsonCoordinates.current.clipPlane = undefined
  }

  useEffect(() => {
    if (!firstTimeGenerated) return
    setCanvasDataUrl(canvasRefs.current[0].toDataURL())
  }, [firstTimeGenerated])

  const handleMouseDown = (event: React.MouseEvent) => {
    event.preventDefault()
    onStartEvent(event.pageX, event.pageY)
  }

  const handleTouchStart = (event: React.TouchEvent) => {
    onStartEvent(event.touches[0].pageX, event.touches[0].pageY)
  }

  const onStartEvent = (x: number, y: number) => {
    lastPos.current.x = x
    lastPos.current.y = y
    dragging.current = true
  }

  const handleMouseUp = (event: React.MouseEvent | React.TouchEvent) => {
    event.preventDefault()
    dragging.current = false
  }

  const handleMouseMove = (event: React.MouseEvent | React.TouchEvent): void => {
    if (dragging.current) {
      const pageXY = getPagePositionXY(event)
      const moveX = lastPos.current.x - pageXY.x
      lastPos.current = pageXY
      setRotationAngle(rotationAngle + moveX / 100)
    }
  }

  const { callback } = useThrottleFn(handleMouseMove, 40)

  return (
    <div
      style={{
        position: 'fixed',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Container
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseMove={callback}
        onMouseUp={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchMove={callback}
        onTouchEnd={handleMouseUp}
      >
        {[...Array(TILE_NUMBER)].map((_, i) => {
          return (
            <div className="tile" key={i}>
              <canvas id="canvas-{i}" ref={canvas => (canvasRefs.current[i] = canvas!)}></canvas>;
            </div>
          )
        })}
        {exportInvoked ? (
          <div className="tile tile tile-to-export">
            <canvas
              width={EXPORT_SIZE / RESOLUTION_MULTIPLIER}
              height={EXPORT_SIZE / RESOLUTION_MULTIPLIER}
              ref={canvasToExportRef}
            ></canvas>
          </div>
        ) : null}
      </Container>
    </div>
  )
}

export default CircleLayoutGeneratorCanvas

export enum AnimationState {
  UNSET = 'unset',
  PLAY = 'play',
  PAUSE = 'pause',
}

export enum InfoSnackbarType {
  KALEIDOSCOPE = 'KALEIDOSCOPE',
  PENROSE = 'PENROSE',
}

import styled from 'styled-components'
import { GeneratorControlWrapper } from './common'
import PatternButtons from './PatternButtons'
import { useContextUI } from '../../contexts/UIContext'
import { useEffect, useState } from 'react'
import { UIElement } from '../../types/UIElements'
import { useEnv } from '../../utils/useEnv'
import GeneratorTooltip from '../GeneratorTooltip'

const PatternButtonListWrapper = styled.div`
  width: 110px;
  position: fixed;
  top: 50%;
  right: 0;
  height: 200px;
  transform: translate(100%, -50%);
  transition: 0.2s ease-out;

  &.open {
    transform: translate(0, -50%);
  }

  span {
    color: ${({ theme }) => theme.colors.black70};
    font-family: ${({ theme }) => theme.font.regular};
    font-size: 15px;
    letter-spacing: 0.73px;
    line-height: 24px;
  }

  @media (min-height: ${({ theme }) => theme.breakpoints.deviceH.xxs}) {
    width: 130px;
    height: 240px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    // TODO: Use the uncommented value if the freehand generator is done
    /* width: 220px; */
    width: 140px;
    height: 260px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.md}) {
    // TODO: Use the uncommented value if the freehand generator is done
    /* width: 260px; */
    width: 175px;
    height: 320px;

    span {
      font-size: 17px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xl}) {
    // TODO: Use the uncommented value if the freehand generator is done
    /* width: 320px; */
    width: 220px;
    height: 415px;

    span {
      font-size: 19px;
    }
  }
`

const PatternButtonListControl = () => {
  const { elementsOpen } = useContextUI()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { isKiosk } = useEnv()

  useEffect(() => {
    if (elementsOpen.length === 0 || elementsOpen.includes(UIElement.CONTROLS)) {
      setIsOpen(true)
    } else if (
      isKiosk &&
      isOpen &&
      !elementsOpen.includes(UIElement.SIDEMENU) &&
      !elementsOpen.includes(UIElement.EXPLANATION)
    ) {
      setIsOpen(false)
    }
  }, [elementsOpen])

  return (
    <PatternButtonListWrapper className={isOpen ? 'open' : ''}>
      <GeneratorTooltip />
      <GeneratorControlWrapper>
        <PatternButtons />
      </GeneratorControlWrapper>
    </PatternButtonListWrapper>
  )
}

export default PatternButtonListControl

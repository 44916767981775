import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useContextMotifDetails } from '../../contexts/motifDetailsContext'
import { Motif } from '../../types/motifs'
import { useStrapiTranslation } from '../../utils/useStrapiTranslation'
import MotifSelector from './MotifSelector'
import { isMobile } from 'react-device-detect'

const Container = styled.div`
  min-height: 350px;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  font-size: 12px;
  display: flex;
  align-items: center;
  border-radius: 24px;
  padding: 18px 34px 16px 20px;

  .wrapper {
    height: 100%;
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-template-rows: 200px;
    opacity: 0;
    transform: translateY(30px);
    gap: 21px;

    &.fade-in {
      animation: fade-in-y30 0.3s 0.3s ease forwards;
    }

    &.fade-out {
      animation: fade-out-y30 0.3s ease forwards;
    }

    .vector-wrapper {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-row: 1fr 100px;
      align-items: center;
      grid-row: 1 / 1;
      grid-column: 1 / 1;

      img {
        grid-row: 1 / 1;
        grid-column: 1 / 1;
        align-self: start;
        max-width: 100%;
      }
    }

    .data-wrapper {
      height: 100%;
      grid-column: 2 / 2;
      grid-row: 1 / span 2;

      .data {
        overflow-x: visible;
        margin: 22px 0 10px 0;

        h1 {
          font-size: 20px;
          line-height: 28px;
          font-family: ${({ theme }) => theme.font.semiBold};
          color: ${({ theme }) => theme.colors.black87};
          margin: 15px 0 10px 0;
        }

        h2 {
          font-size: 16px;
          line-height: 1.3;
          font-family: ${({ theme }) => theme.font.medium};
          color: ${({ theme }) => theme.colors.black49};
          letter-spacing: 1.08px;
          margin: 10px 0;
        }

        p {
          line-height: 1.3;
          font-size: 16px;
          font-family: ${({ theme }) => theme.font.regular};
          color: ${({ theme }) => theme.colors.black70};
          letter-spacing: 0.62px;
          margin: 0;
        }
      }

      .description {
        white-space: pre-wrap;
        font-size: 16px;
        color: ${({ theme }) => theme.colors.black49};
        line-height: 1.3;
        letter-spacing: 0.5px;
      }
    }

    .photo {
      grid-column: 1 / 1;
      grid-row: 2 / 2;
      place-self: start center;
      max-width: 100%;
      max-height: 100%;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    font-size: 14px;
  }

  @media (min-height: ${({ theme }) => theme.breakpoints.deviceH.sm}) {
    font-size: 12px;

    .wrapper {
      grid-template-columns: 400px 450px 400px;
      grid-template-rows: 1fr;
      grid-row-gap: 0px;

      .photo {
        grid-column: 3 / 3;
        grid-row: 1 / 1;
        place-self: center center;
      }

      h1 {
        margin: 15px 0;
      }
    }
  }

  @media (min-height: ${({ theme }) => theme.breakpoints.deviceH.md}) {
    font-size: 16px;
    padding: 100px 50px;
    width: unset;

    .wrapper {
      height: 550px;
      gap: 30px;
      grid-template-columns: 450px 500px 400px;
      grid-template-rows: 1fr;

      .vector-wrapper {
        width: 400px;
        height: 100%;
        margin-top: 13%;

        img {
          max-width: 100%;
        }
      }

      .data-wrapper {
        width: 500px;
        grid-row: 1 / 1;

        .data {
          margin: 0 0 10px 0;

          h1 {
            font-size: 2em;
          }

          h2 {
            font-size: 1.625em;
          }

          h1,
          h2 {
            margin: 15px 0;
          }
        }

        .description {
          font-size: 1.25em;
          margin-right: 10%;
        }
      }

      .photo {
        grid-column: 3 / 3;
        grid-row: 1 / 1;
        place-self: center center;
      }
    }
  }

  @media (min-height: ${({ theme }) => theme.breakpoints.deviceH.lg}) {
    .wrapper {
      height: 650px;

      .vector-wrapper {
        width: 450px;
      }
    }
  }
`

interface Props {
  motif: Motif
}

const MotifDetailsItem = ({ motif }: Props) => {
  const { motifDetailsFadeOut } = useContextMotifDetails()
  const { t } = useTranslation()
  const { strapiTranslate } = useStrapiTranslation()

  return (
    <Container>
      <div className={`wrapper ${motifDetailsFadeOut ? 'fade-out' : 'fade-in'}`}>
        <div className="vector-wrapper">
          {motif.vectorizedUrl && <img src={motif.vectorizedUrl} alt={motif.title} />}
          {isMobile && window.innerHeight < 600 ? null : <MotifSelector motif={motif} />}
        </div>
        <div className="data-wrapper">
          <div className="data">
            {isMobile && window.innerHeight < 600 ? <MotifSelector motif={motif} /> : null}
            <h1>{strapiTranslate(motif, 'title')}</h1>
            <h2>{strapiTranslate(motif, 'subtitle')}</h2>
            <p>
              {strapiTranslate(motif, 'year')}
              {motif.year && (motif.country || motif.region || motif.settlement) ? ' - ' : ''}
              {strapiTranslate(motif, 'country')}
              {motif.country && motif.region ? ', ' : ''}
              {strapiTranslate(motif, 'region')}
              {(motif.region && motif.settlement) ||
              (motif.country && motif.settlement && !motif.region)
                ? ', '
                : ''}
              {strapiTranslate(motif, 'settlement')}
            </p>
            <p>
              {t('inventoryNumber')}: {motif.inventoryNumber}
            </p>
          </div>
          <p className="description">{strapiTranslate(motif, 'description')}</p>
        </div>
        {motif.photoUrl && <img className="photo" src={motif.photoUrl} alt={motif.title} />}
      </div>
    </Container>
  )
}

export default MotifDetailsItem

import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

export const useStrapiTranslation = () => {
  const { i18n } = useTranslation()

  function strapiTranslate<T>(obj: T, key: string): ReactNode {
    let langKey = key
    if (i18n.language === 'en') {
      langKey = 'EN_' + langKey
    }
    return obj[langKey as keyof T]
  }

  return { strapiTranslate }
}

import { isMobile, isDesktop } from 'react-device-detect'
import { Dispatch, SetStateAction } from 'react'

export default function screenSizeObserver(
  windowWidth: number,
  windowHeight: number,
  showErrorMessage: (errorMessageText: string, isRealoadOnError: boolean) => void,
  hideErrorMessage: () => void,
  setRotateDeviceNotificationVisible: Dispatch<SetStateAction<boolean>>,
) {
  const maxHeight = 550
  const maxWidth = 1000
  const portraitMode = windowHeight > windowWidth

  const errorCondition = windowWidth <= maxWidth || windowHeight <= maxHeight
  const reversedCapabilityCondition = windowHeight > maxWidth && windowWidth > maxHeight

  if (isMobile && portraitMode && errorCondition && reversedCapabilityCondition) {
    showErrorMessage('resolutionErrorMessage', false)
  } else if (isMobile && errorCondition && portraitMode) {
    setRotateDeviceNotificationVisible(true)
  } else if (isDesktop && errorCondition) {
    showErrorMessage('windowSizeErrorMessage', false)
  } else {
    hideErrorMessage()
    setRotateDeviceNotificationVisible(false)
  }
}

import { isMobile } from 'react-device-detect'
import { Motif } from '../../../types/motifs'
import { MotifMapEntry } from '../../MotifMap/generate'

export interface Circle {
  idx: number
  url: string
  x: number
  y: number
  r: number
  rotation: number
}

const sizes = [
  16, 21, 21, 23, 25, 25, 25, 26, 27, 29, 29, 30, 30, 31, 31, 31, 34, 34, 37, 37, 37, 38, 39, 39,
  40, 42, 42, 43, 44, 45, 52, 54, 56, 58, 61, 62, 64, 63, 62, 60, 99,
]

const mobilSizes = [
  16, 20, 20, 21, 22, 22, 23, 24, 25, 26, 26, 27, 27, 28, 28, 28, 29, 29, 30, 30, 30, 21, 22, 22,
  23, 23, 24, 24, 25, 25, 26, 26, 27, 28, 29, 30, 31, 32, 31, 30, 29,
]

const IS_PHONE = isMobile && window.innerWidth < 1000 && window.innerHeight < 700
const MOTIF_SIZE_MULTIPLIER = 0.9
const MOTIF_DENSITY = IS_PHONE ? 1.25 : 2 // >= 1.0, the smaller, the denser

export const generateMapProps = (motifs: Motif[], exportSize: number): Circle[] => {
  const width = window.innerWidth < exportSize ? exportSize : window.innerWidth
  const height = window.innerHeight < exportSize ? exportSize : window.innerHeight
  // generate map

  const sizesToUse = IS_PHONE ? mobilSizes : sizes
  const radii = Array.from(
    { length: Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2)) / 16 }, // IMPORTANT / Number of total motifs on the map
    () => sizesToUse[Math.floor(Math.random() * sizesToUse.length)] * MOTIF_SIZE_MULTIPLIER, // change multiplier to generate bigger or smaller circles
  )

  let selectedMotifs = motifs.filter(motif => typeof motif.vectorizedUrl === 'string')

  const entries = selectedMotifs.map(motif => {
    return {
      src: motif.vectorizedUrl!,
      srcXs: motif.vectorizedUrlXs!,
      id: motif.id,
    }
  })

  const radiusSqAverage = radii.reduce((p, c) => p + c * c, 0) / radii.length
  const area = radii.length * radiusSqAverage * Math.PI * MOTIF_DENSITY
  const ratio = width / height
  const MAP_HEIGHT = Math.sqrt(area / ratio)
  const MAP_WIDTH = ratio * MAP_HEIGHT

  // generate circles
  let circles = drawCircles(radii, entries, MAP_WIDTH, MAP_HEIGHT)

  const { minX, maxX, minY, maxY } = circles.reduce(
    (p, c) =>
      Object.assign({
        minX: Math.min(p.minX, c.x),
        maxX: Math.max(p.maxX, c.x),
        minY: Math.min(p.minY, c.y),
        maxY: Math.max(p.maxY, c.y),
      }),
    { minX: Infinity, maxX: -Infinity, minY: Infinity, maxY: -Infinity },
  )
  const [actualWidth, actualHeight] = [maxX - minX, maxY - minY]

  circles = circles.map(c =>
    Object.assign(c, {
      x: ((c.x - minX) / actualWidth) * width,
      y: ((c.y - minY) / actualHeight) * height,
      r: (c.r / actualWidth) * width,
    }),
  )

  return circles
}

function createGrid(columns: number, rows: number, size: number, width: number, height: number) {
  const grid = []
  let y = size / 2.0

  for (let row = 0; row < rows; row++) {
    const distanceFromTop = y
    const distanceFromBottom = height - y
    for (let col = 0; col < columns; col++) {
      const i = row * columns + col
      grid[i] = distanceFromTop < distanceFromBottom ? distanceFromTop : distanceFromBottom
    }
    y += size
  }

  let x = size / 2.0

  for (let col = 0; col < columns; col++) {
    let distanceFromLeft = x
    let distanceFromRight = width - x
    for (let row = 0; row < rows; row++) {
      let i = row * columns + col
      if (grid[i] > distanceFromLeft) {
        grid[i] = distanceFromLeft
      }
      if (grid[i] > distanceFromRight) {
        grid[i] = distanceFromRight
      }
    }
    x += size
  }

  return grid
}

function drawCircles(
  circles: number[],
  motifs: MotifMapEntry[],
  width: number,
  height: number,
): Circle[] {
  const size = Math.sqrt(Math.sqrt(width * height))
  const columns = Math.ceil(width / size)
  const rows = Math.ceil(height / size)
  const grid = createGrid(columns, rows, size, width, height)
  const circleArray = []

  circles = circles.sort().reverse()
  for (let circle = 0; circle < circles.length; circle++) {
    const radius = circles[circle]

    // Find gridpoint with largest distance from anything
    let i = 0
    let maxR = 0
    let maxC = 0
    let maxDist = grid[0]

    for (let row = 0; row < rows; row++) {
      for (let col = 0; col < columns; col++) {
        if (maxDist < grid[i]) {
          maxR = row
          maxC = col
          maxDist = grid[i]
        }
        i++
      }
    }

    let x = size / 2.0 + maxC * size
    let y = size / 2.0 + maxR * size

    let offset = (maxDist - radius) / 100.0
    x += 0.05 * Math.random() * offset
    y += 0.05 * Math.random() * offset

    // Update Distance array with new circle;
    i = 0
    let yy = size / 2.0

    for (let r = 0; r < rows; r++) {
      let xx = size / 2.0

      for (let c = 0; c < columns; c++) {
        let d2 = (xx - x) * (xx - x) + (yy - y) * (yy - y)
        let prev2 = grid[i] + radius
        prev2 *= prev2

        if (prev2 > d2) {
          let d = Math.sqrt(d2) - radius
          if (grid[i] > d) {
            grid[i] = d
          }
        }
        xx += size
        i++
      }

      yy += size
    }

    const idx = Math.floor(Math.random() * motifs.length)
    const motif = motifs[idx]

    circleArray.push({
      idx,
      x,
      y,
      r: radius,
      url: motif.src,
      rotation: Math.random() * 2 * Math.PI,
    })
  }

  return circleArray
}

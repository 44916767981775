import styled from 'styled-components'
import { useEffect, useState, Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import SVG from 'react-inlinesvg'
import { useContextMotifGenerator } from '../../../contexts/motifGeneratorContext'

const ANIMATION_DURATION = 0.5

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.black70};
  animation: simple-fade-in ${ANIMATION_DURATION}s forwards;
  z-index: 1001;

  &.hide {
    animation: simple-fade-out ${ANIMATION_DURATION}s forwards;
  }
`

const Modal = styled.div`
  font-size: 21px;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 12px;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  animation: fade-in-y30-center ${ANIMATION_DURATION}s forwards;

  &.hide {
    animation: fade-out-y30-center ${ANIMATION_DURATION}s forwards;
  }

  p {
    text-align: center;
    margin-top: 0;
  }

  .shapes {
    display: flex;
    justify-content: space-around;

    div {
      padding: 20px 20px 0 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }

    button {
      position: relative;
      border: none;
      width: 80px;
      height: 80px;
      background: ${({ theme }) => theme.colors.red};
      margin-top: 5px;

      svg {
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 45%;
        height: 45%;
      }
    }

    .circle {
      button {
        border-radius: 50%;
      }
    }
  }
`

export enum ExportLayoutModalResponse {
  CANCEL = 'CANCEL',
  SQUARE = 'SQUARE',
  CIRCLE = 'CIRCLE',
}

type Props = {
  responseHandlerFn: (response: ExportLayoutModalResponse) => void
  setExportInvoked: Dispatch<SetStateAction<boolean>>
}

const ExportLayoutModal = ({ responseHandlerFn, setExportInvoked }: Props) => {
  const [shouldHide, setShouldHide] = useState(false)
  const { setLoading } = useContextMotifGenerator()
  const { t } = useTranslation()

  useEffect(() => {
    if (!shouldHide) return
    setTimeout(() => {
      setExportInvoked(false)
    }, ANIMATION_DURATION * 1000)
  }, [shouldHide])

  const hide = async () => {
    setLoading(false)
    setShouldHide(true)
  }

  return (
    <Container className={shouldHide ? 'hide' : ''} onClick={() => hide()}>
      <Modal className={shouldHide ? 'hide' : ''}>
        <p>{t('freehandGeneratorDownloadTitle')}</p>
        <div className="shapes">
          <div>
            {t('freehandGeneratorDownloadShapeSquare')}
            <button
              type="button"
              onClick={() => responseHandlerFn(ExportLayoutModalResponse.SQUARE)}
            >
              <SVG src="/icons/download.svg" className="svg-download" />
            </button>
          </div>
          <div className="circle">
            {t('freehandGeneratorDownloadShapeCircle')}
            <button
              type="button"
              onClick={() => responseHandlerFn(ExportLayoutModalResponse.CIRCLE)}
            >
              <SVG src="/icons/download.svg" className="svg-download" />
            </button>
          </div>
        </div>
      </Modal>
    </Container>
  )
}

export default ExportLayoutModal

import React from 'react'
import SVG from 'react-inlinesvg'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { Motif } from '../../types/motifs'
import { GeneratorControlButton } from './common'
import routePaths from '../../constants/routePaths'

const PlusOne = styled.span`
  font-size: 19px;
  font-family: ${({ theme }) => theme.font.light};
  color: ${({ theme }) => theme.colors.black70};

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.md}) {
    font-size: 26px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xl}) {
    font-size: 36px;
  }
`

const MotifImg = styled.div<{ $imageUrl: string }>`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.$imageUrl});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90%;
`

export const RemoveIcon = styled.div`
  width: 26px;
  height: 26px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.red};
  border: 1px solid ${({ theme }) => theme.colors.borderGrey};
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: -8px;
  right: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    position: absolute;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xxl}) {
    width: 32px;
    height: 32px;
    top: -12px;
    right: -12px;
  }
`

interface Props {
  useSpacing?: boolean
  isEmpty: boolean
  isPlusOne: boolean
  index: number
  motif?: Motif
  onRemove: (id: number) => void
}

const MotifListItem = ({ motif, isEmpty, isPlusOne, onRemove, useSpacing }: Props) => {
  const navigate = useNavigate()

  const handleClick = () => {
    if (isEmpty) return
    if (isPlusOne) {
      navigate(routePaths.HOME)
      return
    }
  }

  return (
    <GeneratorControlButton $empty={isEmpty} $useSpacing={useSpacing} onClick={handleClick}>
      {motif && <MotifImg $imageUrl={motif.vectorizedUrlXs || motif.vectorizedUrl || ''} />}
      {isPlusOne && <PlusOne>+1</PlusOne>}
      {motif && (
        <RemoveIcon onClick={() => onRemove(motif.id)}>
          <SVG src="icons/x.svg" width={12} height={12} />
        </RemoveIcon>
      )}
    </GeneratorControlButton>
  )
}

export default MotifListItem

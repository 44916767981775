import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useContextScreensaver } from '../contexts/screensaverContext'
import { useContextUI } from '../contexts/UIContext'
import { useEnv } from './useEnv'
import { UIElement } from '../types/UIElements'

const SHOW_NUMBER_OF_TIMES = 3
const ANIMATION_DURATION_TIME_IN_SECONDS = 5

interface Props {
  callback: (shouldDisplay: boolean) => void
}

export const useInteractionMotivatorTimer = ({ callback }: Props) => {
  const callbackRef = useRef(callback)
  const shownNumberOfTimes = useRef(0)
  const timer = useRef<NodeJS.Timeout>()
  const { elementsOpen } = useContextUI()
  const { isKiosk } = useEnv()
  const { isScreensaverOn } = useContextScreensaver()
  const [firstTimeShown, setFirstTimeShown] = useState<boolean>(true)
  const shouldStart = useRef(true)
  const location = useLocation()
  const IDLE_TIME_IN_SECONDS = isKiosk ? 10 : 20
  const activityEvents = [
    'click',
    'mousedown',
    'touchmove',
    'touchstart',
    'touchend',
    'drag',
    'dragstart',
    'dragend',
  ]

  useEffect(() => {
    return () => {
      activityEvents.forEach(event => {
        window.removeEventListener(event, interactionTimer)
      })
    }
  }, [])

  useEffect(() => {
    if (
      elementsOpen.length === 1 &&
      elementsOpen.includes(UIElement.CONTROLS) &&
      location.pathname === '/' &&
      shouldStart.current
    ) {
      startIdleTimer()
    }
  }, [elementsOpen, location, shouldStart.current])

  useEffect(() => {
    shouldStart.current = true
    if (isScreensaverOn) {
      callbackRef.current(false)
      clearTimer()
      startIdleTimer()
    } else {
      shownNumberOfTimes.current = 0
    }
  }, [isScreensaverOn])

  useEffect(() => {
    shouldStart.current = true
  }, [location])

  const startIdleTimer = () => {
    setTimeout(
      () => {
        activityEvents.forEach(event => {
          window.addEventListener(event, interactionTimer)
        })
      },
      firstTimeShown ? 0 : 1000,
    )

    timer.current = setTimeout(
      () => {
        if (!isScreensaverOn && shownNumberOfTimes.current >= SHOW_NUMBER_OF_TIMES) return
        callbackRef.current(true)
        clearTimer()
        startAnimationTimer()

        if (firstTimeShown) {
          setFirstTimeShown(false)
        }
      },
      shownNumberOfTimes.current === 0 && firstTimeShown ? 1000 : IDLE_TIME_IN_SECONDS * 1000,
    )
  }

  const startAnimationTimer = () => {
    timer.current = setTimeout(() => {
      callbackRef.current(false)
      clearTimer()
      shownNumberOfTimes.current++
      startIdleTimer()
    }, ANIMATION_DURATION_TIME_IN_SECONDS * 1000)
  }

  const clearTimer = () => {
    timer.current && clearTimeout(timer.current)
    timer.current = undefined
  }

  const interactionTimer = () => {
    shouldStart.current = false
    activityEvents.forEach(event => {
      window.removeEventListener(event, interactionTimer)
    })
    clearTimer()
    callbackRef.current(false)
  }
}

import { useContext, ReactNode, createContext, useState, Dispatch, SetStateAction } from 'react'
import { Motif } from '../types/motifs'

interface ContextType {
  selectedMotifs: Motif[]
  setSelectedMotifs: Dispatch<SetStateAction<Motif[]>>
  numberOfSelectedMotifs: number
  setNumberOfSelectedMotifs: Dispatch<SetStateAction<number>>
  isSelected: (id: number) => boolean
  selectMotif: (motif: Motif) => void
  deselectMotif: (id: number) => void
}

interface Props {
  children: ReactNode
}

const Context = createContext({} as ContextType)

const SelectedMotifsContext = ({ children }: Props) => {
  const [selectedMotifs, setSelectedMotifs] = useState<Motif[]>([])
  const [numberOfSelectedMotifs, setNumberOfSelectedMotifs] = useState<number>(0)
  const isSelected = (id: number): boolean => {
    return !!selectedMotifs.find(motif => motif.id === id)
  }

  const selectMotif = (motif: Motif) => {
    if (selectedMotifs.length === 4) throw new Error('Cannot select any more motifs')
    const newMotifs = [...selectedMotifs, motif]
    setSelectedMotifs(newMotifs)
    setNumberOfSelectedMotifs(newMotifs.length)
  }

  const deselectMotif = (id: number) => {
    const motifIndex = selectedMotifs.findIndex(m => m.id === id)
    if (motifIndex < 0) throw new Error('Motif was not selected')
    const newMotifs = [...selectedMotifs]
    newMotifs.splice(motifIndex, 1)
    setSelectedMotifs(newMotifs)
    setNumberOfSelectedMotifs(newMotifs.length)
  }

  return (
    <Context.Provider
      value={{
        selectedMotifs,
        setSelectedMotifs,
        numberOfSelectedMotifs,
        setNumberOfSelectedMotifs,
        isSelected,
        selectMotif,
        deselectMotif,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useContextSelectedMotifs = () => {
  return useContext(Context)
}

export default SelectedMotifsContext

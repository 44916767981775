import SVG from 'react-inlinesvg'
import styled from 'styled-components'
import { useEnv } from '../../utils/useEnv'
import { useContextScreensaver } from '../../contexts/screensaverContext'

const BrandLogoButton = styled.a`
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 9px 12px;
  background-color: ${({ theme }) => theme.colors.white};
  text-align: center;
  font-family: ${({ theme }) => theme.font.medium};
  border: none;
  border-top-left-radius: 20px;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.2);
  z-index: 2;
  display: flex;

  .brand-logo {
    height: 20px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.md}) {
    border-top-left-radius: 24px;
    padding: 10px 15px;

    .brand-logo {
      height: 25px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xl}) {
    border-top-left-radius: 39px;
    padding: 17.5px 22.5px;

    .brand-logo {
      height: 35px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xxl}) {
    border-radius: 17px;
    right: 3vw;
    bottom: 6vh;
    width: 220px;
  }
`

const BrandLogo = () => {
  const { isWeb } = useEnv()
  const { setIsScreensaverOn } = useContextScreensaver()

  return isWeb ? (
    <BrandLogoButton
      onClick={() => setIsScreensaverOn(false)}
      href="https://www.neprajz.hu/"
      target="_blank"
    >
      <SVG className="brand-logo" src="/images/brandlogo.svg" />
    </BrandLogoButton>
  ) : null
}

export default BrandLogo

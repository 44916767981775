export const useStorage = () => {
  function getStored<T>(key: string): T | null {
    const storedString = localStorage.getItem(key)
    if (!storedString) return null
    return JSON.parse(storedString)
  }

  function getStoredString(key: string): string | null {
    return localStorage.getItem(key)
  }

  function store<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value))
  }

  function storeString(key: string, value: string): void {
    localStorage.setItem(key, value)
  }

  function removeStored(key: string): void {
    localStorage.removeItem(key)
  }

  return {
    getStored,
    getStoredString,
    store,
    storeString,
    removeStored,
  }
}

import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { useTranslation } from 'react-i18next'
import routePaths from '../../constants/routePaths'
import { useContextUI } from '../../contexts/UIContext'
import { useEffect } from 'react'

const ErrorMessageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;

  .message-container {
    width: 200px;
    text-align: center;
    white-space: pre-wrap;
    padding: 10px;
  }

  .refresh-button {
    width: 180px;
    height: 36px;
    border: none;
    border-radius: 18px;
    background-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};
    padding: 0;
  }

  span {
    font-size: 16px;
    letter-spacing: 1.06px;
    font-family: ${({ theme }) => theme.font.medium};
  }

  p {
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 28px;
    font-family: ${({ theme }) => theme.font.light};
    margin: 10px 0;
  }

  .error-guy {
    position: relative;
    left: 15px;
    height: 100px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.lg}) {
    .message-container {
      width: 260px;
    }

    .refresh-button {
      width: 220px;
      height: 44px;
      border-radius: 22px;
    }

    span {
      font-size: 22px;
    }

    p {
      font-size: 22px;
      line-height: 34px;
      margin: 15px 0;
    }

    .error-guy {
      height: 224px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    .message-container {
      width: 220px;
    }

    .refresh-button {
      width: 177px;
      height: 36px;
    }

    span {
      font-size: 18px;
    }

    p {
      font-size: 16px;
      line-height: 22px;
    }

    .error-guy {
      height: 134px;
    }
  }
`

const ErrorMessage = () => {
  const { t } = useTranslation()
  const { isReloadOnError, isErrorMessageVisible, errorMessageText } = useContextUI()

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    if (isReloadOnError) {
      setTimeout(() => {
        window.location.replace(routePaths.HOME)
      }, 2 * 1000 * 60)
    }

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [isReloadOnError])

  return isErrorMessageVisible ? (
    <ErrorMessageWrapper>
      <div className="message-container">
        <SVG className="error-guy" src="/icons/error-guy.svg" />
        <p>{t(errorMessageText)}</p>
        {isReloadOnError ? (
          <button
            className="refresh-button"
            onClick={() => window.location.replace(routePaths.HOME)}
          >
            <span>{t('tryAgain')}</span>
          </button>
        ) : null}
      </div>
    </ErrorMessageWrapper>
  ) : null
}

export default ErrorMessage

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useContextScreensaver } from '../../contexts/screensaverContext'

const LanguageChangerButton = styled.button`
  font-size: 9px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 65px;
  height: 38px;
  background-color: ${({ theme }) => theme.colors.white};
  text-align: center;
  font-family: ${({ theme }) => theme.font.medium};
  text-transform: uppercase;
  letter-spacing: 0.65px;
  line-height: 24px;
  border: none;
  border-top-right-radius: 20px;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.2);
  padding: 0;
  z-index: 2;

  span {
    font-size: 1em;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.md}) {
    border-top-right-radius: 24px;
    font-size: 11px;
    width: 80px;
    height: 45px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xl}) {
    border-top-right-radius: 39px;
    font-size: 19px;
    width: 122px;
    height: 70px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xxl}) {
    border-radius: 17px;
    left: 3vw;
    bottom: 6vh;
  }
`

const LanguageChanger = () => {
  const { t, i18n } = useTranslation()
  const { setIsScreensaverOn } = useContextScreensaver()

  const changeLanguage = () => {
    setIsScreensaverOn(false)
    i18n.changeLanguage(i18n.language === 'hu' ? 'en' : 'hu')
  }

  return (
    <LanguageChangerButton onClick={changeLanguage}>
      <span>{t('changeLanguage')}</span>
    </LanguageChangerButton>
  )
}

export default LanguageChanger

import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { MotifGeneratorType } from '../components/MotifPatternGenerator/types'
import routePaths from '../constants/routePaths'
import { UIElement } from '../types/UIElements'
import { useScreensaverTimer } from '../utils/screensaverTimer'
import { useContextMotifDetails } from './motifDetailsContext'
import { useContextMotifGenerator } from './motifGeneratorContext'
import { useContextSelectedMotifs } from './selectedMotifContext'
import { useContextUI } from './UIContext'

interface ContextType {
  isScreensaverOn: boolean
  setIsScreensaverOn: Dispatch<SetStateAction<boolean>>
}

interface Props {
  children: ReactNode
}

const Context = createContext({} as ContextType)

const MAX_MOTIF_NUMBER = 4
const NUMBER_OF_MOTIFS = 1004 // The number of motifs + 1

const ScreensaverContext = ({ children }: Props) => {
  const [isScreensaverOn, setIsScreensaverOn] = useState<boolean>(false)
  const navigate = useNavigate()
  const { allMotifs } = useContextMotifDetails()
  const { selectedMotifs, selectMotif } = useContextSelectedMotifs()
  const { selectGeneratorType, setFreeLayoutGeneratorState } = useContextMotifGenerator()
  const { elementsOpen, filterOutUiElement } = useContextUI()

  const pickRandomMotif = () => {
    if (allMotifs && selectedMotifs.length < MAX_MOTIF_NUMBER) {
      const randomMotifIndex = Math.floor(Math.random() * NUMBER_OF_MOTIFS)
      const randomMotif = allMotifs[randomMotifIndex]
      if (randomMotif) {
        selectMotif(allMotifs[randomMotifIndex])
      }
    }
  }

  const pickRandomLayout = () => {
    const layouts = ['KALEIDO_SQUARE', 'KALEIDO_PENTA', 'KALEIDO_HEXA', 'KALEIDO_OCTA']
    let rand = Math.floor(Math.random() * layouts.length)
    let randomGeneratorTypeValue = layouts[rand] as MotifGeneratorType
    selectGeneratorType(randomGeneratorTypeValue)
  }

  const startScreensaver = useCallback(() => {
    setIsScreensaverOn(true)

    setFreeLayoutGeneratorState({ images: [], isMirrored: false })

    if (elementsOpen.includes(UIElement.ABOUT)) {
      filterOutUiElement(UIElement.ABOUT)
    }

    if (elementsOpen.includes(UIElement.GALLERY)) {
      filterOutUiElement(UIElement.GALLERY)
    }

    pickRandomMotif()

    navigate(routePaths.GENERATE)

    pickRandomLayout()
  }, [selectedMotifs, routePaths.GENERATE, navigate, selectGeneratorType])

  useEffect(() => {
    // set 4 random motifs if the screen saver animation started
    if (isScreensaverOn) {
      pickRandomMotif()
    }
  }, [selectedMotifs])

  useScreensaverTimer({
    callback: startScreensaver,
  })

  return (
    <Context.Provider value={{ isScreensaverOn, setIsScreensaverOn }}>{children}</Context.Provider>
  )
}

export const useContextScreensaver = () => {
  return useContext(Context)
}

export default ScreensaverContext

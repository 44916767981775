import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useContextUI } from '../../contexts/UIContext'
import { UIElement } from '../../types/UIElements'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'

const LegalNoticeCardWrapper = styled.div`
  position: absolute;
  top: 8vh;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`

const LegalNoticeCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 500px;
  height: 600px;
  background-color: #f9f9f9;
  border-radius: 17px;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.2);
  padding: 40px 60px;

  .title-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #000000;
    letter-spacing: 1.15px;
    line-height: 60px;
    font-family: ${({ theme }) => theme.font.light};
    font-size: 30px;
    line-height: 60px;
    height: 60px;

    .black-x-svg {
      cursor: pointer;
      width: 30px;
      height: 30px;
    }
  }

  .qrcode-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 190px;
    height: 190px;

    svg {
      height: 179px;
      width: 179px;
    }
  }

  .description {
    white-space: pre-wrap;
    color: #000000;
    font-family: ${({ theme }) => theme.font.light};
    font-size: 21px;
    letter-spacing: 0.91px;
    height: 153px;
    width: 378px;
    line-height: 36px;
  }
`

const LegalNotice = () => {
  const { t, i18n } = useTranslation()
  const { elementsOpen, filterOutUiElement } = useContextUI()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleClose = () => {
    filterOutUiElement(UIElement.LEGALNOTICE)
    setIsOpen(false)
  }

  useEffect(() => {
    if (elementsOpen.includes(UIElement.LEGALNOTICE)) {
      setIsOpen(true)
      if (elementsOpen.includes(UIElement.GALLERY)) {
        filterOutUiElement(UIElement.GALLERY)
      }
      if (elementsOpen.includes(UIElement.ABOUT)) {
        filterOutUiElement(UIElement.ABOUT)
      }
    } else if (isOpen) {
      setIsOpen(false)
    }
  }, [elementsOpen])

  return (
    <LegalNoticeCardWrapper>
      <LegalNoticeCard>
        <div className="title-row">
          <span>{t('museumLegalNotice')}</span>
          <SVG className="black-x-svg" src="/icons/x.svg" onClick={handleClose} />
        </div>
        <div className="qrcode-container">
          <SVG src={`/images/qrkod_${i18n.language}.svg`} />
        </div>
        <div className="description">{t('legalNoticeDescription')}</div>
      </LegalNoticeCard>
    </LegalNoticeCardWrapper>
  )
}

export default LegalNotice

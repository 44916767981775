import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useContextUI } from '../../../contexts/UIContext'
import { FeatureDiscoveryElement } from '../../../types/FeatureDiscoveryElement'

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  p {
    font-size: 0.875rem;
    user-select: none;
  }

  .motiflist-discovery {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translate(-50px, -50%);
    height: 250px;
    padding-left: 80px;
    background: ${({ theme }) => theme.colors.black};
    border-radius: 0 228px 228px 0;

    transition: all 0.6s;
    transition-delay: 0.6s;
    opacity: 0;

    img {
      margin-left: 0.5rem;
      width: 2rem;
    }

    p {
      width: 180px;
      padding: 0 1rem 0 0.5rem;
      color: ${({ theme }) => theme.colors.white};
    }
  }

  &.show {
    .motiflist-discovery {
      transform: translate(0, -50%);
      opacity: 1;
    }
  }

  @media (min-height: ${({ theme }) => theme.breakpoints.deviceH.xxs}) {
    .motiflist-discovery {
      height: 290px;
      padding-left: 90px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    p {
      font-size: 1.125rem;
    }

    .motiflist-discovery {
      height: 310px;

      img {
        margin-left: 1.875rem;
      }

      p {
        width: 220px;
        padding: 0 2rem 0 1.875rem;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.md}) {
    .motiflist-discovery {
      height: 370px;
      padding-left: 100px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.lg}) {
    p {
      font-size: 1.5rem;
    }

    .motiflist-discovery {
      p {
        width: 270px;
        padding: 0 2.25rem 0 2rem;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xl}) {
    .motiflist-discovery {
      height: 465px;
      padding-left: 120px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xxl}) {
    p {
      width: 300px;
      font-size: 1.625rem;
    }

    .motiflist-discovery {
      padding-left: calc(3vw + 130px);

      p {
        width: 300px;
      }
    }
  }
`

const FeatureDiscovery = () => {
  const { t } = useTranslation()
  const { featureDiscoveryElementsOpen } = useContextUI()

  return (
    <Container
      className={`${
        featureDiscoveryElementsOpen.includes(FeatureDiscoveryElement.FREE_LAYOUT) ? 'show' : ''
      }`}
    >
      <div className="motiflist-discovery">
        <img src={'/icons/download_icon.svg'} alt="arrow-svg" />
        <p>{t('featureDiscovery.freehandMotiflist')}</p>
      </div>
    </Container>
  )
}

export default FeatureDiscovery

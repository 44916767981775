import { useThrottleFn } from '@react-cmpt/use-throttle'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import { EXPORT_SIZE, useContextMotifGenerator } from '../../../contexts/motifGeneratorContext'
import { useContextSelectedMotifs } from '../../../contexts/selectedMotifContext'
import { PositionXY } from '../../../types/PositionXY'
import { getPagePositionXY } from '../../../utils/getPagePositionXY'
import CheckboxSwitch from '../../CheckboxSwitch'
import ExportLayoutModal, { ExportLayoutModalResponse } from './ExportLayoutModal'
import MaskCircle from './masks/MaskCircle'
import MaskSquare from './masks/MaskSquare'
import { toJpeg } from 'html-to-image'
import { useContextUI } from '../../../contexts/UIContext'
import { FeatureDiscoveryElement } from '../../../types/FeatureDiscoveryElement'
import { t } from 'i18next'

const DEFAULT_IMAGE_SIZE = 150
const MIN_IMAGE_SIZE = DEFAULT_IMAGE_SIZE / 2
const MAX_IMAGE_SIZE = DEFAULT_IMAGE_SIZE * 3

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  position: fixed;
  z-index: 0;

  canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    pointer-events: none;
  }

  .tile {
    background-color: ${({ theme }) => theme.colors.white};
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .image-area {
    position: absolute;
    width: 50%;
    height: 100%;
    z-index: 2;

    &.mirror-on {
      overflow: hidden;
      left: 0;

      &.mirrored {
        transform: scale(-1, 1);
        pointer-events: none;
        z-index: 1;
        left: 50%;

        .resizer,
        .rotator {
          display: none;
        }
      }
    }
  }

  .clip-layer {
    position: absolute;
    top: 0;
    left: 0;
    min-width: ${EXPORT_SIZE}px;
    width: 100%;
    min-height: ${EXPORT_SIZE}px;
    height: 100%;
    background: #fff;
    -webkit-mask-composite: destination-out;
    mask: url('images/mask-square.svg') 0 / calc(${EXPORT_SIZE - 10}px) auto,
      linear-gradient(#fff, #fff);
    mask-composite: exclude;
    mask-position: center;
    mask-repeat: no-repeat;
    z-index: 10;
    pointer-events: none;
    opacity: 0.6;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: ${EXPORT_SIZE}px;
      height: ${EXPORT_SIZE}px;

      rect {
        width: ${EXPORT_SIZE}px;
        height: ${EXPORT_SIZE}px;
      }
    }
  }

  .visible-layer {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 10;

    .clip-layer {
      transform-origin: top left;

      svg {
        border: 4px solid ${({ theme }) => theme.colors.red};
      }
    }

    .outer-image-container {
      transform-origin: top left;
    }
  }

  .clip-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: top left;
    border: 5px solid ${({ theme }) => theme.colors.red};
    border-radius: 50%;
    width: ${EXPORT_SIZE}px;
    height: ${EXPORT_SIZE}px;
    z-index: 1000;
    pointer-events: none;
  }

  .export-layer {
    background: #fff;
    position: absolute;
    width: ${EXPORT_SIZE}px;
    height: ${EXPORT_SIZE}px;
    left: 0;
    pointer-events: none;
    z-index: 5;

    .outer-image-container {
      width: 100% !important;
      height: 100% !important;
      transform: scale(1) !important;

      &.export-circle {
        border-radius: 50%;
        overflow: hidden;
      }
    }

    .clip-layer {
      display: none;
      width: 100% !important;
      height: 100% !important;
      transform: scale(1) !important;
    }

    .resizer,
    .rotator {
      display: none !important;
    }
  }
`

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;

  .inner-image-container {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    box-sizing: border-box;
    z-index: 20;
    transition: opacity 0.2s;

    .image {
      transform-origin: center;
      transition: transform 0.2s;
      transition-delay: 0.2s;
    }

    &.selected {
      .resizer {
        display: block;
        border-color: skyblue;
      }

      .rotator {
        display: block;
      }

      &.to-be-removed {
        opacity: 0.75;
        .image {
          transform: scale(0.8);
        }

        .resizer {
          opacity: 0;
        }

        .rotator {
          opacity: 0;
        }
      }

      &.remove {
        transition: transform 0.2s;

        .image {
          transform: scale(0);
        }
      }
    }
  }

  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .resizer {
    display: none;
    position: absolute;
    top: -3px;
    left: -3px;
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    border: 1px solid skyblue;
    transition: opacity 0.4s;

    .resizer__corner {
      position: absolute;
      width: 10px;
      height: 10px;
      border: 1px solid skyblue;
      cursor: nw-resize;
      background: #fff;

      &--top-left {
        top: -4px;
        left: -4px;
      }

      &--top-right {
        top: -4px;
        right: -4px;
        cursor: ne-resize;
      }

      &--bottom-right {
        bottom: -4px;
        right: -4px;
      }

      &--bottom-left {
        bottom: -4px;
        left: -4px;
        cursor: ne-resize;
      }
    }
  }

  .rotator {
    display: none;
    position: absolute;
    bottom: -3px;
    left: 50%;
    transform: translate(-0.5px, 100%);
    width: 1px;
    height: 16px;
    background: skyblue;
    transition: opacity 0.4s;

    .rotator__handler {
      position: absolute;
      top: 100%;
      left: 50%;
      width: 10px;
      height: 10px;
      margin-left: -5px;
      border: 1px solid skyblue;
      border-radius: 50%;
      cursor: url('icons/circle-arrow-icon.svg'), auto;
    }
  }
`

const MotifRemover = styled.div`
  position: fixed;
  bottom: calc(2vh + 5px);
  left: 50%;
  transform: translateX(-50%);
  background-color: ${({ theme }) => theme.colors.red};
  border: 2px solid ${({ theme }) => theme.colors.red};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 24px;
  padding: 6px 24px;
  user-select: none;
  z-index: 1100;

  pointer-events: none;
  opacity: 0;
  transition: all 0.2s;
  transition-delay: 0.4s;

  div {
    transition: transform 0.2s;
    transition-delay: 0.4s;
  }

  &.show {
    pointer-events: initial;
    opacity: 1;
    transition-delay: 0s;

    div {
      transition-delay: 0s;
    }
  }

  &.active {
    transform: translateX(-50%) scale(1.2);

    div {
      transform: scale(0.865);
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xs}) and (min-height: ${({
      theme,
    }) => theme.breakpoints.deviceH.sm}) {
    bottom: calc(18vh + 10px);
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    bottom: calc(9vh + 15px);
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xxl}) {
    bottom: calc(15vh + 20px);
  }
`

export type DraggableImage = {
  id: string
  vectorizedUrl: string | null
  position: PositionXY
  size: number
  selected: boolean
  rotation: number
}

const FreeLayoutGenerator = () => {
  const { featureDiscoveryElementsOpen, setFeatureDiscoveryElementsOpen } = useContextUI()
  const {
    freeGeneratorTouchEventPageXY,
    setFreeGeneratorTouchEventPageXY,
    selectedMotifForFreeGeneration,
    setSelectedMotifForFreeGeneration,
    freeLayoutGeneratorState,
    setFreeLayoutGeneratorState,
  } = useContextMotifGenerator()
  const { selectedMotifs } = useContextSelectedMotifs()
  const [images, setImages] = useState<DraggableImage[]>(
    freeLayoutGeneratorState?.images ? freeLayoutGeneratorState?.images : [],
  )
  const rotating = useRef<boolean>(false)
  const resizing = useRef<boolean>(false)
  const lastPos = useRef<PositionXY>({ x: 0, y: 0 })
  const dragStartPosXY = useRef<PositionXY | undefined>()
  const resizeDirection = useRef<number | undefined>()
  const containerRef = useRef<HTMLDivElement>(null)
  const canvasToExportRef = useRef<HTMLCanvasElement>(null)
  const clipLayerRef = useRef<HTMLDivElement>(null)
  const [clipType, setClipType] = useState<ExportLayoutModalResponse | null>(null)
  const visibleLayerRef = useRef<HTMLDivElement>(null)
  const exportLayerRef = useRef<HTMLDivElement>(null)
  const [imageAreaLoop, setImageAreaLoop] = useState(1)
  const { exportInvoked, setExportInvoked, handleExport } = useContextMotifGenerator()
  const [defaultImageSize, setDefaultImageSize] = useState(DEFAULT_IMAGE_SIZE)
  const [minImageSize, setMinImageSize] = useState(MIN_IMAGE_SIZE)
  const [maxImageSize, setMaxImageSize] = useState(MAX_IMAGE_SIZE)
  const motifRemoverRef = useRef<HTMLDivElement>(null)
  const motifRemoverClientRect = useRef<DOMRect>()
  const [isDragging, setIsDragging] = useState(false)
  const removeEnabled = useRef(false)
  const ratio = useRef(1)
  const selectedImageRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const rat = (Math.min(window.innerWidth, window.innerHeight) / EXPORT_SIZE) * 0.75
    ratio.current = rat
    setDefaultImageSize(DEFAULT_IMAGE_SIZE * (1 + 1 - rat))
    setMinImageSize(MIN_IMAGE_SIZE * (1 + 1 - rat))
    setMaxImageSize(MAX_IMAGE_SIZE * (1 + 1 - rat))

    if (freeLayoutGeneratorState) {
      setImageAreaLoop(freeLayoutGeneratorState.isMirrored ? 2 : 1)
    }
  }, [])

  useEffect(() => {
    if (!selectedMotifForFreeGeneration) return
    if (
      !images
        .map((image: DraggableImage) => image.id)
        .includes(`${selectedMotifForFreeGeneration.id}-${images.length}`)
    ) {
      setImages([
        // deselect all the images
        ...images.map(image => {
          image.selected = false
          return image
        }),
        // add the recently grabbed image
        {
          id: `${selectedMotifForFreeGeneration.id}-${images.length}`,
          position: {
            x: freeGeneratorTouchEventPageXY
              ? freeGeneratorTouchEventPageXY.x * (1 / ratio.current) - defaultImageSize / 2
              : 0,
            y: freeGeneratorTouchEventPageXY
              ? freeGeneratorTouchEventPageXY.y * (1 / ratio.current) - defaultImageSize / 2
              : window.innerHeight / 2,
          },
          vectorizedUrl: selectedMotifForFreeGeneration.vectorizedUrl,
          size: defaultImageSize,
          selected: true,
          rotation: 0,
        },
      ])
      setIsDragging(true)
    }
  }, [selectedMotifForFreeGeneration])

  useEffect(() => {
    if (!freeGeneratorTouchEventPageXY || !selectedMotifForFreeGeneration) return
    const selectedImage = getSelectedImage()
    if (!selectedImage) return
    setIsDragging(true)
    moveImage(selectedImage, freeGeneratorTouchEventPageXY)
  }, [freeGeneratorTouchEventPageXY])

  useEffect(() => {
    if (!selectedMotifs) return

    setImages(
      images.filter(image =>
        selectedMotifs.map(motif => motif.vectorizedUrl).includes(image.vectorizedUrl),
      ),
    )
  }, [selectedMotifs])

  useEffect(() => {
    if (images.length > 0) {
      setFeatureDiscoveryElementsOpen([
        ...featureDiscoveryElementsOpen.filter(
          elem => elem !== FeatureDiscoveryElement.FREE_LAYOUT,
        ),
      ])
      setFreeLayoutGeneratorState({ images, isMirrored: imageAreaLoop === 2 })
    }
  }, [images, imageAreaLoop])

  useEffect(() => {
    if (clipType) exportImage()
  }, [clipType])

  useEffect(() => {
    if (motifRemoverRef.current) {
      motifRemoverClientRect.current = motifRemoverRef.current.getBoundingClientRect()
    }
  }, [motifRemoverRef])

  const exportImage = async () => {
    copyContentToExportLayer()
    if (!canvasToExportRef.current || !exportLayerRef.current) return
    const dataUrl = await toJpeg(exportLayerRef.current, {
      skipAutoScale: true,
      quality: 0.92,
    })
    const img = document.createElement('img')
    img.style.position = 'absolute'
    img.style.width = `${EXPORT_SIZE}px`
    img.style.height = `${EXPORT_SIZE}px`
    img.style.zIndex = '2000'
    img.src = dataUrl

    canvasToExportRef.current.width = EXPORT_SIZE
    canvasToExportRef.current.height = EXPORT_SIZE

    const ctx = canvasToExportRef.current.getContext('2d')

    if (!ctx) return
    img.onload = () => {
      if (!ctx || !containerRef.current || !canvasToExportRef.current || !clipLayerRef.current)
        return
      const svgBoundingClientRect = clipLayerRef.current
        .querySelector('svg')
        ?.getBoundingClientRect()
      if (!svgBoundingClientRect) return
      canvasToExportRef.current.width = EXPORT_SIZE
      canvasToExportRef.current.height = EXPORT_SIZE

      ctx.beginPath()
      ctx.fillStyle = '#fff'
      ctx.fillRect(0, 0, canvasToExportRef.current.width, canvasToExportRef.current.height)
      ctx.drawImage(img, 0, 0, EXPORT_SIZE, EXPORT_SIZE)

      handleExport(canvasToExportRef.current)
    }

    setClipType(null)

    setExportInvoked(false)
  }

  const handleExportModalResponse = async (response: ExportLayoutModalResponse) => {
    setClipType(response)
  }

  const handleMouseMove = (event: React.MouseEvent | React.TouchEvent): void => {
    const selectedImage = getSelectedImage()

    if (!selectedImage) return
    const pageXY = getPagePositionXY(event)

    if (rotating.current) {
      rotateImage(selectedImage, pageXY)
    }

    if (resizing.current) {
      resizeImage(selectedImage, pageXY)
    }

    if (isDragging && !rotating.current && !resizing.current) {
      moveImage(selectedImage, pageXY)

      if (
        event.type === 'touchmove' &&
        motifRemoverRef.current &&
        motifRemoverClientRect.current &&
        selectedImageRef.current
      ) {
        if (
          pageXY.x > motifRemoverClientRect.current.left &&
          pageXY.x < motifRemoverClientRect.current.right &&
          pageXY.y > motifRemoverClientRect.current.y &&
          pageXY.y < motifRemoverClientRect.current.y + motifRemoverClientRect.current.height
        ) {
          removeEnabled.current = true
          selectedImageRef.current.classList.add('to-be-removed')
          motifRemoverRef.current.classList.add('active')
        } else {
          removeEnabled.current = false
          selectedImageRef.current.classList.remove('to-be-removed')
          motifRemoverRef.current.classList.remove('active')
        }
      }
    }

    if (!isMobile) {
      lastPos.current = getPagePositionXY(event)
    } else if (rotating.current || resizing.current || isDragging) {
      lastPos.current = getPagePositionXY(event)
    }
  }

  const moveImage = (selectedImage: DraggableImage, pageXY: PositionXY) => {
    const movedImages = [...images]
    const imageToMove = movedImages.find(image => image.id === selectedImage.id)

    if (!imageToMove) return

    // Dragging item from the list to the screen
    if (!dragStartPosXY.current) {
      imageToMove.position = {
        x: pageXY.x * (1 / ratio.current) - imageToMove.size / 2,
        y: pageXY.y * (1 / ratio.current) - imageToMove.size / 2,
      }
    } else {
      // Dragging item that are already on the screen
      if (lastPos.current.x === 0) return

      imageToMove.position = {
        x: imageToMove.position.x - (lastPos.current.x - pageXY.x) * (1 / ratio.current),
        y: imageToMove.position.y - (lastPos.current.y - pageXY.y) * (1 / ratio.current),
      }
    }

    setImages(movedImages)
  }

  const rotateImage = (selectedImage: DraggableImage, pageXY: PositionXY) => {
    const rotationAmount = lastPos.current.x - pageXY.x
    const tmpImages = [...images]
    tmpImages.find(image => image.id === selectedImage.id)!.rotation =
      selectedImage.rotation + rotationAmount
    setImages(tmpImages)
  }

  const resizeImage = (selectedImage: DraggableImage, pageXY: PositionXY) => {
    let resizeAmount = -(lastPos.current.x - pageXY.x)
    const tmpImages = [...images]
    const imageToResize = tmpImages.find(image => image.id === selectedImage.id)

    if (!imageToResize) return

    const centerOfImageXY = {
      x: imageToResize.position.x + imageToResize.size / 2,
      y: imageToResize.position.y + imageToResize.size / 2,
    }

    if (!resizeDirection.current) {
      centerOfImageXY.x * ratio.current > dragStartPosXY.current!.x
        ? (resizeDirection.current = -1)
        : (resizeDirection.current = 1)
    }

    resizeAmount = resizeDirection.current * resizeAmount

    imageToResize.size =
      selectedImage.size + resizeAmount > minImageSize &&
      selectedImage.size + resizeAmount < maxImageSize
        ? selectedImage.size + resizeAmount
        : selectedImage.size
    setImages(tmpImages)
  }

  const handleMirrorSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.checked ? setImageAreaLoop(2) : setImageAreaLoop(1)

    handleMouseUp()
  }

  const copyContentToExportLayer = () => {
    if (!visibleLayerRef.current || !exportLayerRef.current || !clipLayerRef.current) return
    exportLayerRef.current.innerHTML = visibleLayerRef.current.innerHTML

    const bounding = clipLayerRef.current.querySelector('svg')!.getBoundingClientRect()

    exportLayerRef.current.querySelectorAll('.inner-image-container').forEach(image => {
      const transform = (image as HTMLDivElement).style.transform
      const x = parseFloat(transform.split('(')[1].split('px')[0])
      let xModded = x - bounding.left * (1 / ratio.current)
      const y = parseFloat(transform.split(', ')[1].split('px')[0])
      let yModded = y - bounding.top * (1 / ratio.current)
      let transformModded = transform.replace(x.toString(), xModded.toString())
      transformModded = transformModded.replace(y.toString(), yModded.toString())
      ;(image as HTMLDivElement).style.transform = transformModded
    })
  }

  const handleContainerClick = (
    event: React.MouseEvent<HTMLDivElement> | React.TouchEvent,
  ): void => {
    if (
      (event.target as HTMLDivElement).classList.contains('free-layout-container') ||
      ((event.target as HTMLDivElement).classList.contains('image-area') &&
        !isDragging &&
        !rotating.current)
    ) {
      setImages(
        images.map(image => {
          image.selected = false
          return image
        }),
      )
    }
  }

  const handleImageMouseDown = (
    event: React.MouseEvent | React.TouchEvent,
    clickedImage: DraggableImage,
  ): void => {
    event.preventDefault()
    event.stopPropagation()

    if (!images.find(image => image.id === clickedImage.id)?.selected) {
      clickedImage.selected = true
      const updatedImages = images.map(image => {
        if (clickedImage.id === image.id) return clickedImage
        image.selected = false
        return image
      })
      setImages(updatedImages)

      return
    }

    setIsDragging(true)
    dragStartPosXY.current = getPagePositionXY(event)
    lastPos.current = getPagePositionXY(event)
  }

  const handleMouseUp = (): void => {
    if (removeEnabled.current) {
      const selectedImage = images.find(img => img.selected)
      if (!selectedImageRef.current) {
        return
      }

      selectedImageRef.current.classList.add('remove')

      const selectedImageElement: HTMLDivElement | null =
        selectedImageRef.current.querySelector('.image')

      if (motifRemoverClientRect.current && selectedImageElement && selectedImage) {
        selectedImageRef.current.style.transform = `translate(${
          motifRemoverClientRect.current.left * (1 / ratio.current) +
          motifRemoverClientRect.current.width / 2 -
          selectedImage.size / 2
        }px, ${
          motifRemoverClientRect.current.y * (1 / ratio.current) +
          motifRemoverClientRect.current.height / 2 -
          selectedImage.size / 2
        }px)`
      }

      setTimeout(() => {
        setImages([...images.filter(img => !img.selected)])
        removeEnabled.current = false
      }, 400)
    }

    setSelectedMotifForFreeGeneration(undefined)
    setFreeGeneratorTouchEventPageXY(undefined)
    rotating.current = false
    resizing.current = false
    setIsDragging(false)
    dragStartPosXY.current = undefined
    resizeDirection.current = undefined
    copyContentToExportLayer()
  }

  const handleRotatorMouseDown = (event: React.MouseEvent | React.TouchEvent): void => {
    event.preventDefault()
    event.stopPropagation()
    rotating.current = true
  }

  const handleResizerMouseDown = (event: React.MouseEvent | React.TouchEvent): void => {
    event.preventDefault()
    event.stopPropagation()
    resizing.current = true
    dragStartPosXY.current = getPagePositionXY(event)
  }

  const handleRemoverEnter = (): void => {
    if (isDragging && motifRemoverRef.current && selectedImageRef.current) {
      selectedImageRef.current.classList.add('to-be-removed')
      removeEnabled.current = true
      motifRemoverRef.current.classList.add('active')
    }
  }

  const handleRemoverLeave = (): void => {
    if (isDragging && motifRemoverRef.current && selectedImageRef.current) {
      selectedImageRef.current.classList.remove('to-be-removed')
      removeEnabled.current = false
      motifRemoverRef.current?.classList.remove('active')
    }
  }

  const getSelectedImage = (): DraggableImage => images.find(image => image.selected)!

  const { callback } = useThrottleFn(handleMouseMove, 20)

  return (
    <Container
      className="free-layout-container"
      onMouseMove={callback}
      onTouchMove={callback}
      onMouseUp={handleMouseUp}
      onTouchEnd={handleMouseUp}
      onMouseDown={e => handleContainerClick(e)}
      onTouchStart={e => handleContainerClick(e)}
    >
      {exportInvoked ? (
        <ExportLayoutModal
          responseHandlerFn={handleExportModalResponse}
          setExportInvoked={setExportInvoked}
        />
      ) : null}
      {images.length > 0 ? (
        <CheckboxSwitch
          onSwitch={handleMirrorSwitchChange}
          isOn={imageAreaLoop === 2}
          position={{
            x: `calc(50% + 500px * ${ratio.current} + 2rem)`,
            y: `calc(50% - 500px * ${ratio.current})`,
          }}
        />
      ) : null}
      <canvas ref={canvasToExportRef} width={EXPORT_SIZE} height={EXPORT_SIZE}></canvas>
      <MotifRemover
        ref={motifRemoverRef}
        className={isDragging ? 'show' : ''}
        onMouseEnter={handleRemoverEnter}
        onMouseLeave={handleRemoverLeave}
      >
        <div>{t('freehandGeneratorRemove')}</div>
      </MotifRemover>
      <div
        className="clip-circle"
        style={{
          transform: `scale(${ratio.current}) translate(-50%, -50%)`,
        }}
      ></div>
      <div className="visible-layer" ref={visibleLayerRef}>
        <div
          className="clip-layer"
          style={{
            width: `${(1 / ratio.current) * 100}%`,
            height: `${(1 / ratio.current) * 100}%`,
            transform: `scale(${ratio.current})`,
          }}
          ref={clipLayerRef}
        >
          <MaskSquare />
          <MaskCircle />
        </div>
        <ImageContainer
          style={{
            width: `${(1 / ratio.current) * 100}%`,
            height: `${(1 / ratio.current) * 100}%`,
            transform: `scale(${ratio.current})`,
          }}
          className={`outer-image-container ${
            clipType === ExportLayoutModalResponse.CIRCLE ? 'export-circle' : ''
          } ${exportInvoked ? 'hide-handlers' : ''}`}
          ref={containerRef}
        >
          {[...Array(imageAreaLoop)].map((_n, index) => (
            <div
              className={`image-area ${imageAreaLoop === 2 ? 'mirror-on' : ''} ${
                index > 0 ? 'mirrored' : ''
              }`}
              key={`image-area-${index}`}
            >
              {images.map((image, imageIndex: number) => {
                return image.vectorizedUrl ? (
                  <div
                    ref={image.selected ? selectedImageRef : null}
                    key={`${image.id}-${imageIndex}`}
                    onMouseDown={e => handleImageMouseDown(e, image)}
                    onTouchStart={e => handleImageMouseDown(e, image)}
                    className={`inner-image-container ${image.selected ? 'selected' : ''}`}
                    style={{
                      width: `${image.size}px`,
                      height: `${image.size}px`,
                      transform: `translate(${image.position.x}px, ${image.position.y}px) rotate(${image.rotation}deg)`,
                    }}
                  >
                    <div
                      className="image"
                      style={{
                        backgroundImage: `url(${image.vectorizedUrl})`,
                      }}
                    ></div>
                    <div
                      className="rotator"
                      onMouseDown={handleRotatorMouseDown}
                      onTouchStart={handleRotatorMouseDown}
                      style={{
                        width: `${1 * (1 + 1 - ratio.current)}px`,
                        height: `${16 * (1 + 1 - ratio.current)}px`,
                      }}
                    >
                      <div
                        className="rotator__handler"
                        style={{
                          transform: `scale(${1 + ((1 - ratio.current) * EXPORT_SIZE) / 1000})`,
                        }}
                      ></div>
                    </div>
                    <div
                      className="resizer"
                      style={{ borderWidth: `${1 + ((1 - ratio.current) * EXPORT_SIZE) / 1000}px` }}
                    >
                      <div
                        onMouseDown={handleResizerMouseDown}
                        onTouchStart={handleResizerMouseDown}
                        className="resizer__corner resizer__corner--top-left"
                        style={{
                          transform: `scale(${1 + ((1 - ratio.current) * EXPORT_SIZE) / 1000})`,
                        }}
                      ></div>
                      <div
                        onMouseDown={handleResizerMouseDown}
                        onTouchStart={handleResizerMouseDown}
                        className="resizer__corner resizer__corner--top-right"
                        style={{
                          transform: `scale(${1 + ((1 - ratio.current) * EXPORT_SIZE) / 1000})`,
                        }}
                      ></div>
                      <div
                        onMouseDown={handleResizerMouseDown}
                        onTouchStart={handleResizerMouseDown}
                        className="resizer__corner resizer__corner--bottom-right"
                        style={{
                          transform: `scale(${1 + ((1 - ratio.current) * EXPORT_SIZE) / 1000})`,
                        }}
                      ></div>
                      <div
                        onMouseDown={handleResizerMouseDown}
                        onTouchStart={handleResizerMouseDown}
                        className="resizer__corner resizer__corner--bottom-left"
                        style={{
                          transform: `scale(${1 + ((1 - ratio.current) * EXPORT_SIZE) / 1000})`,
                        }}
                      ></div>
                    </div>
                  </div>
                ) : null
              })}
            </div>
          ))}
        </ImageContainer>
      </div>
      <div className="export-layer" ref={exportLayerRef}></div>
    </Container>
  )
}

export default FreeLayoutGenerator

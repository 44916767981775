import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { useTranslation } from 'react-i18next'
import { useContextUI } from '../../contexts/UIContext'
import { UIElement } from '../../types/UIElements'
import { useEnv } from '../../utils/useEnv'

const WelcomeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  opacity: 0;
  transition: opacity 0.3s;

  &.fade-in {
    opacity: 1;
  }

  &.kiosk {
    user-select: none;
  }

  .bubble-wrapper {
    width: 685px;
    transform: scale(0.42);

    .bubble-svg {
      margin-left: -145px;
      margin-top: 60px;
    }
  }

  &.fade-out {
    animation: fade-out-y-10p 0.8s ease forwards;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    .bubble-wrapper {
      transform: scale(0.8);
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.lg}) {
    .bubble-wrapper {
      transform: scale(1);
    }
  }
`

const BubbleContent = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  font-size: 0.75rem;

  img {
    max-width: 100%;
    width: 100%;
  }

  .heading1 {
    white-space: nowrap;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.08rem;
    font-family: ${({ theme }) => theme.font.bold};
  }

  .collection {
    width: 100%;
    font-size: 0.625rem;
    font-family: ${({ theme }) => theme.font.medium};
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    letter-spacing: 0.13px;
    line-height: 16px;

    span {
      display: inline-block;
      width: 10px;
      height: 2px;
      background-color: ${({ theme }) => theme.colors.black};
    }
  }

  h2 {
    display: flex;
    width: 100%;
    height: 22.7px;
    background-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};
    justify-content: center;
    align-items: center;
    font-family: ${({ theme }) => theme.font.bold};
    border-radius: 8px;
    text-align: center;
    padding: 6px;
    font-size: 0.875rem;
    letter-spacing: 0.045rem;
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .logo {
      height: 17.4px;
      width: 102.5px;
    }
  }

  &.english {
    .heading1 {
      letter-spacing: 0.6px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    .heading1 {
      margin: 10px 0 0 0;
    }

    h2 {
      margin: 5px 0 11px 0;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    font-size: 0.9375rem;

    .heading1 {
      font-size: 1.625rem;
      letter-spacing: 2.21px;
      margin: 0.5rem 0;
    }

    .collection {
      font-size: 1.125rem;

      span {
        width: 22px;
      }
    }

    h2 {
      height: 41.5px;
      font-size: 1.625rem;
    }

    .footer {
      .logo {
        height: 25px;
        width: 148px;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.lg}) {
    width: fit-content !important;

    .heading1 {
      font-size: 2.125rem;
      letter-spacing: 2.1px;
    }

    .collection {
      font-size: 1.5rem;

      span {
        width: 28px;
      }
    }

    h2 {
      height: 53px;
      font-size: 2.125rem;
      letter-spacing: 1.63px;
    }

    .footer {
      .logo {
        height: 32px;
        width: 188.5px;
      }
    }
  }
`

const Welcome = () => {
  const [fadeOut, setFadeOut] = useState<boolean>(false)
  const [isEnglish, setIsEnglish] = useState<boolean>(false)
  const { t, i18n } = useTranslation()
  const { elementsOpen, setElementsOpen } = useContextUI()
  const { isKiosk } = useEnv()
  const titleRef = useRef<HTMLHeadingElement>(null)
  const [innerContainerWidth, setInnerContainerWidth] = useState<number>()

  useEffect(() => {
    setIsEnglish(i18n.language === 'en')
  }, [i18n.language])

  useEffect(() => {
    setTimeout(() => {
      if (titleRef.current) {
        setInnerContainerWidth(titleRef.current.offsetWidth)
      }
    }, 1500)
  }, [titleRef.current])

  const onClick = () => {
    setFadeOut(true)
    setTimeout(() => {
      setFadeOut(false)
      setElementsOpen([UIElement.CONTROLS])
    }, 500)
  }

  return elementsOpen.includes(UIElement.WELCOME) ? (
    <WelcomeWrapper
      onClick={onClick}
      className={`${fadeOut ? 'fade-out' : ''} ${isKiosk ? 'kiosk' : ''} ${
        innerContainerWidth ? 'fade-in' : ''
      }`}
    >
      <div className="bubble-wrapper">
        <SVG className="bubble-svg" src="/images/speechbubble.svg" />
      </div>
      <BubbleContent
        className={`${isEnglish ? 'english' : ''} ${innerContainerWidth ? 'show' : ''}`}
        style={{ width: innerContainerWidth ? `${innerContainerWidth}px` : 'calc(685px * 0.42)' }}
      >
        <img src="/images/1001.svg" alt="1001" />
        <span className="heading1" ref={titleRef}>
          {t('motifsAndDecorationsCaps')}
        </span>
        <p className="collection">
          <span />
          {t('fromTheMuseum')}
          <span />
        </p>
        <h2>{t('chooseAndCombine')}</h2>
        <div className="footer">
          <img className="logo" src="/icons/neprajzi_logo.svg" alt="neprajzi_logo" />
        </div>
      </BubbleContent>
    </WelcomeWrapper>
  ) : null
}

export default Welcome

const MaskCircle = () => {
  return (
    <svg id="svg-mask" width="300" height="300" xmlns="http://www.w3.org/2000/svg">
      <circle cx="50%" cy="50%" r="50%" />
      Sorry, your browser does not support inline SVG.
    </svg>
  )
}

export default MaskCircle

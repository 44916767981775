import { ChangeEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { PositionXYPX } from '../../types/PositionXY'

const Switch = styled.div`
  position: absolute;
  z-index: 110;
  animation: simple-fade-in 0.2s;
  transform: translateY(-50%);

  p {
    margin: 0 0 14px 0;
  }

  label {
    position: relative;
    display: inline-block;
    width: 64px;
    height: 40px;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .slider::before {
        background-color: ${({ theme }) => theme.colors.red};
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      height: 30px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #000;
      transition: 0.4s;
      border-radius: 34px;

      &::before {
        position: absolute;
        content: '';
        height: 40px;
        width: 40px;
        left: -10px;
        top: -5px;
        background-color: #ebedef;
        transition: 0.4s;
        border-radius: 50%;
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.5);
      }
    }
  }

  input:checked + .slider:before {
    transform: translateX(42px);
  }
`

type Props = {
  onSwitch: ChangeEventHandler<HTMLInputElement>
  isOn?: boolean
  position?: PositionXYPX
}

const CheckboxSwitch = ({ onSwitch, isOn, position }: Props) => {
  const { t } = useTranslation()

  return (
    <Switch
      style={{
        top: position ? position.y : '10%',
        left: position ? position.x : 'calc(50% + 500px + 2rem)',
      }}
    >
      <p>{t('freehandGeneratorMirroring')}</p>
      <label className="switch">
        <input type="checkbox" checked={isOn} onChange={onSwitch} />
        <span className="slider round"></span>
      </label>
    </Switch>
  )
}

export default CheckboxSwitch

import { useEffect, useRef } from 'react'

interface Props {
  callback: () => void
}

export const useScreensaverTimer = ({ callback }: Props) => {
  const callbackRef = useRef(callback)

  useEffect(() => {
    const idleTimeInMinutes = Number(process.env.REACT_APP_KIOSK_IDLE_TIME_IN_MINUTES)
    let id: NodeJS.Timeout

    function timer() {
      clearTimeout(id)
      id = setTimeout(() => {
        callbackRef.current()
      }, idleTimeInMinutes * 1000 * 60)
    }

    timer()

    const activityEvents = [
      'click',
      'mousemove',
      'mousedown',
      'mouseup',
      'touchmove',
      'touchstart',
      'touchend',
      'drag',
      'dragstart',
      'dragend',
    ]
    activityEvents.forEach(event => {
      window.addEventListener(event, timer)
    })
    return () => {
      activityEvents.forEach(event => {
        window.removeEventListener(event, timer)
      })
      clearTimeout(id)
    }
  }, [callbackRef.current])

  useEffect(() => {
    if (callbackRef.current !== callback) {
      callbackRef.current = callback
    }
  }, [callback])
}

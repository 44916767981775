import React, { useEffect, useState } from 'react'
import { useContextUI } from '../../contexts/UIContext'
import { GeoFilterStage, Region } from '../../constants/geographicFilters'
import styled from 'styled-components'
import TypeSelector from './TypeSelector'
import FilterSelector from './FilterSelector'
import LocalFilter from './LocalFilter'
import GlobalFilter from './GlobalFilter'
import { useContextMotifDetails } from '../../contexts/motifDetailsContext'
import FloatingXButton from '../FloatingXButton'
import { UIElement } from '../../types/UIElements'

const GeoFilterWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @keyframes geo-filter-in {
    from {
      opacity: 0;
      transform: translateX(30%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes geo-filter-out {
    from {
      opacity: 1;
      transform: translateX(0);
    }
    to {
      opacity: 0;
      transform: translateX(-30%);
    }
  }
`

export type GeoClass = 'come-in' | 'go-out'

const GeographicFilter = () => {
  const { elementsOpen, setElementsOpen, setOverlayVisible } = useContextUI()
  const { applyGeoFilter, applyTagFilter } = useContextMotifDetails()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [filterStage, setFilterStage] = useState<GeoFilterStage>(GeoFilterStage.INITIAL)
  const [typeSelectorClass, setTypeSelectorClass] = useState<GeoClass>('come-in')
  const [filterSelectorClass, setFilterSelectorClass] = useState<GeoClass>('come-in')
  const [closing, setClosing] = useState<boolean>(false)

  useEffect(() => {
    if (elementsOpen.includes(UIElement.GEOFILTER)) {
      setIsOpen(true)
      setTypeSelectorClass('come-in')
      setFilterSelectorClass('come-in')
      applyTagFilter(undefined)
    } else if (isOpen) {
      close()
    }
  }, [elementsOpen])

  const selectType = (type: GeoFilterStage) => {
    setTypeSelectorClass('go-out')
    setFilterSelectorClass('go-out')
    setTimeout(() => {
      setFilterStage(type)
      setFilterSelectorClass('come-in')
    }, 500)
  }

  const close = () => {
    setClosing(true)
    setTimeout(() => {
      if (filterStage === GeoFilterStage.INITIAL) {
        setTypeSelectorClass('go-out')
      } else {
        setFilterSelectorClass('go-out')
      }
    }, 200)
    setTimeout(() => {
      setFilterStage(GeoFilterStage.INITIAL)
      setClosing(false)
      setIsOpen(false)
    }, 500)
  }

  const switchType = () => {
    setFilterSelectorClass('go-out')
    setTimeout(() => {
      setFilterSelectorClass('come-in')
      setFilterStage(
        filterStage === GeoFilterStage.LOCAL ? GeoFilterStage.GLOBAL : GeoFilterStage.LOCAL,
      )
    }, 500)
  }

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const el = e.target as HTMLElement
    if (el.classList.contains('outside')) {
      handleCloseGeoFilter()
    }
  }

  const handleFilter = (filter: Region) => {
    applyGeoFilter(filter)
    setOverlayVisible(false)
    close()
  }

  const handleCloseGeoFilter = () => {
    close()
    setElementsOpen([UIElement.CONTROLS])
    applyGeoFilter(undefined)
  }

  return isOpen ? (
    <GeoFilterWrapper className="outside" onClick={e => handleOutsideClick(e)}>
      {filterStage === GeoFilterStage.INITIAL ? (
        <TypeSelector
          selectType={selectType}
          className={typeSelectorClass}
          onClick={handleOutsideClick}
        />
      ) : (
        <FilterSelector
          className={filterSelectorClass}
          switchType={switchType}
          closing={closing}
          onClick={handleOutsideClick}
        >
          {filterStage === GeoFilterStage.LOCAL ? (
            <LocalFilter onFilter={handleFilter} />
          ) : (
            <GlobalFilter onFilter={handleFilter} />
          )}
        </FilterSelector>
      )}
      <FloatingXButton onClick={handleCloseGeoFilter} />
    </GeoFilterWrapper>
  ) : null
}

export default GeographicFilter

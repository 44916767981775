import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { AnimationState, InfoSnackbarType } from '../constants/animationStates'
import { UIElement } from '../types/UIElements'
import screenSizeObserver from '../utils/screenSizeObserver'
import { isMobile, isAndroid } from 'react-device-detect'
import useWindowDimensions from '../utils/useWindowDimensions'
import { FeatureDiscoveryElement } from '../types/FeatureDiscoveryElement'

interface ContextType {
  elementsOpen: UIElement[]
  setElementsOpen: Dispatch<SetStateAction<UIElement[]>>
  filterOutUiElement: (elementToFilterOut: UIElement) => void
  featureDiscoveryElementsOpen: FeatureDiscoveryElement[]
  setFeatureDiscoveryElementsOpen: Dispatch<SetStateAction<FeatureDiscoveryElement[]>>
  featureDiscoveryElementsAlreadyShown: FeatureDiscoveryElement[]
  setFeatureDiscoveryElementsAlreadyShown: Dispatch<SetStateAction<FeatureDiscoveryElement[]>>
  overlayVisible: boolean | undefined
  setOverlayVisible: Dispatch<SetStateAction<boolean | undefined>>
  isInitialLoading: boolean
  setIsInitialLoading: Dispatch<SetStateAction<boolean>>
  isFirstGeneratorVisit: boolean
  setIsFirstGeneratorVisit: Dispatch<SetStateAction<boolean>>
  isFirstNotification: boolean
  setFirstNotification: Dispatch<SetStateAction<boolean>>
  isAnimationShowing: boolean
  setIsAnimationShowing: Dispatch<SetStateAction<boolean>>
  isErrorMessageVisible: boolean
  showErrorMessage: (errorMessageText: string, isReloadOnError: boolean) => void
  errorMessageText: string
  rotateDeviceNotificationVisible: boolean
  setRotateDeviceNotificationVisible: Dispatch<SetStateAction<boolean>>
  isReloadOnError: boolean
  hideErrorMessage: () => void
  qrCodeLink: string
  setQrCodeLink: Dispatch<SetStateAction<string>>
  snackbarVisibilityState: AnimationState
  showSnackbar: (message: string, durationInSec?: number) => void
  hideSnackbar: () => void
  snackbarText: string
  disabledSnackbars: InfoSnackbarType[]
  setDisabledSnackbars: Dispatch<SetStateAction<InfoSnackbarType[]>>
  isIpad: boolean
  triggerFirstNotification: (numberOfSelectedMotifs: number) => void
}

let snackbarPauseTimer: NodeJS.Timeout | undefined
let snackbarUnsetTimer: NodeJS.Timeout | undefined

interface Props {
  children: ReactNode
}

const Context = createContext({} as ContextType)

const UIContext = ({ children }: Props) => {
  const [elementsOpen, setElementsOpen] = useState<UIElement[]>([UIElement.WELCOME])
  const [featureDiscoveryElementsOpen, setFeatureDiscoveryElementsOpen] = useState<
    FeatureDiscoveryElement[]
  >([])
  const [featureDiscoveryElementsAlreadyShown, setFeatureDiscoveryElementsAlreadyShown] = useState<
    FeatureDiscoveryElement[]
  >([])
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(false)
  const [isFirstGeneratorVisit, setIsFirstGeneratorVisit] = useState<boolean>(true)
  const [isFirstNotification, setFirstNotification] = useState<boolean>(true)
  const [isAnimationShowing, setIsAnimationShowing] = useState<boolean>(false)
  const [overlayVisible, setOverlayVisible] = useState<boolean | undefined>(undefined)
  const [isErrorMessageVisible, setIsErrorMessageVisible] = useState<boolean>(false)
  const [errorMessageText, setErrorMessageText] = useState<string>('')
  const [rotateDeviceNotificationVisible, setRotateDeviceNotificationVisible] =
    useState<boolean>(false)
  const [isReloadOnError, setIsReloadOnError] = useState<boolean>(false)
  const [qrCodeLink, setQrCodeLink] = useState<string>('')
  const [snackbarVisibilityState, setSnackbarVisibilityState] = useState<AnimationState>(
    AnimationState.UNSET,
  )
  const [disabledSnackbars, setDisabledSnackbars] = useState<InfoSnackbarType[]>([])
  const [snackbarText, setSnackbarText] = useState<string>('')
  const isIpad = isMobile && !isAndroid
  const { windowWidth, windowHeight } = useWindowDimensions()

  useEffect(() => {
    screenSizeObserver(
      windowWidth,
      windowHeight,
      showErrorMessage,
      hideErrorMessage,
      setRotateDeviceNotificationVisible,
    )
  }, [windowWidth, windowHeight])

  useEffect(() => {
    const overlayedElements = [UIElement.TAGFILTER, UIElement.GEOFILTER, UIElement.EXPLANATION]
    if (overlayedElements.filter(element => elementsOpen.includes(element)).length > 0) {
      setOverlayVisible(true)
    } else {
      setOverlayVisible(false)
    }
  }, [elementsOpen])

  const showSnackbar = (message: string, durationInSec = 5) => {
    setSnackbarVisibilityState(AnimationState.PLAY)
    setSnackbarText(message)
    snackbarPauseTimer = setTimeout(() => {
      setSnackbarVisibilityState(AnimationState.PAUSE)
    }, durationInSec * 1000 - 300)
    snackbarUnsetTimer = setTimeout(() => {
      setSnackbarVisibilityState(AnimationState.UNSET)
    }, durationInSec * 1000)
  }

  const hideSnackbar = () => {
    setSnackbarVisibilityState(AnimationState.UNSET)
    setSnackbarText('')
    if (snackbarPauseTimer) {
      clearTimeout(snackbarPauseTimer)
      snackbarPauseTimer = undefined
    }
    if (snackbarUnsetTimer) {
      clearTimeout(snackbarUnsetTimer)
      snackbarUnsetTimer = undefined
    }
  }

  const showErrorMessage = (errorMessageText: string, isRealoadOnError: boolean) => {
    if (errorMessageText === 'APIErrorMessage' && isRealoadOnError) {
      setIsErrorMessageVisible(true)
      setErrorMessageText('APIErrorMessage')
      setIsReloadOnError(true)
    } else if (errorMessageText === 'resolutionErrorMessage' && !isRealoadOnError) {
      setIsErrorMessageVisible(true)
      setErrorMessageText('resolutionErrorMessage')
      setIsReloadOnError(false)
    } else if (errorMessageText === 'windowSizeErrorMessage' && !isRealoadOnError) {
      setIsErrorMessageVisible(true)
      setErrorMessageText('windowSizeErrorMessage')
      setIsReloadOnError(false)
    } else if (errorMessageText === 'screenOrientationErrorMessage' && !isRealoadOnError) {
      setIsErrorMessageVisible(true)
      setErrorMessageText('screenOrientationErrorMessage')
      setIsReloadOnError(false)
    }
  }

  const hideErrorMessage = () => {
    setIsErrorMessageVisible(false)
    setErrorMessageText('')
  }

  const triggerFirstNotification = (numberOfSelectedMotifs: number) => {
    if (isFirstNotification && numberOfSelectedMotifs === 4) {
      setFirstNotification(false)
      setIsAnimationShowing(true)
    }
  }

  const filterOutUiElement = (elementToFilterOut: UIElement) => {
    setElementsOpen(elementsOpen.filter(el => el !== elementToFilterOut))
  }

  return (
    <Context.Provider
      value={{
        elementsOpen,
        setElementsOpen,
        filterOutUiElement,
        featureDiscoveryElementsOpen,
        setFeatureDiscoveryElementsOpen,
        featureDiscoveryElementsAlreadyShown,
        setFeatureDiscoveryElementsAlreadyShown,
        overlayVisible,
        setOverlayVisible,
        isInitialLoading,
        setIsInitialLoading,
        isFirstGeneratorVisit,
        setIsFirstGeneratorVisit,
        isFirstNotification,
        setFirstNotification,
        isAnimationShowing,
        setIsAnimationShowing,
        isErrorMessageVisible,
        showErrorMessage,
        errorMessageText,
        rotateDeviceNotificationVisible,
        setRotateDeviceNotificationVisible,
        isReloadOnError,
        hideErrorMessage,
        qrCodeLink,
        setQrCodeLink,
        snackbarVisibilityState,
        showSnackbar,
        hideSnackbar,
        snackbarText,
        disabledSnackbars,
        setDisabledSnackbars,
        isIpad,
        triggerFirstNotification,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useContextUI = () => {
  return useContext(Context)
}

export default UIContext

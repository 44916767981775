import MapTypeGenerator from './MapTypeGenerator'
import { MotifGeneratorType } from './types'
import KaleidoscopeGeneratorCanvas from './KaleidoscopeGeneratorCanvas'
import { useContextMotifGenerator } from '../../contexts/motifGeneratorContext'
import PenroseGenerator from './PenroseGenerator'
import SquareLayoutGeneratorCanvas from './SquareLayoutGeneratorCanvas'
import CircleLayoutGeneratorCanvas from './CircleLayoutGeneratorCanvas'
import FreeLayoutGenerator from './FreeLayoutGenerator'

const MotifPatternGenerator = () => {
  const { selectedGeneratorType } = useContextMotifGenerator()

  const getSelectedGenerator = () => {
    switch (selectedGeneratorType) {
      case MotifGeneratorType.MAP:
        return <MapTypeGenerator />
      case MotifGeneratorType.SQUARE:
        return <SquareLayoutGeneratorCanvas />
      case MotifGeneratorType.CIRCLE:
        return <CircleLayoutGeneratorCanvas />
      case MotifGeneratorType.PENROSE:
        return <PenroseGenerator />
      case MotifGeneratorType.KALEIDO_SQUARE:
        return <KaleidoscopeGeneratorCanvas type={MotifGeneratorType.KALEIDO_SQUARE} />
      case MotifGeneratorType.KALEIDO_PENTA:
        return <KaleidoscopeGeneratorCanvas type={MotifGeneratorType.KALEIDO_PENTA} />
      case MotifGeneratorType.KALEIDO_HEXA:
        return <KaleidoscopeGeneratorCanvas type={MotifGeneratorType.KALEIDO_HEXA} />
      case MotifGeneratorType.KALEIDO_OCTA:
        return <KaleidoscopeGeneratorCanvas type={MotifGeneratorType.KALEIDO_OCTA} />
      case MotifGeneratorType.FREE:
        return <FreeLayoutGenerator />
      default:
        return <MapTypeGenerator />
    }
  }

  return <>{getSelectedGenerator()}</>
}

export default MotifPatternGenerator

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { useEffect, useState } from 'react'
import { useContextUI } from '../../contexts/UIContext'
import { UIElement } from '../../types/UIElements'
import { useEnv } from '../../utils/useEnv'

const ExplanationMenuWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-out;

  &.slide-in {
    transform: translateX(calc(50vw - 50%));
  }
`

const ExplanationMenu = styled.div`
  font-size: 10px;
  position: absolute;
  height: 500px;
  top: 2vh;
  width: 700px;
  border-radius: 17px;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.2);
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.black95};
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ${({ theme }) => theme.font.light};

  > div {
    width: 95%;
  }

  .row-item {
    flex: 1;
  }

  .header {
    position: relative;
    display: flex;
    align-items: center;
    height: 30px;
    padding-left: 30px;

    .bold {
      font-family: ${({ theme }) => theme.font.bold};
    }

    span {
      font-size: 25px;
    }

    button {
      position: absolute;
      display: flex;
      align-items: center;
      right: 0;
      height: 35px;
      width: 35px;
      color: ${({ theme }) => theme.colors.white};
      background-color: transparent;
      border: ${({ theme }) => theme.colors.black};
    }
  }

  .description {
    display: flex;
    font-size: 16px;
    align-items: center;
    justify-content: center;

    > p {
      padding: 0 30px;
      line-height: 25px;
    }
  }

  .footer {
    display: flex;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    gap: 30px;

    > button {
      height: 30px;
      width: 160px;
      border-radius: 22px;
      opacity: 0.95;
      color: white;
      font-size: 16px;
      font-family: ${({ theme }) => theme.font.light};
      letter-spacing: 1.06px;
    }

    .cancel-btn {
      border: 1px solid #979797;
      background-color: black;
    }

    .confirm-btn {
      border: 1px solid #e60050;
      background-color: #e60050;
    }
  }

  h1 {
    font-size: 3.5em;
    letter-spacing: 2.15px;
    font-family: ${({ theme }) => theme.font.bold};
  }

  @media (min-height: ${({ theme }) => theme.breakpoints.deviceH.xs}) {
    top: 10vh;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    .header {
      max-height: 125px;
    }

    .middle {
      margin-top: 35px;
    }

    .description {
      margin-bottom: 50px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.md}) {
    font-size: 11px;
    width: 775px;
    height: 500px;
    top: 8vh;

    .header {
      max-height: 135px;

      span {
        font-size: 30px;
      }
    }

    .middle {
      margin-top: 10px;
    }

    .description {
      margin-bottom: 25px;

      > p {
        font-size: 18px;
        padding: 0 30px;
        line-height: 30px;
      }
    }

    .footer {
      > button {
        height: 40px;
        width: 200px;
        font-size: 18px;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xl}) {
    font-size: 14px;
    width: 1010px;
    height: 600px;
    top: 8vh;

    .header {
      max-height: 145px;

      button {
        width: 40px;
        height: 40px;
      }
    }

    .middle {
      margin-top: 45px;
    }

    .description {
      margin-bottom: 35px;

      > p {
        font-size: 21px;
        padding: 0 30px;
        line-height: 36px;
      }
    }

    .footer {
      > button {
        height: 44px;
        width: 220px;
        font-size: 21px;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xxl}) {
    top: 13vh;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    height: 315px;
    width: 525px;

    h1 {
      font-size: 2.5em;
    }

    > div {
      width: 90%;
    }

    .header {
      max-height: 65px;
      padding-left: 0;

      span {
        font-size: 20px;
      }
    }

    .description {
      > p {
        padding: 0px 10px 0 0;
        line-height: 20px;
      }
    }

    .footer {
      > button {
        height: 30px;
        width: 160px;
      }
    }
  }
`

const RoadmapContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 25px;

  p {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.white};
    font-size: 1.8em;
    line-height: 35px;
    text-align: center;
    margin: 10px 10px 0 10px;
    font-family: ${({ theme }) => theme.font.medium};
  }

  svg {
    width: 140px;
    height: 25px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.md}) {
    p {
      height: 40px;
      width: 40px;
      margin: 15px 15px 0 15px;
      line-height: 40px;
    }

    svg {
      width: 170px;
      height: 35px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xl}) {
    p {
      height: 45px;
      width: 45px;
      margin: 25px 20px 0 20px;
      line-height: 45px;
    }

    svg {
      width: 215px;
      height: 50px;
    }
  }
`

const ExplanationItemsContainer = styled.div`
  font-size: 11px;
  display: flex;
  align-content: center;
  font-family: ${({ theme }) => theme.font.light};
  padding: 0 30px;
  gap: 30px;

  .explanation-item {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 45px;
    width: 165px;
    border: 1px solid ${({ theme }) => theme.colors.white};
    border-radius: 16px;
  }

  svg {
    margin: 0 8px 0 5px;
    width: 20px;
    height: 20px;
    opacity: 0.8;
  }

  p {
    white-space: pre-wrap;
    text-align: right;
    font-size: 1.125em;
    letter-spacing: 0.69px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.white};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    padding: 0;

    .explanation-item {
      width: 140px;
    }

    p {
      font-size: 1em;
      letter-spacing: 0;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.md}) {
    font-size: 12px;
    padding: 0 30px;
    gap: 55px;

    .explanation-item {
      height: 60px;
      width: 190px;
    }

    svg {
      margin: 0 12px 0 7px;
      width: 25px;
      height: 25px;
    }

    p {
      line-height: 18px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xl}) {
    font-size: 14px;
    padding: 0 30px;
    gap: 78px;

    .explanation-item {
      height: 65px;
      width: 245px;
    }

    svg {
      margin: 0 13px 0 10px;
      width: 30px;
      height: 30px;
    }

    p {
      line-height: 22px;
    }
  }
`

const ExplanationFeature = () => {
  const { t } = useTranslation()
  const { elementsOpen, filterOutUiElement } = useContextUI()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { isKiosk } = useEnv()

  useEffect(() => {
    if (elementsOpen.includes(UIElement.EXPLANATION)) {
      setIsOpen(true)
      if (elementsOpen.includes(UIElement.GALLERY)) {
        filterOutUiElement(UIElement.GALLERY)
      }
      if (elementsOpen.includes(UIElement.ABOUT)) {
        filterOutUiElement(UIElement.ABOUT)
      }
    } else if (isOpen) {
      setIsOpen(false)
    }
  }, [elementsOpen])

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const el = e.target as HTMLElement
    if (el.classList.contains('outside')) {
      filterOutUiElement(UIElement.EXPLANATION)
    }
  }

  return (
    <>
      <ExplanationMenuWrapper
        className={`outside ${isOpen ? 'slide-in' : ''}`}
        onClick={e => handleOutsideClick(e)}
      >
        <ExplanationMenu onClick={() => filterOutUiElement(UIElement.EXPLANATION)}>
          <div className="header row-item">
            <div>
              <span>
                {t('howWorks')}
                {' – '}
              </span>
              <span className="bold">{t('chooseAndCombine')}</span>
            </div>
          </div>
          <div className="row-item middle">
            <RoadmapContainer>
              <p>1</p>
              <SVG src="/icons/dots.svg" />
              <p>2</p>
              <SVG src="/icons/dots.svg" />
              <p>3</p>
            </RoadmapContainer>
            <ExplanationItemsContainer>
              <div className="explanation-item">
                <p>{t('chooseMotifs')}</p>
                <SVG src="/icons/heart2.svg" className="svg-heart" />
              </div>
              <div className="explanation-item">
                <p> {t('choosePattern')}</p>
                <SVG src="/icons/patterns.svg" className="svg-patterns" />
              </div>
              <div className="explanation-item">
                <p>{t('downloadAndShare')}</p>
                <SVG src="/icons/download.svg" className="svg-download" />
              </div>
            </ExplanationItemsContainer>
          </div>
          <div className="footer description row-item">
            <p>{isKiosk ? t('moveMotifMapKiosk') : t('moveMotifMap')}</p>
            <p>{isKiosk ? t('choosePatternFromElementsKiosk') : t('choosePatternFromElements')}</p>
          </div>
          {/* TODO: Do not delete yet! */}
          {/* <div className="footer row-item">
            <button className="cancel-btn">{t('cancel')}</button>
            <button className="confirm-btn">{t('confirm')}</button>
          </div> */}
        </ExplanationMenu>
      </ExplanationMenuWrapper>
    </>
  )
}

export default ExplanationFeature

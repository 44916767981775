import styled from 'styled-components'

export const KaleidoscopeContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 140vw;
  height: 140vw;
  transform: translate(-50%, -50%);

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 10;
  }

  .tile {
    background-color: #fff;
    width: calc(100% / 3 + 1px);
    height: calc(100% / 3 + 1px);
    overflow: hidden;
    position: absolute;
    background-size: contain;
    z-index: 20;

    &:not(.tile-to-export) {
      canvas {
        width: 100%;
        height: 100%;
      }
    }

    &.tile-to-export {
      position: absolute;
      left: 0;
      top: 0;
      pointer-events: none;
      z-index: 1;
    }

    &:nth-child(n + 3) {
      top: 0;
    }

    &:nth-child(3n + 1) {
      left: 0;
    }

    &:nth-child(n + 4):nth-child(-n + 7) {
      top: 33.333%;
    }

    &:nth-child(3n + 2) {
      left: 33.333%;
    }

    &:nth-child(n + 7):nth-child(-n + 9) {
      top: 66.666%;
    }

    &:nth-child(3n + 3) {
      left: 66.666%;
    }
  }

  .item-outer {
    transform-origin: right top;
    width: 50%;
    height: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 10;
    overflow: hidden;
  }

  .overflow {
    width: 140%;
    height: 140%;
    position: absolute;
    top: -20%;
    left: -20%;
    overflow: hidden;
  }

  .item-inner {
    height: 100%;
    width: 100%;
    transform: rotate(90deg);
    position: relative;
    transform-origin: left top;
    left: 100%;
    top: 0;
    overflow: hidden;
  }

  .pattern {
    position: absolute;
    top: -25%;
    left: -25%;
    width: 175%;
    height: 175%;
    display: flex;
    flex-wrap: wrap;
  }

  &.kaleidoscope-kaleido_square {
    .item-outer-1 {
      transform: rotate(-45deg);
    }

    .item-outer-2 {
      transform: rotate(45deg) matrix(-1, 0, 0, 1, 0, 0);
    }

    .item-outer-3 {
      transform: rotate(45deg);
    }
    .item-outer-4 {
      transform: rotate(135deg) matrix(-1, 0, 0, 1, 0, 0);
    }
    .item-outer-5 {
      transform: rotate(135deg);
    }
    .item-outer-6 {
      transform: rotate(225deg) matrix(-1, 0, 0, 1, 0, 0);
    }
    .item-outer-7 {
      transform: rotate(225deg);
    }
    .item-outer-8 {
      transform: rotate(315deg) matrix(-1, 0, 0, 1, 0, 0);
    }
  }

  &.kaleidoscope-kaleido_penta {
    .tile-flipped {
      transform: scaleY(-1);
    }
    .item-inner {
      transform: rotate(72deg);
    }
    .item-outer-1 {
      transform: rotate(-36deg);
    }
    .item-outer-2 {
      transform: rotate(36deg) matrix(-1, 0, 0, 1, 0, 0);
    }
    .item-outer-3 {
      transform: rotate(36deg);
    }
    .item-outer-4 {
      transform: rotate(108deg) matrix(-1, 0, 0, 1, 0, 0);
    }
    .item-outer-5 {
      transform: rotate(108deg);
    }
    .item-outer-6 {
      transform: rotate(180deg) matrix(-1, 0, 0, 1, 0, 0);
    }
    .item-outer-7 {
      transform: rotate(180deg);
    }
    .item-outer-8 {
      transform: rotate(252deg) matrix(-1, 0, 0, 1, 0, 0);
    }
    .item-outer-9 {
      transform: rotate(252deg);
    }
    .item-outer-10 {
      transform: rotate(324deg) matrix(-1, 0, 0, 1, 0, 0);
    }
  }

  &.kaleidoscope-kaleido_hexa {
    .item-inner {
      transform: rotate(30deg);
    }

    .item-outer-1 {
      transform: rotate(-30deg);
    }

    .item-outer-2 {
      transform: rotate(30deg) matrix(-1, 0, 0, 1, 0, 0);
    }

    .item-outer-3 {
      transform: rotate(30deg);
    }
    .item-outer-4 {
      transform: rotate(90deg) matrix(-1, 0, 0, 1, 0, 0);
    }
    .item-outer-5 {
      transform: rotate(90deg);
    }
    .item-outer-6 {
      transform: rotate(150deg) matrix(-1, 0, 0, 1, 0, 0);
    }
    .item-outer-7 {
      transform: rotate(150deg);
    }
    .item-outer-8 {
      transform: rotate(210deg) matrix(-1, 0, 0, 1, 0, 0);
    }
    .item-outer-9 {
      transform: rotate(210deg);
    }
    .item-outer-10 {
      transform: rotate(270deg) matrix(-1, 0, 0, 1, 0, 0);
    }
    .item-outer-11 {
      transform: rotate(270deg);
    }
    .item-outer-12 {
      transform: rotate(330deg) matrix(-1, 0, 0, 1, 0, 0);
    }
  }

  &.kaleidoscope-kaleido_octa {
    .item-inner {
      transform: rotate(15deg);
    }
    .item-outer-1 {
      transform: rotate(-30deg);
    }

    .item-outer-2 {
      transform: rotate(0deg) matrix(-1, 0, 0, 1, 0, 0);
    }

    .item-outer-3 {
      transform: rotate(0deg);
    }
    .item-outer-4 {
      transform: rotate(30deg) matrix(-1, 0, 0, 1, 0, 0);
    }
    .item-outer-5 {
      transform: rotate(30deg);
    }
    .item-outer-6 {
      transform: rotate(60deg) matrix(-1, 0, 0, 1, 0, 0);
    }
    .item-outer-7 {
      transform: rotate(60deg);
    }
    .item-outer-8 {
      transform: rotate(90deg) matrix(-1, 0, 0, 1, 0, 0);
    }
    .item-outer-9 {
      transform: rotate(90deg);
    }
    .item-outer-10 {
      transform: rotate(120deg) matrix(-1, 0, 0, 1, 0, 0);
    }
    .item-outer-11 {
      transform: rotate(120deg);
    }
    .item-outer-12 {
      transform: rotate(150deg) matrix(-1, 0, 0, 1, 0, 0);
    }
    .item-outer-13 {
      transform: rotate(150deg);
    }
    .item-outer-14 {
      transform: rotate(180deg) matrix(-1, 0, 0, 1, 0, 0);
    }
    .item-outer-15 {
      transform: rotate(180deg);
    }
    .item-outer-16 {
      transform: rotate(210deg) matrix(-1, 0, 0, 1, 0, 0);
    }
    .item-outer-17 {
      transform: rotate(210deg);
    }
    .item-outer-18 {
      transform: rotate(240deg) matrix(-1, 0, 0, 1, 0, 0);
    }
    .item-outer-19 {
      transform: rotate(240deg);
    }
    .item-outer-20 {
      transform: rotate(270deg) matrix(-1, 0, 0, 1, 0, 0);
    }
    .item-outer-21 {
      transform: rotate(270deg);
    }
    .item-outer-22 {
      transform: rotate(300deg) matrix(-1, 0, 0, 1, 0, 0);
    }
    .item-outer-23 {
      transform: rotate(300deg);
    }
    .item-outer-24 {
      transform: rotate(330deg) matrix(-1, 0, 0, 1, 0, 0);
    }
  }
`

export const PatternContainer = styled.div`
  top: 0;
  left: 0;
  width: 50%;
  height: 50%;
  display: flex;
  flex-wrap: wrap;

  .image-wrapper {
    width: 50%;
    height: 0;
    padding-top: 50%;
    position: relative;
    background-size: contain;
  }
`

import React, { useState } from 'react'
import SVG from 'react-inlinesvg'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 9;
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;

  border-radius: 12px;
  height: 238px;
  width: 395px;

  &.fade-in {
    animation: fade-in-y-10p 1.5s ease forwards;
  }

  &.fade-out {
    animation: fade-out-y-10p 0.8s ease forwards;
  }
`

const Layer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.68);
`

const Description = styled.div`
  height: 111px;
  width: 331px;
  color: #000000;
  font-size: 18px;
  letter-spacing: 0.43px;
  line-height: 28px;
  text-align: center;
  margin-top: 30px;
`

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fff;
  min-width: 36px;
  min-height: 26px;
  border: 0;
  margin: 5px 5px 0 0;

  svg {
    max-width: 100%;
    max-height: 100%;
  }
`

const ConfirmButton = styled.button`
  background-color: #e60050;
  color: #ffffff;
  height: 40px;
  width: 90px;
  border: 0;
  font-size: 18px;
  letter-spacing: 0.43px;
  border-radius: 20px;
`

type Props = {
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean | undefined>>
}

const MobileDeviceNotification = ({ setIsDialogOpen }: Props) => {
  const { t } = useTranslation()
  const [fadeOut, setFadeOut] = useState<boolean>(false)

  const onClose = () => {
    setFadeOut(true)
    setTimeout(() => {
      setIsDialogOpen(false)
      setFadeOut(false)
    }, 800)
  }

  return (
    <Container>
      <Layer id="layer" />
      <Card className={`${fadeOut ? 'fade-out' : 'fade-in'}`}>
        <CloseButton onClick={onClose} style={{ float: 'right' }}>
          <SVG className="x-button" src="icons/x.svg" />
        </CloseButton>
        <Description>{t('mobileDeviceNotification')}</Description>
        <ConfirmButton onClick={onClose}>OK</ConfirmButton>
      </Card>
    </Container>
  )
}

export default MobileDeviceNotification

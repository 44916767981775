import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useContextSelectedMotifs } from '../../../contexts/selectedMotifContext'
import { EXPORT_SIZE, useContextMotifGenerator } from '../../../contexts/motifGeneratorContext'
import { FrameOfMotifs } from './frameOfMotifs'
import { isAndroid, isIOS } from 'react-device-detect'
import { useThrottleFn } from '@react-cmpt/use-throttle'
import { PositionXY } from '../../../types/PositionXY'
import { getPagePositionXY } from '../../../utils/getPagePositionXY'
import { JSONExportPlane, MotifGeneratorType } from '../types'
import { getTransformProperties } from '../util'

const TILE_NUMBER = 9
const RESOLUTION_MULTIPLIER = 2

const Container = styled.div`
  width: 120vw;
  height: 120vw;
  display: flex;
  flex-wrap: wrap;

  canvas {
    width: 100%;
    height: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 10;
  }

  .tile {
    background-color: ${({ theme }) => theme.colors.white};
    width: calc(100% / 3);
    height: calc(100% / 3);
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative;
    background-position: center;
    background-size: contain;
    z-index: 20;

    &.tile-to-export {
      width: ${EXPORT_SIZE}px;
      height: ${EXPORT_SIZE}px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }
  }
`

type Props = {
  rotationAngleProp?: number
}

const SquareLayoutGeneratorCanvas = ({ rotationAngleProp }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [firstTimeGenerated, setFirstTimeGenerated] = useState<Boolean>(false)
  const canvasToExportRef = useRef<HTMLCanvasElement>(null)
  const lastPos = useRef<PositionXY>({ x: 0, y: 0 })
  const dragging = useRef<boolean>(false)
  const { selectedMotifs } = useContextSelectedMotifs()
  const canvasRefs = useRef<HTMLCanvasElement[]>([])
  const [images, setImages] = useState<HTMLImageElement[]>([])
  const [canvasDataUrl, setCanvasDataUrl] = useState<string | null>(null)
  const {
    exportInvoked,
    setExportInvoked,
    exportGeneratorCoordinatesToJSON,
    handleExport,
    handleExportCanvas,
  } = useContextMotifGenerator()
  const defaultRotationAngle = 0
  const [imageLoadingIsFinished, setImageLoadingIsFinished] = useState<boolean>(false)
  const [rotationAngle, setRotationAngle] = useState(rotationAngleProp || defaultRotationAngle)
  const jsonCoordinates = useRef<JSONExportPlane>({
    clipPlane: undefined,
    entries: [],
  })

  const loadImages = (index: number) => {
    const img = new Image()
    img.crossOrigin = ''
    img.src = selectedMotifs[index].vectorizedUrl!
    img.onload = () => {
      setImages([...images, img])
    }
  }

  const sortImages = (imagesToSort: HTMLImageElement[], sortByArray: string[]) => {
    return [...imagesToSort].sort((a, b) => sortByArray.indexOf(a.src) - sortByArray.indexOf(b.src))
  }

  // Prevents pull-to refresh behavior on iOS devices
  useEffect(() => {
    if (!containerRef.current) return
    let prevent = false

    containerRef.current.addEventListener('touchstart', function (e) {
      if (e.touches.length !== 1) return

      const scrollY =
        window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop
      prevent = scrollY === 0
    })

    containerRef.current.addEventListener('touchmove', function (e) {
      if (prevent) {
        prevent = false
        e.preventDefault()
      }
    })
  }, [containerRef.current])

  useEffect(() => {
    if (images.length === selectedMotifs.length) {
      setImageLoadingIsFinished(true)
      if (imageLoadingIsFinished) {
        setImages(
          sortImages(
            images,
            selectedMotifs.map(motif => motif.vectorizedUrl!),
          ),
        )
      }
    }
    if (images.length < selectedMotifs.length && !imageLoadingIsFinished) {
      loadImages(images.length)
    }
    if (images.length > selectedMotifs.length) {
      setImages(
        images.filter(img => selectedMotifs.map(motif => motif.vectorizedUrl).includes(img.src)),
      )
    }
  }, [selectedMotifs, images.length, imageLoadingIsFinished])

  useEffect(() => {
    if (!canvasToExportRef.current || !exportInvoked) return

    setCanvasDataUrl(canvasToExportRef.current.toDataURL())
  }, [exportInvoked, canvasToExportRef])

  useEffect(() => {
    if (!canvasDataUrl || !canvasToExportRef.current) return

    exportGeneratorCoordinatesToJSON({
      props: {
        type: MotifGeneratorType.SQUARE,
        selectedMotifs,
        rotationAngle,
      },
      planes: [jsonCoordinates.current],
      canvasSize: {
        width: canvasRefs.current[0].width,
        height: canvasRefs.current[0].height,
      },
    })

    if (isIOS || isAndroid) {
      handleExportCanvas(canvasToExportRef.current)
      setExportInvoked(false)
      setCanvasDataUrl(null)
    } else {
      const exportCanvas = async () => {
        await handleExport(canvasToExportRef.current!)
        setExportInvoked(false)
        setCanvasDataUrl(null)
      }

      exportCanvas().catch(console.error)
    }
  }, [canvasDataUrl, canvasToExportRef])

  useEffect(() => {
    if (exportInvoked && canvasToExportRef.current) {
      draw(canvasToExportRef.current, 1)
    }

    if (canvasRefs.current.length !== TILE_NUMBER || selectedMotifs.length !== images.length) return

    canvasRefs.current.forEach(canvas => {
      draw(canvas, RESOLUTION_MULTIPLIER)
    })

    if (!firstTimeGenerated) {
      setFirstTimeGenerated(true)
    }
  }, [canvasRefs, canvasDataUrl, selectedMotifs, rotationAngle, images, exportInvoked])

  const draw = (canvas: HTMLCanvasElement, resolutionMultiplier: number) => {
    if (!canvas) return
    canvas.width = canvas.offsetWidth * resolutionMultiplier
    canvas.height = canvas.offsetHeight * resolutionMultiplier
    const ctx = canvas.getContext('2d')

    if (!ctx) return
    ctx.scale(resolutionMultiplier, resolutionMultiplier)
    ctx.imageSmoothingQuality = 'high'
    ctx.imageSmoothingEnabled = true

    ctx.beginPath()
    ctx.fillStyle = '#fff'
    ctx.fillRect(0, 0, canvas.width, canvas.height)

    const CENTER_MOTIF_SIZE = canvas.offsetWidth / 2
    const AROUND_MOTIFS_PER_LINE = 8
    const AROUND_MOTIF_SIZE = canvas.offsetWidth / 8

    jsonCoordinates.current.entries = []

    const draw = (index: number, x: number, y: number, sizeX: number, sizeY: number) => {
      if (!ctx || images.length === 0) return

      ctx.drawImage(images[index], x, y, sizeX, sizeY)

      const { origin, scale, rotation } = getTransformProperties(ctx)
      jsonCoordinates.current.entries.push({
        id: selectedMotifs[index].id,
        x: origin.x,
        y: origin.y,
        size: Math.max(sizeX, sizeY) * scale,
        rotation,
      })
    }

    ctx.save()
    ctx.translate(canvas.width / resolutionMultiplier / 2, canvas.height / resolutionMultiplier / 2)
    ctx.rotate(rotationAngle)
    draw(0, -CENTER_MOTIF_SIZE / 2, -CENTER_MOTIF_SIZE / 2, CENTER_MOTIF_SIZE, CENTER_MOTIF_SIZE)

    ctx.restore()

    let verticalLineIndex: number = 0
    let horizontalLineIndex: number = 0

    const aroundMotifFactory = (index: number, type: FrameOfMotifs) => {
      if (type === FrameOfMotifs.LEFT) {
        ctx.save()
        ctx.translate(
          AROUND_MOTIF_SIZE / 2,
          horizontalLineIndex * AROUND_MOTIF_SIZE + AROUND_MOTIF_SIZE + AROUND_MOTIF_SIZE / 2,
        )
        ctx.rotate(rotationAngle)
        draw(
          index,
          -AROUND_MOTIF_SIZE / 2,
          -AROUND_MOTIF_SIZE / 2,
          AROUND_MOTIF_SIZE,
          AROUND_MOTIF_SIZE,
        )
        ctx.restore()
      }
      if (type === FrameOfMotifs.RIGHT) {
        ctx.save()
        ctx.translate(
          verticalLineIndex * AROUND_MOTIF_SIZE + AROUND_MOTIF_SIZE / 2,
          horizontalLineIndex * AROUND_MOTIF_SIZE + AROUND_MOTIF_SIZE + AROUND_MOTIF_SIZE / 2,
        )
        ctx.rotate(-rotationAngle)
        draw(
          index,
          -AROUND_MOTIF_SIZE / 2,
          -AROUND_MOTIF_SIZE / 2,
          AROUND_MOTIF_SIZE,
          AROUND_MOTIF_SIZE,
        )
        ctx.restore()
      }
      if (type === FrameOfMotifs.TOP) {
        ctx.save()
        ctx.translate(
          verticalLineIndex * AROUND_MOTIF_SIZE + AROUND_MOTIF_SIZE / 2,
          AROUND_MOTIF_SIZE / 2,
        )
        ctx.rotate(verticalLineIndex < 4 ? rotationAngle : -rotationAngle)
        draw(
          index,
          -AROUND_MOTIF_SIZE / 2,
          -AROUND_MOTIF_SIZE / 2,
          AROUND_MOTIF_SIZE,
          AROUND_MOTIF_SIZE,
        )
        ctx.restore()
      }
      if (type === FrameOfMotifs.BOTTOM) {
        ctx.save()
        ctx.translate(
          verticalLineIndex * AROUND_MOTIF_SIZE + AROUND_MOTIF_SIZE / 2,
          horizontalLineIndex * AROUND_MOTIF_SIZE + AROUND_MOTIF_SIZE / 2,
        )
        ctx.rotate(verticalLineIndex < 4 ? rotationAngle : -rotationAngle)
        draw(
          index,
          -AROUND_MOTIF_SIZE / 2,
          -AROUND_MOTIF_SIZE / 2,
          AROUND_MOTIF_SIZE,
          AROUND_MOTIF_SIZE,
        )
        ctx.restore()
      }
    }

    for (verticalLineIndex = 0; verticalLineIndex < AROUND_MOTIFS_PER_LINE; verticalLineIndex++) {
      for (
        horizontalLineIndex = 0;
        horizontalLineIndex < AROUND_MOTIFS_PER_LINE;
        horizontalLineIndex++
      ) {
        const leftLineCondition =
          verticalLineIndex === 0 && horizontalLineIndex !== AROUND_MOTIFS_PER_LINE - 2
        const rightLineCondition =
          verticalLineIndex === AROUND_MOTIFS_PER_LINE - 1 &&
          horizontalLineIndex !== AROUND_MOTIFS_PER_LINE - 2
        const topLineCondition = horizontalLineIndex === 0
        const bottomLineCondition = horizontalLineIndex === AROUND_MOTIFS_PER_LINE - 1
        if (selectedMotifs.length === 1) {
          if (leftLineCondition) aroundMotifFactory(0, FrameOfMotifs.LEFT)
          if (rightLineCondition) aroundMotifFactory(0, FrameOfMotifs.RIGHT)
          if (topLineCondition) aroundMotifFactory(0, FrameOfMotifs.TOP)
          if (bottomLineCondition) aroundMotifFactory(0, FrameOfMotifs.BOTTOM)
        } else if (selectedMotifs.length === 2) {
          if (leftLineCondition) aroundMotifFactory(1, FrameOfMotifs.LEFT)
          if (rightLineCondition) aroundMotifFactory(1, FrameOfMotifs.RIGHT)
          if (topLineCondition) aroundMotifFactory(1, FrameOfMotifs.TOP)
          if (bottomLineCondition) aroundMotifFactory(1, FrameOfMotifs.BOTTOM)
        } else if (selectedMotifs.length === 3) {
          const verticalLineCondition = horizontalLineIndex === 1 || horizontalLineIndex === 4
          const horizontalLineCondition =
            verticalLineIndex === 0 ||
            verticalLineIndex === 2 ||
            verticalLineIndex === 5 ||
            verticalLineIndex === 7
          if (leftLineCondition)
            verticalLineCondition
              ? aroundMotifFactory(1, FrameOfMotifs.LEFT)
              : aroundMotifFactory(2, FrameOfMotifs.LEFT)
          if (rightLineCondition)
            verticalLineCondition
              ? aroundMotifFactory(1, FrameOfMotifs.RIGHT)
              : aroundMotifFactory(2, FrameOfMotifs.RIGHT)
          if (topLineCondition)
            horizontalLineCondition
              ? aroundMotifFactory(1, FrameOfMotifs.TOP)
              : aroundMotifFactory(2, FrameOfMotifs.TOP)
          if (bottomLineCondition)
            horizontalLineCondition
              ? aroundMotifFactory(1, FrameOfMotifs.BOTTOM)
              : aroundMotifFactory(2, FrameOfMotifs.BOTTOM)
        } else if (selectedMotifs.length === 4) {
          if (leftLineCondition)
            if (horizontalLineIndex === 0 || horizontalLineIndex === 5) {
              aroundMotifFactory(2, FrameOfMotifs.LEFT)
            } else if (horizontalLineIndex === 1 || horizontalLineIndex === 4) {
              aroundMotifFactory(3, FrameOfMotifs.LEFT)
            } else if (horizontalLineIndex === 2 || horizontalLineIndex === 3) {
              aroundMotifFactory(1, FrameOfMotifs.LEFT)
            }
          if (rightLineCondition)
            if (horizontalLineIndex === 0 || horizontalLineIndex === 5) {
              aroundMotifFactory(2, FrameOfMotifs.RIGHT)
            } else if (horizontalLineIndex === 1 || horizontalLineIndex === 4) {
              aroundMotifFactory(3, FrameOfMotifs.RIGHT)
            } else if (horizontalLineIndex === 2 || horizontalLineIndex === 3) {
              aroundMotifFactory(1, FrameOfMotifs.RIGHT)
            }
          if (topLineCondition)
            if (verticalLineIndex === 1 || verticalLineIndex === 6) {
              aroundMotifFactory(2, FrameOfMotifs.TOP)
            } else if (verticalLineIndex === 2 || verticalLineIndex === 5) {
              aroundMotifFactory(3, FrameOfMotifs.TOP)
            } else {
              aroundMotifFactory(1, FrameOfMotifs.TOP)
            }
          if (bottomLineCondition)
            if (verticalLineIndex === 1 || verticalLineIndex === 6) {
              aroundMotifFactory(2, FrameOfMotifs.BOTTOM)
            } else if (verticalLineIndex === 2 || verticalLineIndex === 5) {
              aroundMotifFactory(3, FrameOfMotifs.BOTTOM)
            } else {
              aroundMotifFactory(1, FrameOfMotifs.BOTTOM)
            }
        }
      }
    }
  }

  useEffect(() => {
    if (!firstTimeGenerated) return
    setCanvasDataUrl(canvasRefs.current[0].toDataURL())
  }, [firstTimeGenerated])

  const handleMouseDown = (event: React.MouseEvent) => {
    event.preventDefault()
    onStartEvent(event.pageX, event.pageY)
  }

  const handleTouchStart = (event: React.TouchEvent) => {
    onStartEvent(event.touches[0].pageX, event.touches[0].pageY)
  }

  const onStartEvent = (x: number, y: number) => {
    lastPos.current.x = x
    lastPos.current.y = y
    dragging.current = true
  }

  const handleMouseUp = (event: React.MouseEvent | React.TouchEvent) => {
    event.preventDefault()
    setCanvasDataUrl(canvasRefs.current[0].toDataURL())
    dragging.current = false
  }

  const handleMouseMove = (event: React.MouseEvent | React.TouchEvent): void => {
    if (dragging.current) {
      const pageXY = getPagePositionXY(event)
      const moveX = lastPos.current.x - pageXY.x
      lastPos.current = pageXY
      setRotationAngle(rotationAngle + moveX / 100)
    }
  }

  const { callback } = useThrottleFn(handleMouseMove, 40)

  return (
    <div
      style={{
        position: 'fixed',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Container
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseMove={callback}
        onMouseUp={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchMove={callback}
        onTouchEnd={handleMouseUp}
      >
        {[...Array(TILE_NUMBER)].map((_, i) => {
          return (
            <div className="tile" key={i}>
              <canvas ref={el => (canvasRefs.current[i] = el!)}></canvas>
            </div>
          )
        })}
        {exportInvoked ? (
          <div className="tile tile tile-to-export">
            <canvas
              width={EXPORT_SIZE / RESOLUTION_MULTIPLIER}
              height={EXPORT_SIZE / RESOLUTION_MULTIPLIER}
              ref={canvasToExportRef}
            ></canvas>
          </div>
        ) : null}
      </Container>
    </div>
  )
}

export default SquareLayoutGeneratorCanvas

import { Tile, TilePart } from './tile'
import { TileKind } from './tilekind'
import { Vector } from './vector'
import { Constants } from './constants'

export class Tessellation extends Set<Tile> {
  private findTileBy(origin: Vector, abscissa: Vector, part: TilePart): Tile | null {
    for (let t of this) {
      if (
        origin.equalsWithTolerance(t.origin) &&
        abscissa.equalsWithTolerance(t.abscissa) &&
        part === t.part
      ) {
        return t
      }
    }
    return null
  }

  private findTilesBy(origin: Vector, abscissa?: Vector, part?: TilePart): Tile[] {
    let tiles: Tile[] = []
    for (let t of this) {
      if (
        origin.equalsWithTolerance(t.origin) &&
        (abscissa === undefined || abscissa.equalsWithTolerance(t.abscissa)) &&
        (part === undefined || part === t.part)
      ) {
        tiles.push(t)
      }
    }
    return tiles
  }

  private findThickRhombs(): Tessellation {
    let result = new Tessellation([...this])
    result.forEach(t => {
      if (t.kind === TileKind.DART && t.part === TilePart.LEFT) {
        let rightDart = result.findTileBy(t.origin, t.abscissa, TilePart.RIGHT)
        let kiteOrigin = t.origin.add(t.abscissa.mul(1 + Constants.PHI))
        let leftKiteAbscissa = t.abscissa.rotate((4 * Math.PI) / 5)
        let rightKiteAbscissa = t.abscissa.rotate((6 * Math.PI) / 5)
        let leftKite = result.findTileBy(kiteOrigin, leftKiteAbscissa, TilePart.LEFT)
        let rightKite = result.findTileBy(kiteOrigin, rightKiteAbscissa, TilePart.RIGHT)
        if (rightDart !== null && leftKite !== null && rightKite !== null) {
          result.delete(t)
          result.delete(rightDart)
          result.delete(leftKite)
          result.delete(rightKite)
          result.add(new Tile(TileKind.THICK_RHOMB, TilePart.WHOLE, t.origin, t.abscissa))
        }
      }
    })
    return result
  }

  private findThinRhombs(): Tessellation {
    let result = new Tessellation([...this])
    result.forEach(t => {
      if (t.kind === TileKind.KITE && t.part === TilePart.LEFT) {
        let rightOrigin = t.origin
          .add(t.abscissa.mul(Constants.PHI))
          .add(t.abscissa.rotate(Math.PI / 5).mul(Constants.PHI))
        let rightAbscissa = t.abscissa.rotate((6 * Math.PI) / 5)
        let rightHalfKite = result.findTileBy(rightOrigin, rightAbscissa, TilePart.RIGHT)
        if (rightHalfKite !== null) {
          result.delete(t)
          result.delete(rightHalfKite)
          result.add(
            new Tile(
              TileKind.THIN_RHOMB,
              TilePart.WHOLE,
              t.origin.add(t.abscissa.mul(Constants.PHI)),
              t.abscissa.rotate((3 * Math.PI) / 5),
            ),
          )
        }
      }
    })
    return result
  }

  private findWholesByHalves(kind: TileKind): Tessellation {
    let result = new Tessellation([...this])
    result.forEach(t => {
      if (t.kind === kind && t.part === TilePart.LEFT) {
        let rightHalfDart = result.findTileBy(t.origin, t.abscissa, TilePart.RIGHT)
        if (rightHalfDart !== null) {
          result.delete(t)
          result.delete(rightHalfDart)
          result.add(new Tile(kind, TilePart.WHOLE, t.origin, t.abscissa))
        }
      }
    })
    return result
  }

  findRhombs(): Tessellation {
    return this.findThickRhombs().findThinRhombs()
  }

  findKitesAndDarts(): Tessellation {
    return this.findWholesByHalves(TileKind.KITE).findWholesByHalves(TileKind.DART)
  }

  /**
   * Scales the current tesselation so the abscissas of all tiles will be 1 unit long.
   * @return The normalized tessellation.
   */
  normalize(): Tessellation {
    let normalizedTessellation = new Tessellation()
    for (let t of this) {
      let scale = 1 / t.abscissa.abs()
      normalizedTessellation.add(
        new Tile(t.kind, t.part, t.origin.mul(scale), t.abscissa.mul(scale)),
      )
    }
    return normalizedTessellation
  }
}

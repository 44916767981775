export const getTransformProperties = (ctx: CanvasRenderingContext2D) => {
  const t = ctx.getTransform()
  const p0 = t.transformPoint(new DOMPoint(0, 0))
  const p1 = t.transformPoint(new DOMPoint(0, 1))

  const origin = p0
  const scale = Math.sqrt(Math.pow(p0.x - p1.x, 2) + Math.pow(p0.y - p1.y, 2))
  let rotation = Math.atan2(p0.y - p1.y, p0.x - p1.x) + Math.PI / 2

  return { origin, scale, rotation }
}

import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { Region } from '../../constants/geographicFilters'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LabelContainer } from './common'
import { MapContainer } from './common'
import { AnimationState } from '../../constants/animationStates'

const GlobalMapContainer = styled(MapContainer)`
  .global-map {
    width: 85%;
  }
`

const GlobalLabelContainer = styled(LabelContainer)`
  .europe {
    top: 39%;
    left: 44%;
  }

  .asia {
    top: 38.5%;
    right: 22%;
  }

  .africa {
    bottom: 42%;
    left: 47.5%;
  }

  .northamerica {
    top: 40%;
    left: 10%;
  }

  .centralamerica {
    top: 50%;
    left: 14%;
  }

  .southamerica {
    top: 58%;
    left: 22%;
  }

  .australia {
    bottom: 36.5%;
    right: 14.5%;
  }

  .oceania {
    bottom: 30.5%;
    right: 12%;
  }

  .melanesia {
    bottom: 41%;
    right: 4%;
  }

  .caucasus {
    top: 44%;
    right: 32%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    .europe {
      top: 41.25%;
      left: 46%;
    }

    .asia {
      top: 40.5%;
      right: 26%;
    }

    .africa {
      bottom: 44%;
      left: 48.5%;
    }

    .northamerica {
      top: 40%;
      left: 15%;
    }

    .centralamerica {
      top: 50%;
      left: 21%;
    }

    .southamerica {
      top: 58%;
      left: 28%;
    }

    .australia {
      bottom: 36.5%;
      right: 16.5%;
    }

    .oceania {
      bottom: 30.5%;
      right: 12%;
    }

    .melanesia {
      bottom: 40%;
      right: 11%;
    }

    .caucasus {
      top: 44%;
      right: 38%;
    }
  }
`

interface Props {
  onFilter: (filter: Region) => void
}

const GlobalFilter = ({ onFilter }: Props) => {
  const { t } = useTranslation()
  const [labelVisibilityState, setLabelVisibilityState] = useState<AnimationState>(
    AnimationState.UNSET,
  )

  const labels = [
    { name: 'europe', class: 'small-label', action: () => onFilter(Region.EUROPE) },
    { name: 'asia', class: 'big-label', action: () => onFilter(Region.ASIA) },
    { name: 'africa', class: 'big-label', action: () => onFilter(Region.AFRICA) },
    { name: 'northamerica', class: 'big-label', action: () => onFilter(Region.NORTH_AMERICA) },
    { name: 'centralamerica', class: '', action: () => onFilter(Region.CENTRAL_AMERICA) },
    { name: 'southamerica', class: 'small-label', action: () => onFilter(Region.SOUTH_AMERICA) },
    { name: 'australia', class: '', action: () => onFilter(Region.AUSTRALIA) },
    { name: 'oceania', class: 'big-label', action: () => onFilter(Region.OCEANIA) },
    { name: 'melanesia', class: '', action: () => onFilter(Region.MELANESIA) },
    { name: 'caucasus', class: '', action: () => onFilter(Region.CAUCASUS) },
  ]

  useEffect(() => {
    setTimeout(() => {
      setLabelVisibilityState(AnimationState.PLAY)
    }, 500)
  }, [labelVisibilityState])

  return (
    <GlobalMapContainer>
      <SVG className="global-map" src="icons/global-map-layered.svg" />
      <GlobalLabelContainer>
        {labels.map(label => (
          <button
            key={label.name}
            className={`${label.name} ${label.class} ${labelVisibilityState}`}
            onClick={label.action}
          >
            <span className={label.class}>{t(`regions.${label.name}`)}</span>
          </button>
        ))}
      </GlobalLabelContainer>
    </GlobalMapContainer>
  )
}

export default GlobalFilter

import React, {
  MouseEventHandler,
  Ref,
  UIEventHandler,
  useEffect,
  useRef,
  useState,
  WheelEventHandler,
} from 'react'
import { isDesktop, isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import SVG from 'react-inlinesvg'
import links from '../../constants/links'
import { useContextMotifDetails } from '../../contexts/motifDetailsContext'
import { useContextUI } from '../../contexts/UIContext'
import { Motif } from '../../types/motifs'
import { useEnv } from '../../utils/useEnv'
import { ButtonWrapper, Container, GalleryWrapper } from './common'
import { CloseButton } from '../MotifDetails'
import { UIElement } from '../../types/UIElements'

const Gallery = () => {
  const { t } = useTranslation()
  const { isWeb } = useEnv()
  const { sortedMotifs } = useContextMotifDetails()
  const { filterOutUiElement } = useContextUI()
  const [content, setContent] = useState<Motif[]>([])
  const [scrollPos, setScrollPos] = useState<number>(0)
  const [lastContentIndex, setLastContentIndex] = useState<number>(2)
  const containerRef = useRef<HTMLDivElement>()
  const [wrapperClass, setWrapperClass] = useState<string>('fade-in')
  const firstCardRef = useRef<HTMLDivElement>(null)
  const scrollPerItem = useRef<number>(0)
  const [fadeOut, setFadeOut] = useState<boolean>(false)

  useEffect(() => {
    if (!sortedMotifs.length) return
    setContent([sortedMotifs[0], sortedMotifs[1], sortedMotifs[2]])
  }, [sortedMotifs])

  useEffect(() => {
    if (!firstCardRef.current) return

    scrollPerItem.current =
      isMobile && window.innerHeight < 600
        ? firstCardRef.current?.getBoundingClientRect().height
        : firstCardRef.current?.getBoundingClientRect().width
  }, [firstCardRef.current])

  const generatedPatternExamples = new Array(2).fill(null).map((_, i) => ({
    src: `gallery_${i + 1}.jpg`,
  }))

  const generatedPatterns = new Array(13)
    .fill(null)
    .map((_, i) => ({
      src: `gallery_${i + 1}.jpg`,
    }))
    .splice(2, 11)

  const handleHorizontalWheelScroll: WheelEventHandler<HTMLDivElement> = event => {
    if (!containerRef?.current) return
    event.stopPropagation()
    containerRef.current.scrollLeft += event.deltaY
  }

  const handleElementScroll: UIEventHandler = event => {
    if (!containerRef?.current) return
    const { scrollLeft, scrollTop } = containerRef.current
    const nextIndex = lastContentIndex + 1
    const scrollOffset = isMobile && window.innerHeight < 600 ? scrollTop : scrollLeft

    if (scrollOffset - scrollPos >= scrollPerItem.current && sortedMotifs[nextIndex]) {
      setScrollPos(scrollOffset)
      setContent([...content, sortedMotifs[nextIndex]])
      setLastContentIndex(nextIndex)
    }
  }

  const handleClickOutside: MouseEventHandler<HTMLDivElement> = e => {
    if (e.target !== containerRef.current) return
    handleClose()
  }

  const handleClose = () => {
    setWrapperClass('fade-out')
    setFadeOut(true)
    setTimeout(() => {
      filterOutUiElement(UIElement.GALLERY)
      setFadeOut(false)
    }, 500)
  }

  return (
    <>
      <GalleryWrapper
        ref={containerRef as Ref<HTMLDivElement>}
        onScroll={handleElementScroll}
        onWheel={isDesktop ? handleHorizontalWheelScroll : undefined}
        onClick={handleClickOutside}
        className={wrapperClass}
      >
        <Container>
          <div className={`wrapper ${fadeOut ? 'fade-out' : 'fade-in'}`}>
            <div className="about-gallery">
              <header>
                {isWeb ? (
                  <a href={links.MUSEUM} rel="noopener noreferrer" target="_blank">
                    <SVG className="logo" src="icons/logo.svg" />
                  </a>
                ) : (
                  <SVG className="logo" src="icons/logo.svg" />
                )}
              </header>
              <main>
                <div className="title">{t('gallery.title')}</div>
                <div className="subtitle">{t('gallery.subtitle')}</div>
                <div className="description">{t('gallery.details1')}</div>
                <div className="description">{t('gallery.details2')}</div>
              </main>
            </div>
            <div className="generated-pattern-container">
              {generatedPatternExamples.map(generatedPatternExample => (
                <img
                  key={generatedPatternExample.src}
                  className="generated-pattern"
                  src={`/icons/gallery/${generatedPatternExample.src}`}
                  alt="generated pattern"
                />
              ))}
            </div>
            <div className="text-container">
              <div>{t('gallery.details3')}</div>
              <div>{t('gallery.details4')}</div>
              <div>
                {t('gallery.details5')}: <strong>{t('gallery.link')}</strong>
              </div>
            </div>
            <div className="generated-pattern-container">
              {generatedPatterns.map(generatedPattern => (
                <img
                  key={generatedPattern.src}
                  className="generated-pattern"
                  src={`/icons/gallery/${generatedPattern.src}`}
                  alt="generated pattern"
                />
              ))}
            </div>
          </div>
        </Container>
      </GalleryWrapper>
      <ButtonWrapper>
        <CloseButton onClick={handleClose} className={wrapperClass}>
          <SVG className="x-button" src="icons/x.svg" />
        </CloseButton>
      </ButtonWrapper>
    </>
  )
}

export default Gallery

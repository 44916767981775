import React, { ReactNode, useEffect, useState } from 'react'
import { SelectorWrapper } from './common'
import styled from 'styled-components'
import { useSwipeable } from 'react-swipeable'

const BlackBubble = styled.div`
  min-width: 420px;
  min-height: 420px;
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  transition: all 0.3s ease;

  &.swiping {
    transform: translateX(-120px);
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    min-width: 860px;
    min-height: 860px;
  }

  @media (min-width: 1300px) {
    min-width: 1100px;
    min-height: 1100px;
  }

  @media (min-width: 1550px) {
    min-width: 1200px;
    min-height: 1200px;
  }

  @media (min-width: 1700px) {
    min-width: 1450px;
    min-height: 1450px;
  }
`

const SwitchBubble = styled.button`
  position: absolute;
  right: -90px;
  top: calc(50% - 90px);
  width: 180px;
  height: 180px;
  background-color: ${({ theme }) => theme.colors.black};
  border: none;
  border-radius: 50%;

  &.go-out {
    animation: move-to-right-100p 0.2s ease-out forwards;
  }

  &.come-in {
    animation: move-from-right-100p 0.2s ease-in forwards;
  }

  @media (min-width: 1300px) {
    right: -120px;
    top: calc(50% - 120px);
    width: 240px;
    height: 240px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    right: -60px;
    top: calc(50% - 50px);
    height: 100px;
    width: 100px;
  }
`

interface Props {
  children: ReactNode
  className: string
  selectFilter?: () => void
  switchType: () => void
  closing: boolean
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const FilterSelector = ({ className, children, switchType, closing, onClick }: Props) => {
  const [switching, setSwitching] = useState<boolean>(false)
  const [swiping, setSwiping] = useState<boolean>(false)

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (!switching) {
      setSwitching(true)
      timer = setTimeout(() => {
        setSwitching(false)
      }, 500)
    }
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
      setSwitching(false)
    }
  }, [closing])

  const onSwitch = () => {
    setSwitching(true)
    setTimeout(() => {
      switchType()
    }, 200)
    setTimeout(() => {
      setSwitching(false)
    }, 500)
  }

  const handleSwipe = useSwipeable({
    onSwipedLeft: () => {
      onSwitch()
      setTimeout(() => {
        setSwiping(false)
      }, 300)
    },
    onSwipeStart: ({ deltaX }) => {
      if (deltaX <= 0) {
        setSwiping(true)
      }
    },
  })

  return (
    <SelectorWrapper className={`outside ${className}`} onClick={onClick}>
      <BlackBubble {...handleSwipe} className={swiping ? 'swiping' : ''}>
        {children}
      </BlackBubble>
      <SwitchBubble onClick={onSwitch} className={switching ? 'go-out' : 'come-in'} />
    </SelectorWrapper>
  )
}

export default FilterSelector

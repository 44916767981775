import React, {
  MouseEventHandler,
  Ref,
  UIEventHandler,
  useEffect,
  useRef,
  useState,
  WheelEventHandler,
} from 'react'
import { isDesktop, isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import SVG from 'react-inlinesvg'
import links from '../../constants/links'
import { useContextMotifDetails } from '../../contexts/motifDetailsContext'
import { useContextUI } from '../../contexts/UIContext'
import { Motif } from '../../types/motifs'
import { useEnv } from '../../utils/useEnv'
import {
  AboutProjectWrapper,
  AwardsLogo,
  AwardsText,
  AwardsWrapper,
  Button,
  ButtonWrapper,
  Container,
  HorinzontalRule,
  LogoWrapper,
} from './common'
import { CloseButton } from '../MotifDetails'
import { UIElement } from '../../types/UIElements'

const AboutProject = () => {
  const { t } = useTranslation()
  const { isWeb, isKiosk } = useEnv()
  const { sortedMotifs } = useContextMotifDetails()
  const { filterOutUiElement } = useContextUI()
  const [content, setContent] = useState<Motif[]>([])
  const [scrollPos, setScrollPos] = useState<number>(0)
  const [lastContentIndex, setLastContentIndex] = useState<number>(2)
  const containerRef = useRef<HTMLDivElement>()
  const [wrapperClass, setWrapperClass] = useState<string>('fade-in')
  const firstCardRef = useRef<HTMLDivElement>(null)
  const scrollPerItem = useRef<number>(0)
  const [fadeOut, setFadeOut] = useState<boolean>(false)

  useEffect(() => {
    if (!sortedMotifs.length) return
    setContent([sortedMotifs[0], sortedMotifs[1], sortedMotifs[2]])
  }, [sortedMotifs])

  useEffect(() => {
    if (!firstCardRef.current) return

    scrollPerItem.current =
      isMobile && window.innerHeight < 600
        ? firstCardRef.current?.getBoundingClientRect().height
        : firstCardRef.current?.getBoundingClientRect().width
  }, [firstCardRef.current])

  const handleHorizontalWheelScroll: WheelEventHandler<HTMLDivElement> = event => {
    if (!containerRef?.current) return
    event.stopPropagation()
    containerRef.current.scrollLeft += event.deltaY
  }

  const handleElementScroll: UIEventHandler = event => {
    if (!containerRef?.current) return
    const { scrollLeft, scrollTop } = containerRef.current
    const nextIndex = lastContentIndex + 1
    const scrollOffset = isMobile && window.innerHeight < 600 ? scrollTop : scrollLeft

    if (scrollOffset - scrollPos >= scrollPerItem.current && sortedMotifs[nextIndex]) {
      setScrollPos(scrollOffset)
      setContent([...content, sortedMotifs[nextIndex]])
      setLastContentIndex(nextIndex)
    }
  }

  const handleClickOutside: MouseEventHandler<HTMLDivElement> = e => {
    if (e.target !== containerRef.current) return
    handleClose()
  }

  const handleClose = () => {
    setWrapperClass('fade-out')
    setFadeOut(true)
    setTimeout(() => {
      filterOutUiElement(UIElement.ABOUT)
      setFadeOut(false)
    }, 500)
  }

  return (
    <>
      <AboutProjectWrapper
        ref={containerRef as Ref<HTMLDivElement>}
        onScroll={handleElementScroll}
        onWheel={isDesktop ? handleHorizontalWheelScroll : undefined}
        onClick={handleClickOutside}
        className={wrapperClass}
      >
        <Container>
          <div className={`wrapper ${fadeOut ? 'fade-out' : 'fade-in'}`}>
            <div className="about-project intro">
              <header>
                {isWeb ? (
                  <a href={links.MUSEUM} rel="noopener noreferrer" target="_blank">
                    <SVG src="icons/logo.svg" />
                  </a>
                ) : (
                  <SVG src="icons/logo.svg" />
                )}
              </header>
              <main>
                <div className="title">{t('aboutProject.title')}</div>
                <div className="subtitle">{t('aboutProject.subtitle')}</div>
                <div className="description">{t('aboutProject.details1')}</div>
                <div className="description">{t('aboutProject.details2')}</div>
              </main>
            </div>
            <div className="poster-container">
              {!isMobile && window.innerHeight > 600 ? (
                <header>
                  <Button
                    onClick={() => {
                      if (isWeb) window.open(links.ETNOSHOP, '_blank')
                    }}
                  >
                    <span>
                      {t(
                        `${isKiosk ? 'aboutProject.etnoshopAlternative' : 'aboutProject.etnoshop'}`,
                      )}
                    </span>
                  </Button>
                </header>
              ) : null}
              <main>
                <img src="/icons/motifmap_poster.png" alt="poster" />
              </main>
            </div>
            <div className="about-project summary">
              <div className="description">{t('aboutProject.details3')}</div>
              <div className="description with-gap">{t('aboutProject.details4')}</div>
              <div className="description with-gap">{t('aboutProject.details5')}</div>
              <div className="description all-rights with-gap">
                {t('aboutProject.allRightsReserved')}
              </div>
            </div>
            {window.innerHeight > 600 ? (
              <div className="how-was-it-made">
                <div className="about-project story">
                  <div className="title">{t('aboutProject.creatingTitle')}</div>
                  <div className="subtitle">{t('aboutProject.creatingSubtitle')}</div>
                  <div className="description">{t('aboutProject.creatingDetails1')}</div>
                  <div className="description">{t('aboutProject.creatingDetails2')}</div>
                  <div className="description">{t('aboutProject.creatingDetails3')}</div>
                  <div className="description">{t('aboutProject.creatingDetails4')}</div>
                </div>
                <div className="about-project illustration">
                  <img
                    src="/icons/about-project/how_was_it_made.png"
                    alt="how-was-it-made-illustration"
                  />
                </div>
              </div>
            ) : null}
            <div className="about-project awards">
              <div className="title">{t('aboutProject.museumTitle')}</div>
              <div className="subtitle">{t('aboutProject.museumSubtitle')}</div>
              <div className="description">{t('aboutProject.museumDetails')}</div>
              <div className="description">{t('aboutProject.museumAddress')}</div>
              <a
                className={isWeb ? '' : 'disable-click'}
                href={links.PIXELPERFECTAWARDS}
                rel="noopener noreferrer"
                target="_blank"
              >
                <AwardsWrapper>
                  <AwardsLogo>
                    <img src="/images/PPA_logo.svg" alt="pixel-perfect-awards-logo" />
                  </AwardsLogo>
                  <AwardsText>
                    <p>{t('aboutProject.pixelPerfectAwards')}</p>
                  </AwardsText>
                </AwardsWrapper>
              </a>
            </div>
            <div className="about-project">
              <HorinzontalRule>
                <div className="title">{t('aboutProject.creatorsTitle')}</div>
                <div className="creators-description subtitle">
                  {t('aboutProject.conceptDetails')}
                </div>
                <div className="creators-description">{t('aboutProject.conceptCreators')}</div>
                <div className="creators-description subtitle">{t('aboutProject.developers')}</div>
                <div className="creators-description">{t('aboutProject.developerCompany')}</div>
                <div style={{ display: 'flex', gap: '40px' }}>
                  <div className="creators-description">{t('aboutProject.developerList1')}</div>
                  <div className="creators-description">{t('aboutProject.developerList2')}</div>
                </div>
                <div className="creators-description subtitle">{t('aboutProject.artists')}</div>
                <div className="creators-description">{t('aboutProject.artistList')}</div>
              </HorinzontalRule>
              <LogoWrapper>
                <div>
                  <img src="/images/neprajzi_logo_ff.svg" alt="pixel-perfect-awards-logo" />
                </div>
                <div>
                  <img src="/images/MOON42_logo.svg" alt="pixel-perfect-awards-logo" />
                </div>
              </LogoWrapper>
            </div>
          </div>
        </Container>
      </AboutProjectWrapper>
      <ButtonWrapper>
        <CloseButton onClick={handleClose} className={wrapperClass}>
          <SVG className="x-button" src="icons/x.svg" />
        </CloseButton>
      </ButtonWrapper>
    </>
  )
}

export default AboutProject

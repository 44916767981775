import { useMemo } from 'react'

/**
 * Helper hook to get the environment variables, so we don't have to type their names every time.
 */
export const useEnv = () => {
  const isLocalDev = useMemo<boolean>(() => process.env.REACT_APP_LOCAL_DEV === 'true', [])
  const isKiosk = useMemo<boolean>(() => process.env.REACT_APP_ENV === 'kiosk', [])
  const isWeb = useMemo<boolean>(() => process.env.REACT_APP_ENV === 'web', [])

  return {
    isLocalDev,
    isKiosk,
    isWeb,
  }
}

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SVG from 'react-inlinesvg'
import styled from 'styled-components'
import { useContextInteractionMotivator } from '../../contexts/interactionMotivatorContext'

const HIDE_ANIMATION_DURATION_IN_SEC = 1

const InteractionMotivatorBubble = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150px;
  height: 150px;
  margin-top: -75px;
  margin-left: -75px;
  background: ${({ theme }) => theme.colors.black};
  border-radius: 50%;
  opacity: 0;
  z-index: 100;
  animation: fade-in-x50 0.6s forwards;
  transition: all 0.9s;

  &.hide {
    animation: simple-fade-out ${HIDE_ANIMATION_DURATION_IN_SEC}s forwards;
  }

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 80px;

    &.icon-hand {
      transform: translate(-50%, calc(-50% - 30px));
      opacity: 0;
      margin-left: 8px;
      animation: fade-in-y30-center 0.4s 0.4s ease-in-out forwards;
      height: 70px;

      &.animate {
        opacity: 1;
        transform: translate(-50%, -50%);
        animation: move-horizontally 1s 0.2s linear infinite;

        &.slow-animation {
          animation-duration: 100s;
        }
      }
    }

    &.icon-arrow {
      width: 100px;
      opacity: 0;
      margin-top: -30px;
      transform: translate(-50%, calc(-50% - 30px));
      animation: fade-in-y30-center 0.4s 0.2s ease-in-out forwards;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    width: 240px;
    height: 240px;
    margin-top: -120px;
    margin-left: -120px;

    svg {
      width: 100px;

      &.icon-hand {
        height: 100px;
        margin-left: 11px;
      }

      &.icon-arrow {
        width: inherit;
        margin-top: -40px;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.lg}) {
    width: 355px;
    height: 355px;
    margin-top: -177.5px;
    margin-left: -177.5px;

    svg {
      width: auto;

      &.icon-hand {
        height: auto;
        margin-left: 14px;
      }

      &.icon-arrow {
        margin-top: -55px;
      }
    }
  }
`

const MenuCallToAction = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  width: 240px;
  height: 60px;
  left: 0;
  top: 5vh;
  margin-top: -20px;
  padding: 10px 10px 10px 80px;
  background: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 0 60px 60px 0;
  transform: translateX(-100%);
  z-index: 10;
  animation: fade-in-x50-from-left 1s forwards;

  &.hide {
    animation: fade-out-x50-to-left ${HIDE_ANIMATION_DURATION_IN_SEC}s forwards;
  }

  div {
    padding-right: 30px;
  }

  @media (min-height: ${({ theme }) => theme.breakpoints.deviceH.xxs}) {
    top: 5.5vh;
  }

  @media (min-height: ${({ theme }) => theme.breakpoints.deviceH.xs}) {
    top: 12vh;
    height: 70px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xs}) and (min-height: ${({
      theme,
    }) => theme.breakpoints.deviceH.sm}) {
    top: 19vh;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    font-size: 18px;
    height: 90px;
    top: 11.5vh;
    width: 300px;
    padding: 10px 10px 10px 100px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.md}) {
    height: 100px;
    padding: 10px 10px 10px 110px;
    width: 310px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.lg}) {
    font-size: 24px;
    top: 10.5vh;
    height: 120px;
    width: 390px;
    padding: 10px 10px 10px 130px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xl}) {
    top: 11vh;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xxl}) {
    font-size: 26px;
    height: 136px;
    width: 510px;
    top: 15.7vh;
    padding: 10px 10px 10px 240px;
    border-radius: 0 80px 80px 0;
  }
`

const InteractionMotivator = () => {
  const { t } = useTranslation()
  const [animateSVG, setAnimateSVG] = useState<boolean>(false)
  const { isInteractionMotivatorShown, setIsInteractionMotivatorShownWithHideAnimation } =
    useContextInteractionMotivator()

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAnimateSVG(true)
    }, 1700)

    return () => clearTimeout(timeout)
  }, [])

  useEffect(() => {
    if (isInteractionMotivatorShown) return

    setTimeout(() => {
      setIsInteractionMotivatorShownWithHideAnimation(false)
    }, HIDE_ANIMATION_DURATION_IN_SEC * 1000)
  }, [isInteractionMotivatorShown])

  return (
    <>
      <MenuCallToAction className={!isInteractionMotivatorShown ? 'hide' : ''}>
        <div>{t('featureDiscovery.furtherFunctionsInMenu')}</div>
      </MenuCallToAction>
      <InteractionMotivatorBubble className={!isInteractionMotivatorShown ? 'hide' : ''}>
        <SVG
          className={`icon-hand ${animateSVG ? 'animate' : ''} ${
            !isInteractionMotivatorShown ? 'slow-animation' : ''
          }`}
          src="/icons/hand@3x.svg"
        />
        <SVG className={`icon-arrow ${animateSVG ? 'animate' : ''}`} src="/icons/arrow@3x.svg" />
      </InteractionMotivatorBubble>
    </>
  )
}

export default InteractionMotivator

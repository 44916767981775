import { DefaultTheme } from 'styled-components'

/**
 * The default theme 'styled-components' are using, but can also be imported anywhere
 * as a simple JS object.
 *
 * Before adding a new property, you have to register it on the interface in the 'styled.d.ts' file.
 */
export const theme: DefaultTheme = {
  colors: {
    black: '#000000',
    black20: 'rgba(0,0,0,0.2)',
    black49: 'rgba(0,0,0,0.49)',
    black70: 'rgba(0,0,0,0.7)',
    black87: 'rgba(0,0,0,0.87)',
    black95: 'rgba(0,0,0,0.95)',
    bgLightGrey: '#F9F9F9',
    grey: '#7D7D7D',
    borderLightGrey: '#DDDDDD',
    borderGrey: '#979797',
    red: '#E60050',
    white: '#FFFFFF',
    white70: 'rgba(255,255,255,0.70)',
    white75: 'rgba(255,255,255,0.75)',
    white80: 'rgba(255,255,255,0.8)',
  },
  font: {
    bold: 'ApproachBold, sans-serif',
    boldItalic: 'ApproachBoldItalic, sans-serif',
    black: 'ApproachBlack, sans-serif',
    blackItalic: 'ApproachBlackItalic, sans-serif',
    light: 'ApproachLight, sans-serif',
    lightItalic: 'ApproachLightItalic, sans-serif',
    medium: 'ApproachMedium, sans-serif',
    mediumItalic: 'ApproachMediumItalic, sans-serif',
    regular: 'ApproachRegular, sans-serif',
    regularItalic: 'ApproachRegularItalic, sans-serif',
    semiBold: 'ApproachSemiBold, sans-serif',
    semiBoldItalic: 'ApproachSemiBoldItalic, sans-serif',
    ultraBlack: 'ApproachUltraBlack, sans-serif',
    ultraBlackItalic: 'ApproachUltraBlackItalic, sans-serif',
    extraBlack: 'ApproachExtraBlack, sans-serif',
    extraBlackItalic: 'ApproachExtraBlackItalic, sans-serif',
  },
  breakpoints: {
    deviceW: {
      xxs: '320px',
      xs: '768px',
      sm: '1023px',
      md: '1101px',
      lg: '1400px',
      xl: '1601px',
      xxl: '1921px',
      xxxl: '2561px',
    },
    deviceH: {
      xxs: '380px',
      xs: '500px',
      sm: '600px',
      md: '800px',
      lg: '1000px',
    },
  },
}

import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { Region } from '../../constants/geographicFilters'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LabelContainer } from './common'
import { MapContainer } from './common'
import { AnimationState } from '../../constants/animationStates'

const LocalMapContainer = styled(MapContainer)`
  .local-map {
    width: 85%;
  }
`
export const LocalLabelContainer = styled(LabelContainer)`
  .alfold {
    top: 41.75%;
    left: 43%;
  }

  .dunantul {
    top: 40.5%;
    left: 23%;
  }

  .felvidek {
    top: 24.5%;
    left: 39.5%;
  }

  .erdely {
    top: 47.5%;
    right: 29%;
  }

  .szekelyfold {
    top: 52.5%;
    right: 10%;
  }

  .delvidek {
    bottom: 35%;
    left: 44.5%;
  }

  .hajdusag {
    top: 37.35%;
    right: 33%;
  }

  .kalotaszeg {
    bottom: 54%;
    right: 25%;
  }

  .sarkoz {
    bottom: 45%;
    left: 35%;
  }

  .mezokovesd {
    top: 33.65%;
    left: 47%;
  }

  .kalocsa {
    bottom: 49.5%;
    left: 39%;
  }

  .ormansag {
    bottom: 40%;
    left: 26%;
  }

  .bukovina {
    top: 33%;
    right: 6.5%;
  }

  .eszakmagyarorszag {
    top: 29.23%;
    left: 41%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    .alfold {
      top: 43.5%;
      left: 48%;
    }

    .dunantul {
      top: 41.5%;
      left: 25%;
    }

    .felvidek {
      top: 26.5%;
      left: 39.5%;
    }

    .erdely {
      top: 48.5%;
      right: 29%;
    }

    .szekelyfold {
      top: 51.5%;
      right: 13%;
    }

    .delvidek {
      bottom: 38%;
      left: 44.5%;
    }

    .hajdusag {
      top: 37%;
      right: 33%;
    }

    .kalotaszeg {
      bottom: 55%;
      right: 29%;
    }

    .sarkoz {
      bottom: 46%;
      left: 35%;
    }

    .mezokovesd {
      top: 33.5%;
      left: 47%;
    }

    .kalocsa {
      bottom: 49.5%;
      left: 39%;
    }

    .ormansag {
      bottom: 42%;
      left: 29%;
    }

    .bukovina {
      top: 33%;
      right: 11.5%;
    }

    .eszakmagyarorszag {
      top: 30.23%;
      left: 45%;
    }
  }
`

interface Props {
  onFilter: (filter: Region) => void
}

const LocalFilter = ({ onFilter }: Props) => {
  const { t } = useTranslation()
  const [labelVisibilityState, setLabelVisibilityState] = useState<AnimationState>(
    AnimationState.UNSET,
  )

  const labels = [
    { name: 'alfold', class: 'big-label', action: () => onFilter(Region.ALFOLD) },
    { name: 'dunantul', class: 'big-label', action: () => onFilter(Region.DUNANTUL) },
    { name: 'felvidek', class: 'big-label', action: () => onFilter(Region.FELVIDEK) },
    { name: 'erdely', class: 'big-label', action: () => onFilter(Region.ERDELY) },
    { name: 'szekelyfold', class: 'small-label', action: () => onFilter(Region.SZEKELYFOLD) },
    { name: 'delvidek', class: 'big-label', action: () => onFilter(Region.DELVIDEK) },
    { name: 'hajdusag', class: 'small-label', action: () => onFilter(Region.HAJDUSAG) },
    { name: 'kalotaszeg', class: '', action: () => onFilter(Region.KALOTASZEG) },
    { name: 'sarkoz', class: '', action: () => onFilter(Region.SARKOZ) },
    { name: 'mezokovesd', class: '', action: () => onFilter(Region.MEZOKOVESD) },
    { name: 'kalocsa', class: '', action: () => onFilter(Region.KALOCSA) },
    { name: 'ormansag', class: 'small-label', action: () => onFilter(Region.ORMANSAG) },
    { name: 'bukovina', class: 'small-label', action: () => onFilter(Region.BUKOVINA) },
    {
      name: 'eszakmagyarorszag',
      class: 'small-label',
      action: () => onFilter(Region.ESZAKMAGYARORSZAG),
    },
  ]

  useEffect(() => {
    setTimeout(() => {
      setLabelVisibilityState(AnimationState.PLAY)
    }, 500)
  }, [labelVisibilityState])

  return (
    <LocalMapContainer>
      <SVG className="local-map" src="icons/local-map-layered.svg" />
      <LocalLabelContainer>
        {labels.map(label => (
          <button
            key={label.name}
            className={`${label.name} ${label.class} ${labelVisibilityState}`}
            onClick={label.action}
          >
            <span className={label.class}>{t(`regions.${label.name}`)}</span>
          </button>
        ))}
      </LocalLabelContainer>
    </LocalMapContainer>
  )
}

export default LocalFilter

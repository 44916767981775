import React, {
  MouseEventHandler,
  Ref,
  UIEventHandler,
  useEffect,
  useRef,
  useState,
  WheelEventHandler,
} from 'react'
import { isDesktop, isMobile } from 'react-device-detect'
import SVG from 'react-inlinesvg'
import styled from 'styled-components'

import { useContextMotifDetails } from '../../contexts/motifDetailsContext'
import { useContextSelectedMotifs } from '../../contexts/selectedMotifContext'
import { useContextUI } from '../../contexts/UIContext'
import { Motif } from '../../types/motifs'
import MotifDetailsItem from './MotifDetailsItem'

export const DetailsWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 23px;
  opacity: 0;
  transform: translateX(50px);

  &.fade-in {
    animation: fade-in-x50 0.5s ease forwards;
  }

  &.fade-out {
    animation: fade-out-x50 0.5s ease forwards;
  }

  .outside {
    position: fixed;
    left: 0;
    z-index: -1;
    width: 100vw;
    height: 100vh;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) and (max-height: ${({
      theme,
    }) => theme.breakpoints.deviceH.sm}) {
    width: calc(100vw - 82px);
    padding: 16px 12px 16px 8px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    flex-direction: row;
    left: 0;
    width: 100vw;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    padding-left: 172px;
    overflow-x: auto;
    overflow-y: hidden;
    gap: 46px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xxl}) {
    padding-left: 10vw;
  }
`

export const ButtonWrapper = styled.div`
  position: absolute;
  top: 18px;
  right: 8px;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-y: hidden;
  pointer-events: none;

  svg {
    height: 24px;
    width: 24px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    top: 0;
    right: 0;
    width: 150px;

    svg {
      height: 30px;
      width: 30px;
    }
  }
`
export const CloseButton = styled.button`
  background: transparent;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  border: none;
  border-radius: 50%;
  padding: 0;
  pointer-events: initial;
  position: fixed;
  top: 8vh;
  right: 25px;

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    top: 12vh;
    right: 84px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xxl}) {
    top: 23vh;
    right: 5vw;
  }

  &.fade-in {
    animation: fade-in-x50 0.5s ease forwards;
  }

  &.fade-out {
    animation: fade-out-x50 0.5s ease forwards;
  }
`
// const TOP = 64

const MotifDetails = () => {
  const { sortedMotifs, setDetailsOpen, setMotifDetailsFadeOut } = useContextMotifDetails()
  const { numberOfSelectedMotifs } = useContextSelectedMotifs()
  const { triggerFirstNotification } = useContextUI()
  const [content, setContent] = useState<Motif[]>([])
  const [scrollPos, setScrollPos] = useState<number>(0)
  const [lastContentIndex, setLastContentIndex] = useState<number>(2)
  const containerRef = useRef<HTMLDivElement>()
  const [wrapperClass, setWrapperClass] = useState<string>('fade-in')
  const firstCardRef = useRef<HTMLDivElement>(null)
  const scrollPerItem = useRef<number>(0)

  useEffect(() => {
    if (!sortedMotifs.length) return
    setContent([sortedMotifs[0], sortedMotifs[1], sortedMotifs[2]])
  }, [sortedMotifs])

  useEffect(() => {
    if (!firstCardRef.current) return

    scrollPerItem.current =
      isMobile && window.innerHeight < 600
        ? firstCardRef.current?.getBoundingClientRect().height
        : firstCardRef.current?.getBoundingClientRect().width
  }, [firstCardRef.current])

  const handleHorizontalWheelScroll: WheelEventHandler<HTMLDivElement> = event => {
    if (!containerRef?.current) return
    event.stopPropagation()
    containerRef.current.scrollLeft += event.deltaY
  }

  const handleElementScroll: UIEventHandler = event => {
    if (!containerRef?.current) return
    const { scrollLeft, scrollTop } = containerRef.current
    const nextIndex = lastContentIndex + 1
    const scrollOffset = isMobile && window.innerHeight < 600 ? scrollTop : scrollLeft

    if (scrollOffset - scrollPos >= scrollPerItem.current && sortedMotifs[nextIndex]) {
      setScrollPos(scrollOffset)
      setContent([...content, sortedMotifs[nextIndex]])
      setLastContentIndex(nextIndex)
    }
  }

  const handleClickOutside: MouseEventHandler<HTMLDivElement> = e => {
    if (e.target !== containerRef.current) return

    handleClick()
  }

  const handleClick = () => {
    setWrapperClass('fade-out')
    setMotifDetailsFadeOut(true)
    setTimeout(() => {
      setDetailsOpen(false)
      setMotifDetailsFadeOut(false)
      triggerFirstNotification(numberOfSelectedMotifs)
    }, 500)
  }

  // TODO: uncomment when handleNext bug is being fixed
  // const handleNext = (event: React.MouseEvent<HTMLDivElement>) => {
  //   const left = (event.target as HTMLDivElement).offsetLeft
  //   const offset = firstCardRef.current?.offsetLeft || 0
  //   containerRef.current?.scrollTo({ top: TOP, left: left - offset, behavior: 'smooth' })
  // }

  return (
    <>
      <DetailsWrapper
        ref={containerRef as Ref<HTMLDivElement>}
        onScroll={handleElementScroll}
        onWheel={isDesktop ? handleHorizontalWheelScroll : undefined}
        onClick={handleClickOutside}
        className={wrapperClass}
      >
        {content.map((motif, index) => (
          <div
            key={index}
            // onClick={isDesktop ? handleNext : undefined}
            id={`detail-item-${index}`}
            className="detail-card"
            ref={index === 0 ? firstCardRef : null}
          >
            <MotifDetailsItem motif={motif} />
          </div>
        ))}
      </DetailsWrapper>
      <ButtonWrapper>
        <CloseButton onClick={handleClick} className={wrapperClass}>
          <SVG className="x-button" src="icons/x.svg" />
        </CloseButton>
      </ButtonWrapper>
    </>
  )
}

export default MotifDetails

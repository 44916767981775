import React, { useLayoutEffect, useState, useEffect } from 'react'
import styled from 'styled-components'
import lottie from 'lottie-web'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

const LoadingWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  z-index: 99;

  #lottie {
    position: fixed;
    bottom: 50vh;
    width: 100px;
    height: 100px;
  }

  .content {
    position: fixed;
    bottom: 38vh;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.2px;
    line-height: 28px;
    font-family: ${({ theme }) => theme.font.light};
    animation: simple-fade-in 0.8s ease-in;

    p {
      margin: 12px 0;
    }
  }

  .hidden {
    display: none;
  }

  .progress-bar {
    height: 2px;
    width: 100%;
    max-width: 80vw;
    background: #c9c9c9;
    position: relative;

    &::before {
      content: '';
    }

    .progress,
    &::before {
      background: #000;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 0;
    }

    .progress {
      animation: loading 240s forwards;
    }

    &.completed {
      &::before {
        animation: completeLoading 0.8s forwards;
      }
    }
  }

  @media (min-height: ${({ theme }) => theme.breakpoints.deviceH.lg}) {
    .content {
      bottom: 42vh;
      font-size: 22px;
      line-height: 34px;
    }

    #lottie {
      width: 150px;
      height: 150px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    .content {
      bottom: 10vh;
      line-height: 34px;
    }

    #lottie {
      bottom: 42vh;
      width: 120px;
      height: 120px;
    }
  }

  @keyframes loading {
    0% {
      width: 0;
    }
    4% {
      width: 33%;
    }
    6% {
      width: 40%;
    }
    7% {
      width: 70%;
    }
    15% {
      width: 75%;
    }
    17% {
      width: 85%;
    }
    100% {
      width: 99%;
    }
  }

  @keyframes completeLoading {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
`

interface Props {
  isCompleted: boolean
}

const FullScreenLoading = ({ isCompleted }: Props) => {
  const [isLoadingMessageVisible, setIsLoadingMessageVisible] = useState<boolean>(false)
  const { t } = useTranslation()

  useLayoutEffect(() => {
    const element = document.getElementById('lottie')
    if (!element) throw new Error('Lottie container not found')
    lottie.loadAnimation({
      container: element,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: 'animation/motivum_loading.json',
    })
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setIsLoadingMessageVisible(true)
    }, 6000)
  }, [])

  return (
    <LoadingWrapper>
      <div id="lottie" />
      <div className={`content ${isLoadingMessageVisible ? '' : 'hidden'}`}>
        <p>{t('loadingInProgress')}</p>

        <div className={`progress-bar ${isCompleted ? 'completed' : ''}`}>
          <div className="progress"></div>
        </div>

        {isMobile && window.innerHeight < 500 ? <p>{t('mobileNoticeToUseMonitor')}</p> : null}
      </div>
    </LoadingWrapper>
  )
}

export default FullScreenLoading

import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { GeneratorControlButtonDiv } from './common'
import { Motif } from '../../types/motifs'
import MotifListItem, { RemoveIcon } from './MotifListItem'
import { useContextSelectedMotifs } from '../../contexts/selectedMotifContext'
import ConfirmationDialog from '../ConfirmationDialog'
import { useContextUI } from '../../contexts/UIContext'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import { UIElement } from '../../types/UIElements'
import { useContextMotifGenerator } from '../../contexts/motifGeneratorContext'
import { MotifGeneratorType } from '../MotifPatternGenerator/types'
import { getPagePositionXY } from '../../utils/getPagePositionXY'
import { isMobile } from 'react-device-detect'
import { useLocation } from 'react-router-dom'
import { useContextScreensaver } from '../../contexts/screensaverContext'
import { useThrottleFn } from '@react-cmpt/use-throttle'

const MotifListWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: -200px;
  height: 200px;
  margin-top: -100px;
  transition: 0.2s left ease-out;
  z-index: 10;

  &.open {
    left: 0;
  }

  @media (min-height: ${({ theme }) => theme.breakpoints.deviceH.xxs}) {
    height: 240px;
    margin-top: -120px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    height: 260px;
    margin-top: -130px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.md}) {
    height: 320px;
    margin-top: -160px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xl}) {
    height: 415px;
    margin-top: -207.5px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xxl}) {
    &.open {
      left: 3vw;
    }
  }
`

const GeneratorControlElement = styled.div<{
  $useSpacing?: boolean
}>`
  --button-size: 40px;

  @media (min-height: ${({ theme }) => theme.breakpoints.deviceH.xxs}) {
    --button-size: 50px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.md}) {
    --button-size: 60px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xl}) {
    --button-size: 80px;
  }
`

const ControlWrapper = styled.div`
  border-bottom-right-radius: 17px;
  border-top-right-radius: 17px;
  background-color: ${({ theme }) => theme.colors.bgLightGrey};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  padding: 10px 12px;
  height: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    padding: 15px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.md}) {
    padding: 18px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xl}) {
    padding: 21px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.xxl}) {
    border-top-left-radius: 17px;
    border-bottom-left-radius: 17px;
  }
`

const MotifButtonsOuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const MotifButtonsInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .motif-placeholder:last-of-type {
    margin: 0 !important;
  }
`

const MotifListControl = () => {
  const { elementsOpen } = useContextUI()
  const { setIsScreensaverOn } = useContextScreensaver()
  const { selectedGeneratorType, setSelectedMotifForFreeGeneration } = useContextMotifGenerator()
  const { setFreeGeneratorTouchEventPageXY } = useContextMotifGenerator()
  const { selectedMotifs, setSelectedMotifs, deselectMotif } = useContextSelectedMotifs()
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const [motifToRemove, setMotifToRemove] = useState<Motif | undefined>()
  const containerWrapperRef = useRef<HTMLDivElement>(null)
  const firstButtonRef = useRef<HTMLDivElement>(null)
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [containerHeight, setContainerHeight] = useState<number>()
  const [gap, setGap] = useState<number>(0)
  const location = useLocation()
  const shouldDragMotifOut = useRef(false)

  useEffect(() => {
    if (elementsOpen.length === 0 || elementsOpen.includes(UIElement.CONTROLS)) {
      setIsOpen(true)
    } else if (
      isOpen &&
      !elementsOpen.includes(UIElement.SIDEMENU) &&
      !elementsOpen.includes(UIElement.EXPLANATION)
    ) {
      setIsOpen(false)
    }
  }, [elementsOpen])

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', callback)

    return () => window.removeEventListener('resize', callback)
  }, [])

  const { callback } = useThrottleFn(handleResize, 40)

  useEffect(() => {
    if (!containerWrapperRef.current) return
    const outerContainerHeight = containerWrapperRef.current.getBoundingClientRect().height
    setContainerHeight(outerContainerHeight)
  }, [windowWidth, selectedMotifs.length])

  useEffect(() => {
    if (
      containerHeight &&
      firstButtonRef.current &&
      !(isMobile && window.matchMedia('(orientation: portrait)').matches)
    ) {
      setGap((containerHeight - 4 * firstButtonRef.current.closest('div')!.offsetHeight) / 3)
    }
  }, [containerHeight, firstButtonRef])

  const handleClickRemove = (id: number) => {
    const motif = selectedMotifs.find(m => m.id === id)
    if (!motif) return
    setMotifToRemove(motif)
    setShowConfirmation(true)
  }

  const handleRemoveConfirm = (confirmed: boolean) => {
    if (confirmed && motifToRemove) {
      deselectMotif(motifToRemove.id)
    }
    setShowConfirmation(false)
    setMotifToRemove(undefined)
  }

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(selectedMotifs, result.source.index, result.destination.index)

    setSelectedMotifs(items)
  }

  // a little function to help us with reordering the result
  const reorder = (list: Motif[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onMouseDownHandler = (event: React.MouseEvent | React.TouchEvent, index: number) => {
    if (selectedGeneratorType === MotifGeneratorType.FREE) {
      setFreeGeneratorTouchEventPageXY(getPagePositionXY(event))

      shouldDragMotifOut.current = true
      setTimeout(() => {
        if (shouldDragMotifOut.current) {
          setSelectedMotifForFreeGeneration(selectedMotifs[index])
        }
      }, 115)
    }
  }

  const onTouchMoveHandler = (event: React.TouchEvent) => {
    setFreeGeneratorTouchEventPageXY(getPagePositionXY(event))
  }

  const onMouseUpHandler = (event: React.MouseEvent | React.TouchEvent, index: number) => {
    if (selectedGeneratorType === MotifGeneratorType.FREE) {
      if (event.type === 'touchend') {
        event.preventDefault()
      }

      shouldDragMotifOut.current = false
      setSelectedMotifForFreeGeneration(undefined)
    }
  }

  return (
    <>
      <MotifListWrapper className={isOpen ? 'open' : ''}>
        <ControlWrapper>
          <MotifButtonsOuterWrapper ref={containerWrapperRef}>
            {selectedGeneratorType === MotifGeneratorType.FREE ? (
              <MotifButtonsInnerWrapper>
                {new Array(selectedMotifs.length).fill(null).map((_, index) => {
                  let isPlusOne = index === selectedMotifs.length
                  let isEmpty = index > selectedMotifs.length
                  return (
                    <GeneratorControlElement
                      key={index}
                      className={`btn-${index}`}
                      ref={index === 0 ? firstButtonRef : null}
                      style={{
                        marginBottom: `${index !== 3 ? gap : 0}px`,
                      }}
                      $useSpacing={true}
                      onTouchStart={event => onMouseDownHandler(event, index)}
                      onMouseDown={event => onMouseDownHandler(event, index)}
                      onMouseUp={event => onMouseUpHandler(event, index)}
                      onTouchEnd={event => onMouseUpHandler(event, index)}
                      onTouchMove={onTouchMoveHandler}
                    >
                      <MotifListItem
                        useSpacing={true}
                        motif={selectedMotifs[index]}
                        index={index}
                        isEmpty={isEmpty}
                        isPlusOne={isPlusOne}
                        onRemove={handleClickRemove}
                      />
                    </GeneratorControlElement>
                  )
                })}
              </MotifButtonsInnerWrapper>
            ) : (
              <MotifButtonsInnerWrapper>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable" direction="vertical">
                    {provided => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {new Array(selectedMotifs.length).fill(null).map((_, index) => (
                          <div
                            key={index}
                            ref={index === 0 ? firstButtonRef : null}
                            className={`btn-${index}`}
                            style={{
                              marginBottom: `${index !== 3 ? gap : 0}px`,
                            }}
                          >
                            <Draggable key={index} draggableId={`draggable-${index}`} index={index}>
                              {provided => {
                                return (
                                  <GeneratorControlButtonDiv
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      backgroundImage: `url(${
                                        selectedMotifs[index].vectorizedUrlXs ||
                                        selectedMotifs[index].vectorizedUrl ||
                                        ''
                                      })`,
                                      ...provided.draggableProps.style,
                                    }}
                                  >
                                    <RemoveIcon
                                      data-rbd-drag-handle-context-id={
                                        provided.dragHandleProps?.[
                                          'data-rbd-drag-handle-context-id'
                                        ]
                                      }
                                      data-rbd-drag-handle-draggable-id="any-id-to-avoid-drag-event-from-being-fired"
                                      onClick={() => {
                                        setIsScreensaverOn(false)
                                        handleClickRemove(selectedMotifs[index].id)
                                      }}
                                    >
                                      <SVG src="icons/x.svg" width={12} height={12} />
                                    </RemoveIcon>
                                  </GeneratorControlButtonDiv>
                                )
                              }}
                            </Draggable>
                          </div>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </MotifButtonsInnerWrapper>
            )}
            {selectedMotifs.length !== 4 && (
              <MotifButtonsInnerWrapper>
                {new Array(4 - selectedMotifs.length).fill(null).map((_, index) => {
                  let isPlusOne = index === 0 && location.pathname === '/generate'
                  let isEmpty = !isPlusOne
                  return (
                    <div
                      key={index}
                      style={{ marginBottom: `${index !== 4 - selectedMotifs.length ? gap : 0}px` }}
                    >
                      <MotifListItem
                        useSpacing={true}
                        index={index}
                        isEmpty={isEmpty}
                        isPlusOne={isPlusOne}
                        onRemove={handleClickRemove}
                      />
                    </div>
                  )
                })}
              </MotifButtonsInnerWrapper>
            )}
          </MotifButtonsOuterWrapper>
        </ControlWrapper>
      </MotifListWrapper>
      {showConfirmation && (
        <ConfirmationDialog onConfirm={handleRemoveConfirm} message={'motifDeselectConfirmation'} />
      )}
    </>
  )
}

export default MotifListControl

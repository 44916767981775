import styled from 'styled-components'
import { useContextUI } from '../../contexts/UIContext'

const StyledDiv = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: ${({ theme }) => theme.colors.white75};

  &.fade-in {
    z-index: 0;
    animation: simple-fade-in 0.7s ease forwards;
  }

  &.fade-out {
    z-index: 0;
    animation: fade-out-hide 0.7s ease forwards;
  }
`

const WhiteOverlay = () => {
  const { overlayVisible } = useContextUI()
  return <StyledDiv className={`fade-in ${!overlayVisible ? 'fade-out' : ''}`} />
}

export default WhiteOverlay

import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  ReactNode,
  createContext,
  useState,
  useMemo,
  useRef,
} from 'react'
import { Motif } from '../types/motifs'
import { useAxiosStrapi } from '../utils/axios'
import { filterByTag, filterByRegion, mapMotifResponse } from '../utils/motifs'
import { useStorage } from '../utils/localStorage'
import { useEnv } from '../utils/useEnv'
import { useContextUI } from './UIContext'
import { Tag } from '../constants/tags'
import { Region } from '../constants/geographicFilters'
import { generateMap, MotifMap } from '../components/MotifMap/generate'
import { ReactZoomPanPinchState } from 'react-zoom-pan-pinch'
import { UIElement } from '../types/UIElements'

interface ContextType {
  allMotifs: Motif[]
  sortedMotifs: Motif[]
  filteredMotifs: Motif[]
  areDetailsOpen: boolean
  setDetailsOpen: Dispatch<SetStateAction<boolean>>
  setTargetedMotif: Dispatch<SetStateAction<number | undefined>>
  tagFilter: Tag | undefined
  applyTagFilter: (tag: Tag | undefined) => void
  geoFilter: Region | undefined
  applyGeoFilter: (region: Region | undefined) => void
  motifDetailsFadeOut: boolean
  setMotifDetailsFadeOut: Dispatch<SetStateAction<boolean>>
  motifMap: MotifMap | undefined
  motifMapViewport: React.MutableRefObject<ReactZoomPanPinchState | undefined>
  motifImageCache: React.MutableRefObject<Map<number, MotifImageCache | null>>
}

interface Props {
  children: ReactNode
}

interface MotifImageCache {
  blob: Blob
  url: string
  blobXs: Blob
  urlXs: string
}

const Context = createContext({} as ContextType)

const MotifDetailsContext = ({ children }: Props) => {
  const { store, getStored } = useStorage()
  const { isLocalDev } = useEnv()
  const { get } = useAxiosStrapi()
  const {
    elementsOpen,
    setElementsOpen,
    filterOutUiElement,
    setIsInitialLoading,
    showErrorMessage,
  } = useContextUI()
  const [allMotifs, setAllMotifs] = useState<Motif[]>([])
  const [sortedMotifs, setSortedMotifs] = useState<Motif[]>([])
  const [areDetailsOpen, setDetailsOpen] = useState<boolean>(false)
  const [targetedMotif, setTargetedMotif] = useState<number>()
  const [tagFilter, setTagFilter] = useState<Tag | undefined>()
  const [geoFilter, setGeoFilter] = useState<Region | undefined>()
  const [filteredMotifs, setFilteredMotifs] = useState<Motif[]>([])
  const [motifDetailsFadeOut, setMotifDetailsFadeOut] = useState<boolean>(false)
  const motifMap = useMemo(() => {
    if (allMotifs.length === 0) return

    return generateMap(allMotifs, window.innerWidth, window.innerHeight)
  }, [allMotifs])
  const motifMapViewport = useRef<ReactZoomPanPinchState>()
  const motifImageCache = useRef(new Map<number, MotifImageCache | null>()) // null means loading

  const fetchMotifs = async () => {
    try {
      setIsInitialLoading(true)
      const [...responses] = await Promise.all([
        get('/cards?_start=0&_limit=200'),
        get('/cards?_start=200&_limit=200'),
        get('/cards?_start=400&_limit=200'),
        get('/cards?_start=600&_limit=200'),
        get('/cards?_start=800&_limit=200'),
        get('/cards?_start=1000&_limit=-1'),
      ])
      const data = responses.map(res => res.data).flat()
      const motifs = mapMotifResponse([...data])
      if (isLocalDev) {
        store<Motif[]>('all-motifs', motifs)
      }
      setAllMotifs(motifs)
    } catch (e) {
      showErrorMessage('APIErrorMessage', true)
      console.log('Could not fetch motifs', e)
    } finally {
      setIsInitialLoading(false)
    }
  }

  useEffect(() => {
    const storedMotifs = getStored<Motif[]>('all-motifs')
    // Getting all the motifs is a crazy long request. To speed things up I implemented the use of local storage
    // so if LOCAL_DEV is set to 'true' we only need to fetch the data once and not every single page reload.
    // Comment this if block out if you need to re-fetch the motifs.
    if (isLocalDev && storedMotifs?.length) {
      setAllMotifs(storedMotifs)
      return
    }
    fetchMotifs().then()
  }, [])

  useEffect(() => {
    if (!targetedMotif || isNaN(Number(targetedMotif))) return

    // If we have a filter, we want to use the 'filteredMotifs' array as the base
    const motifIndex =
      tagFilter || geoFilter
        ? filteredMotifs.findIndex(m => m.id === Number(targetedMotif))
        : allMotifs.findIndex(m => m.id === Number(targetedMotif))

    if (motifIndex < 0) throw new Error('Motif not found!')

    const sorted =
      tagFilter || geoFilter
        ? [
            ...filteredMotifs.slice(motifIndex, filteredMotifs.length),
            ...filteredMotifs.slice(0, motifIndex),
          ]
        : [...allMotifs.slice(motifIndex, allMotifs.length), ...allMotifs.slice(0, motifIndex)]

    setSortedMotifs(sorted)
    if (elementsOpen.includes(UIElement.GALLERY)) {
      filterOutUiElement(UIElement.GALLERY)
    }
    if (elementsOpen.includes(UIElement.ABOUT)) {
      filterOutUiElement(UIElement.ABOUT)
    }
    setDetailsOpen(true)

    if (!elementsOpen.includes(UIElement.CONTROLS)) {
      setElementsOpen([UIElement.CONTROLS])
    }

    setTargetedMotif(undefined)
  }, [targetedMotif])

  useEffect(() => {
    if (!tagFilter && !geoFilter) {
      setFilteredMotifs([...allMotifs])
    } else if (tagFilter) {
      const filteredMotifs = filterByTag(allMotifs, tagFilter)
      setFilteredMotifs(filteredMotifs)
    } else if (geoFilter) {
      const filteredMotifs = filterByRegion(allMotifs, geoFilter)
      setFilteredMotifs(filteredMotifs)
    }
  }, [tagFilter, geoFilter])

  const applyTagFilter = (tag: Tag | undefined) => {
    setGeoFilter(undefined)
    setTagFilter(tag)
  }

  const applyGeoFilter = (region: Region | undefined) => {
    setTagFilter(undefined)
    setGeoFilter(region)
  }

  return (
    <Context.Provider
      value={{
        allMotifs,
        sortedMotifs,
        filteredMotifs,
        areDetailsOpen,
        setDetailsOpen,
        setTargetedMotif,
        tagFilter,
        geoFilter,
        applyTagFilter,
        applyGeoFilter,
        motifDetailsFadeOut,
        setMotifDetailsFadeOut,
        motifMap,
        motifMapViewport,
        motifImageCache,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useContextMotifDetails = () => {
  return useContext(Context)
}

export default MotifDetailsContext

import styled from 'styled-components'

export const SelectorWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  position: relative;

  &.come-in {
    animation: geo-filter-in 0.5s ease forwards;
  }

  &.go-out {
    animation: geo-filter-out 0.5s ease forwards;
  }
`

export const MapContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 420px;
  min-height: 420px;

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    min-width: 860px;
    min-height: 860px;
  }

  @media (min-width: 1300px) {
    min-width: 1100px;
    min-height: 1100px;
  }

  @media (min-width: 1550px) {
    min-width: 1200px;
    min-height: 1200px;
  }

  @media (min-width: 1700px) {
    min-width: 1450px;
    min-height: 1450px;
  }
`

export const LabelContainer = styled.div`
  position: absolute;
  min-width: 420px;
  min-height: 420px;

  button {
    height: 16px;
    padding: 0 4px;
    text-align: center;
    border: 1px solid ${({ theme }) => theme.colors.borderGrey};
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.white};
    position: absolute;

    &.play {
      animation: zoom-in 0.8s ease;
    }

    &.unset {
      opacity: 0;
    }
  }

  span {
    font-size: 12px;
    letter-spacing: 1.2px;
    line-height: 16px;
    font-family: ${({ theme }) => theme.font.semiBold};
  }

  .small-label {
    height: 18px;
    font-family: ${({ theme }) => theme.font.medium};
    line-height: 18px;
    font-size: 12px;
    letter-spacing: 1.2px;
  }

  .big-label {
    height: 20px;
    font-family: ${({ theme }) => theme.font.bold};
    line-height: 20px;
    font-size: 12px;
    letter-spacing: 1.2px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.deviceW.sm}) {
    min-width: 860px;
    min-height: 860px;

    button {
      height: 22px;
      padding: 0 5px;
    }

    span {
      font-size: 12px;
      letter-spacing: 1.62px;
      line-height: 16px;
      font-family: ${({ theme }) => theme.font.semiBold};
    }

    .small-label {
      height: 28px;
      font-family: ${({ theme }) => theme.font.medium};
      line-height: 26px;
      font-size: 16px;
      letter-spacing: 1.8px;
    }

    .big-label {
      height: 32px;
      font-family: ${({ theme }) => theme.font.bold};
      line-height: 30px;
      font-size: 20px;
      letter-spacing: 2px;
    }
  }

  @media (min-width: 1300px) {
    min-width: 1100px;
    min-height: 1100px;

    button {
      height: 30px;
      padding: 0 10px;
    }

    span {
      font-size: 14px;
      line-height: 20px;
    }

    .small-label {
      height: 35px;
      line-height: 30px;
      font-size: 20px;
    }

    .big-label {
      height: 40px;
      line-height: 36px;
      font-size: 26px;
    }
  }

  @media (min-width: 1550px) {
    min-width: 1200px;
    min-height: 1200px;
  }

  @media (min-width: 1700px) {
    min-width: 1450px;
    min-height: 1450px;
  }
`
